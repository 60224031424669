import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router'
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConnectorspopupComponent } from '../connectorspopup/connectorspopup.component';
import { AuthservService } from '../servicess/authserv.service';
import { ConnectorsService } from '../servicess/connectors.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})

export class HomeComponent implements OnInit {
  @BlockUI() blockUI: NgBlockUI;
  @ViewChild('closeAddExpenseModal') closeAddExpenseModal: ElementRef;
  constructor(private router: Router, public dialog: MatDialog, private route: ActivatedRoute, private authServ: AuthservService, private conService: ConnectorsService) { }
  ngOnInit() {
    this.ConnectorDetails()
    this.connectors();
  }
  id1: any;

  fun1(id) {
    this.id1 = id;
    this.router.navigate(["/product", id]);
  }
  openDialog() {
    const dialogRef = this.dialog.open(ConnectorspopupComponent, {
      width: "80%",
      height: "70%"
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }

  display: any = [];
  display1: boolean = false;
  isEnabled: true;
  isEnableConnector: boolean = false;
  _integrator_ID: any;

  ConnectorDetails() {
    this.authServ.getConnectors().subscribe((data: any) => {
      this.display = data;
      for (let i = 0; i < data.results.length; i++) {
        if (data.results[i].isEnabled != true)
          this.isEnableConnector = true;
      }
    })
  }
  store: any = [];
  imgs: Array<any> = [];
  connectors() {
    this.authServ.showConnectorDetails().subscribe((data) => {
      this.store = data;
    })
  }

  updateIoStatus(integratorID) {
    this._integrator_ID = integratorID;
  }
  PostIOStatus() {
    this.closeAddExpenseModal.nativeElement.click();
    this.blockUI.start('Processing...');
    this.conService.UpdateIOStatusService(this._integrator_ID).subscribe((data) => {
      this.ConnectorDetails();
      this.connectors();
      this.blockUI.stop();
      location.reload();
    })
  }
  getIntegratorDetails(IntegratorID) {
    this.authServ.getIntegratorDetailsService(IntegratorID).subscribe((data: any) => {
      var ConnectorName = data.results[0].dashBoardName;
      localStorage.setItem('SelectedconnectorName', data.results[0].connectorName);
      localStorage.setItem('connectorName', ConnectorName);
      var IntegratorID = data.results[0].pk_IntegratorID;
      localStorage.setItem('id', IntegratorID);
      localStorage.setItem('ActiveLiName', 'ConnectorAnalytics');
      localStorage.setItem('erptype', data.results[0].erp);
      localStorage.setItem('erpname', data.results[0].erpName);
      localStorage.setItem('needActivationkey', data.results[0].needActivationkey);
      if (data.results[0].needActivationkey == true)
        this.router.navigate(['/ConnectorAnalytics', IntegratorID])
      else
        this.router.navigate(['/general', IntegratorID])
    })
  }

  isExpired(dates) {
    dates = dates.split('/')[2] + '-' + (((dates.split('/')[0]).length == 1) ? ('0' + (dates.split('/')[0])) : (dates.split('/')[0])) + '-' + (((dates.split('/')[1]).length == 1) ? ('0' + (dates.split('/')[1])) : (dates.split('/')[1])) + 'T00:00:00';
    let expdate = new Date(dates);
    let today = new Date();
    if (today > expdate) {
      return true;
    }
    else {
      return false;
    }
  }
  deactivateIntegrator(integratorid) {
    this.blockUI.start('Processing...');
    this.conService.UpdateIOStatusService(integratorid).subscribe((data) => {
      this.ConnectorDetails();
      this.connectors();
      this.blockUI.stop();
    })
  }

  checkoverstock() {

  }
}