import { Component, OnInit, EventEmitter, Output, Inject } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MapDataComponent } from '../map-data/map-data.component';
import { HttpClient } from '@angular/common/http';
import { ConnectorsService } from '../servicess/connectors.service'
import { BlockUI, NgBlockUI } from 'ng-block-ui';

@Component({
  selector: 'app-cancellation',
  templateUrl: './cancellation.component.html',
  styleUrls: ['./cancellation.component.css']
})
export class CancellationComponent implements OnInit {

  @BlockUI() blockUI: NgBlockUI;
  constructor(private router: ActivatedRoute, public dialog: MatDialog, private route: Router, private http: HttpClient, private connectService: ConnectorsService) { }
  _CancellationImportSubscribed: any = false;
  _CancellationExportSubscribed: any = false;
  _CancellationImportEnabled: any = false;
  _CancellationExportEnabled: any = false;

  _StandFieldMappingSrcJSON: Array<any> = [];
  _StandFieldMappingDstJSON: Array<any> = [];
  _MarketPlaceFieldMapping: Array<any> = [];
  _CancellationImportRunning: any = false;
  _CancellationExportRunning: any = false;
  _importLockFieldMappingResult: Array<any> = [];
  _importStdFieldMappingResult: Array<any> = [];
  _exportLockFieldMappingResult: Array<any> = [];
  _exportStdFieldMappingResult: Array<any> = [];
  _updatedImportFieldMapping: Array<any> = [];
  _updatedExportFieldMapping: Array<any> = [];
  _connectorData: any;
  _flowNames: Array<any> = [];
  _importFlowName: any;
  _exportFlowName: any;
  _SchedulerFrequencyList: any = [];
  _SchedulerStartTimeList: any;
  _SchedulerDaysList: any;
  _importScheduleTime: any = [{ "Date": "", "Frequency": "", "Time": "", "Days": "" }];
  _exportScheduleTime: any = [{ "Date": "", "Frequency": "", "Time": "", "Days": "" }];
  _importScheduleTimeDays: any = [];
  _exportScheduleTimeDays: any = [];
  _cancelImportRunning: any = false;
  _cancelExportRunning: any = false;
  _importProcessingName: any; _exportProcessingName: any;
  _serviceAPI: any = {
    cancellationImport: "OrderCancelsImport",
    cancellationExport: "ordercancelsExport"
  };
  _connectorID: any = "";
  ngOnInit() {
    const parameters = window.location.href;
    var pieces = parameters.split(/[\s/]+/);
    this._connectorID = (pieces[pieces.length - 1]);
    localStorage.setItem('ActiveLiName', 'cancel');
    //this.blockUI.start('Loading...');
    this.GetMappingFeilds()
    this.connectorsList()
    this.GetSchedulerData()
  }
  GetSchedulerData() {
    var Result = this.connectService.GetSchedulerListItems();
    this._SchedulerFrequencyList = (Result[0].Frequency);
    this._SchedulerStartTimeList = (Result[0].Time);
    this._SchedulerDaysList = (Result[0].DayList);
  }
  GetMappingFeilds() {

  }
  connectorsList() {
    this.connectService.ListConnector(this._connectorID).subscribe((data: any) => {

      this._flowNames = JSON.parse(data.results[0].flowNames);
      this._importFlowName = (this._flowNames[0]).CancellationsImport;
      this._exportFlowName = (this._flowNames[0]).CancellationsExport;
      this._serviceAPI = {
        cancellationImport: (data.results[0].api_Cancellation_Import != "" && data.results[0].api_Cancellation_Import != null) ? data.results[0].api_Cancellation_Import : "OrderCancelsImport",
        cancellationExport: (data.results[0].api_Cancellation_Export != "" && data.results[0].api_Cancellation_Export != null) ? data.results[0].api_Cancellation_Export : "ordercancelsExport",
      };
      var MappingFields = JSON.parse(data.results[0].connectorFields);
      for (var k = 0; k < MappingFields.length; k++) {
        if ((MappingFields[k].RecordType.toUpperCase()) == 'CANCELLATION') {
          this._MarketPlaceFieldMapping.push(MappingFields[k].Title)
        }
      }
      this._CancellationImportRunning = (data.results[0].cancellation_ImportRunning)
      this._CancellationExportRunning = (data.results[0].cancellation_ExportRunning)
      this._connectorData = data;
      if (data.results[0].s_Cancellation_Import == true || data.results[0].add_Cancellation_Import == true)
        this._CancellationImportSubscribed = true;
      if (data.results[0].s_Cancellation_Export == true || data.results[0].add_Cancellation_Export == true)
        this._CancellationExportSubscribed = true;

      if (data.results[0].cancellation_Import == true)
        this._CancellationImportEnabled = true;
      if (data.results[0].cancellation_Export == true)
        this._CancellationExportEnabled = true;

      if (this._connectorData.results[0].cancellation_ImportFieldsMapping != null && this._connectorData.results[0].cancellation_ImportFieldsMapping != "") {
        var FieldsMapping = JSON.parse(this._connectorData.results[0].cancellation_ImportFieldsMapping)
        var ItemImportJsonMapping = FieldsMapping.FieldMapping;
        for (var i = 0; i < ItemImportJsonMapping.length; i++) {
          if (ItemImportJsonMapping[i].fieldType == 'Lock')
            this._importLockFieldMappingResult.push({ MarketPlace: ItemImportJsonMapping[i].MarketPlace, NetSuite: ItemImportJsonMapping[i].NetSuite, Default: ItemImportJsonMapping[i].Default });
          if (ItemImportJsonMapping[i].fieldType == 'Standard')
            this._importStdFieldMappingResult.push({ MarketPlace: ItemImportJsonMapping[i].MarketPlace, NetSuite: ItemImportJsonMapping[i].NetSuite, Default: ItemImportJsonMapping[i].Default });
        }
      }
      if (this._connectorData.results[0].cancellation_ExportFieldsMapping != null && this._connectorData.results[0].cancellation_ExportFieldsMapping != "") {
        var ExFieldsMapping = JSON.parse(this._connectorData.results[0].cancellation_ExportFieldsMapping);
        var ItemExportJsonMapping = ExFieldsMapping.FieldMapping;
        for (var i = 0; i < ItemExportJsonMapping.length; i++) {
          if (ItemExportJsonMapping[i].fieldType == 'Lock')
            this._exportLockFieldMappingResult.push({ NetSuite: ItemExportJsonMapping[i].NetSuite, MarketPlace: ItemExportJsonMapping[i].MarketPlace, Default: ItemExportJsonMapping[i].Default });
          if (ItemExportJsonMapping[i].fieldType == 'Standard')
            this._exportStdFieldMappingResult.push({ NetSuite: ItemExportJsonMapping[i].NetSuite, MarketPlace: ItemExportJsonMapping[i].MarketPlace, Default: ItemExportJsonMapping[i].Default });
        }
      }
      this._importStdFieldMappingResult.push({ MarketPlace: '', NetSuite: '', Default: '' });
      this._exportStdFieldMappingResult.push({ NetSuite: '', MarketPlace: '', Default: '' });

      if (this._connectorData.results[0].cancellation_ExportScheduletime != null) {
        this._exportScheduleTime = (JSON.parse(this._connectorData.results[0].cancellation_ExportScheduletime));
        this._exportScheduleTimeDays = this._exportScheduleTime[0].Days.split(',');
      }
      if (this._connectorData.results[0].cancellation_ImportScheduletime != null) {
        this._importScheduleTime = (JSON.parse(this._connectorData.results[0].cancellation_ImportScheduletime));
        this._importScheduleTimeDays = this._importScheduleTime[0].Days.split(',');
      }
    })
  }
  UpdateFlowStatus(FlowUpdateVariable) {
    this.connectService.UpdateFlowStatusByConnector(FlowUpdateVariable, this._connectorID).subscribe((data: any) => {

    })
  }

  _OnChangeStandardImportFieldMapping() {
    var index_id = (this._importStdFieldMappingResult.length) - 1;
    var SiD = "StdSrc_" + index_id;
    var DiD = "StdDst_" + index_id;
    var DfD = "DFTStd_" + index_id;
    var S_e = (document.getElementById("" + SiD)) as HTMLSelectElement;
    var S_sel = S_e.selectedIndex;
    var S_opt = S_e.options[S_sel];
    var ExpValue = (<HTMLInputElement>document.getElementById(DiD)).value;
    var DestValue = (<HTMLInputElement>document.getElementById(DfD)).value;
    if (ExpValue != "" && (((<HTMLOptionElement>S_opt).value) != "" || DestValue != "")) {
      this._importStdFieldMappingResult.push({ MarketPlace: '', NetSuite: '', Default: '' });
    }
  }

  _OnChangeStandardExportFieldMapping() {
    var index_id = (this._exportStdFieldMappingResult.length) - 1;
    var SiD = "ExStdSrc_" + index_id;
    var DiD = "ExStdDst_" + index_id;
    var DfD = "DFTStdEx_" + index_id;
    var D_e = (document.getElementById("" + DiD)) as HTMLSelectElement;
    var D_sel = D_e.selectedIndex;
    var D_opt = D_e.options[D_sel];
    var SourceValue = (<HTMLInputElement>document.getElementById(SiD)).value;
    var DestValue = ((<HTMLOptionElement>D_opt).value)
    var DefaultValue = (<HTMLInputElement>document.getElementById(DfD)).value;
    if (DestValue != "" && (SourceValue != "" || DefaultValue != "")) {
      this._exportStdFieldMappingResult.push({ NetSuite: '', MarketPlace: '', Default: '' });
    }
  }

  PostImportFieldMapping() {
    this._updatedImportFieldMapping = [];
    for (var i = 0; i < this._importStdFieldMappingResult.length; i++) {
      var SiD = "StdSrc_" + i;
      var DiD = "StdDst_" + i;
      var DfD = "DFTStd_" + i;
      var S_e = (document.getElementById("" + SiD)) as HTMLSelectElement;
      var S_sel = S_e.selectedIndex;
      var S_opt = S_e.options[S_sel];
      var ExpValue = (<HTMLInputElement>document.getElementById(DiD)).value;
      var DestValue = (<HTMLInputElement>document.getElementById(DfD)).value;
      if (ExpValue != "" && (((<HTMLOptionElement>S_opt).value) != "" || DestValue != "")) {
        this._updatedImportFieldMapping.push({ MarketPlace: ((<HTMLOptionElement>S_opt).value), NetSuite: ExpValue, Default: DestValue, fieldType: 'Standard' })
      }
    }

    for (var j = 0; j < this._importLockFieldMappingResult.length; j++) {
      var SiD = "SpnImpLockSrc_" + j;
      var DiD = "SpnImpLockDes_" + j;
      var DfD = "SpnImLockDef_" + j;
      var SIMLockValue = (<HTMLInputElement>document.getElementById(SiD)).innerHTML
      var DefIMLockValue = (<HTMLInputElement>document.getElementById(DiD)).innerHTML
      var DesIMLockValue = (<HTMLInputElement>document.getElementById(DfD)).innerHTML
      this._updatedImportFieldMapping.push({ MarketPlace: SIMLockValue, NetSuite: DefIMLockValue, Default: DesIMLockValue, fieldType: 'Lock' })
    }
    var FielaMappingJson = ({ Source: 'MarketPlace', Destination: 'NetSuite', FieldMapping: this._updatedImportFieldMapping });
    this.connectService.Srv_PostImportFieldMapping(FielaMappingJson, "cancellation_ImportFieldsMapping", this._connectorID).subscribe((data: any) => {

    })
  }
  PostExportFieldMapping() {
    this._updatedExportFieldMapping = [];
    for (var i = 0; i < this._exportStdFieldMappingResult.length; i++) {
      var SiD = "ExStdSrc_" + i;
      var DiD = "ExStdDst_" + i;
      var DfD = "DFTStdEx_" + i;
      var D_e = (document.getElementById("" + DiD)) as HTMLSelectElement;
      var D_sel = D_e.selectedIndex;
      var D_opt = D_e.options[D_sel];
      var SourceValue = (<HTMLInputElement>document.getElementById(SiD)).value;
      var DestValue = ((<HTMLOptionElement>D_opt).value)
      var DefaultValue = (<HTMLInputElement>document.getElementById(DfD)).value;
      if (DestValue != "" && (SourceValue != "" || DefaultValue != "")) {
        this._updatedExportFieldMapping.push({ NetSuite: SourceValue, MarketPlace: DestValue, Default: DefaultValue, fieldType: 'Standard' })
      }
    }
    for (var k = 0; k < this._exportLockFieldMappingResult.length; k++) {
      var SiD = "SpnExpLockSrc_" + k;
      var DiD = "SpnExpLockDes_" + k;
      var DfD = "SpnExpLockDef_" + k;
      var SIMLockValue = (<HTMLInputElement>document.getElementById(SiD)).innerHTML
      var DefIMLockValue = (<HTMLInputElement>document.getElementById(DiD)).innerHTML
      var DesIMLockValue = (<HTMLInputElement>document.getElementById(DfD)).innerHTML
      this._updatedExportFieldMapping.push({ NetSuite: SIMLockValue, MarketPlace: DefIMLockValue, Default: DesIMLockValue, fieldType: 'Lock' })
    }
    var FielaMappingJson = ({ Source: 'NetSuite', Destination: 'MarketPlace', FieldMapping: this._updatedExportFieldMapping });
    this.connectService.Srv_PostImportFieldMapping(FielaMappingJson, "cancellation_ExportFieldsMapping", this._connectorID).subscribe((data: any) => {

    })
  }
  PostIESchedulerJSON() {
    var StandardItemImport = [];
    StandardItemImport.push({ "Date": (<HTMLInputElement>document.getElementById("_IEIS_ScheduleFrom")).value, "Frequency": this.GetSelectedValueFromId("_IEIS_Frequency"), "Time": this.GetSelectedValueFromId("_IEIS_Time"), "Days": this.GetSelectedMultipleValuesFromID("_IEIS_Days") })
    this.connectService.Srv_PostImportFieldMapping(StandardItemImport, "Cancellation_ExportScheduletime", this._connectorID).subscribe((data: any) => {

    })
  }
  PostIISchedulerJSON() {
    var StandardItemImport = [];
    StandardItemImport.push({ "Date": (<HTMLInputElement>document.getElementById("_IMIS_ScheduleFrom")).value, "Frequency": this.GetSelectedValueFromId("_IMIS_Frequency"), "Time": this.GetSelectedValueFromId("_IMIS_Time"), "Days": this.GetSelectedMultipleValuesFromID("_IMIS_Days") })
    this.connectService.Srv_PostImportFieldMapping(StandardItemImport, "Cancellation_ImportScheduletime", this._connectorID).subscribe((data: any) => {

    })
  }
  RemoveDivById(divString: any, i) {
    if (divString.length - 1 == i) {

    }
    else {
      var ans = confirm('Are you sure you want to remove this field?');
      if (ans) {
        divString.splice(i, 1);
      }
    }
  }
  GetSelectedValueFromId(ID) {
    try {
      var D_e = (document.getElementById(ID)) as HTMLSelectElement;
      var D_sel = D_e.selectedIndex;
      var D_opt = D_e.options[D_sel];
      var DestValue = ((<HTMLOptionElement>D_opt).value)
      return DestValue;
    }
    catch (e) {
      return "";
    }
  }
  GetSelectedMultipleValuesFromID(ID) {
    let selectElement = (document.getElementById(ID)) as HTMLSelectElement;
    let selectedValues = Array.from(selectElement.selectedOptions).map(option => option.value);
    return ((selectedValues).toString());
  }
  CheckInArray(Arr, Value) {
    Arr = Arr.map(String);
    if (Arr.indexOf(Value.toString()) > -1) {
      return true;
    }
    return false;
  }
  RunIntegrator(ApiMethodName, RunningMethodName, ProcessingName) {
    this['' + RunningMethodName] = true;
    this['' + ProcessingName] = 'Initiated...';
    setTimeout(() => {
      setTimeout(() => {
        this['' + RunningMethodName] = false;
      }, 3000)
      this['' + ProcessingName] = 'Processing...';
    }, 3000)
    this.connectService.RunIntegrator(ApiMethodName, this._connectorID).subscribe((data: any) => {
    
    })
  }
}
