import { Component, OnInit, EventEmitter, Output, Inject } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MapDataComponent } from '../map-data/map-data.component';
import { HttpClient } from '@angular/common/http';
import { ConnectorsService } from '../servicess/connectors.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';

@Component({
  selector: 'app-masters',
  templateUrl: './masters.component.html',
  styleUrls: ['./masters.component.css']
})
export class MastersComponent implements OnInit {
  @BlockUI() blockUI: NgBlockUI;
  constructor(private router: ActivatedRoute, public dialog: MatDialog, private route: Router, private http: HttpClient, private connectService: ConnectorsService) { }

  _erpName: any = localStorage.getItem('erpname');
  _connectorID: any = "";
  _flowNames: Array<any> = [];
  _SubsidiaryFlowName: any;
  _CustomerFlowName: any;
  _EmployeeFlowName: any;
  _MarketPlaceFields: Array<any> = [];
  _EmployeeMarketPlaceFields: Array<any> = [];
  _CustomerFieldMappingResult: Array<any> = [];
  _EmployeeFieldMappingResult: Array<any> = [];
  _erpFields: Array<any> = [];
  _erpEmployeeFields: Array<any> = [];
  _updatedCustomerFieldMapping: Array<any> = [];
  _updatedEmployeeFieldMapping: Array<any> = [];
  _customerExportRunning: any = false;
  _EmployeeExportRunning: any = false;
  _connectorData: any;
  _customerExportSubscribed: any = false;
  _customerExportEnabled: any = false;
  _employeeExportEnabled: any = false;
  _customerExportScheduleTime: any = [{ "Date": "", "Frequency": "", "Time": "", "Days": "" }];
  _CustomerexportScheduleTimeDays: any = [];
  _employeeExportScheduleTime: any = [{ "Date": "", "Frequency": "", "Time": "", "Days": "" }];
  _employeeexportScheduleTimeDays: any = [];
  _SchedulerFrequencyList: any = [];
  _SchedulerStartTimeList: any;
  _SchedulerDaysList: any;
  _serviceAPI: any = {
    subsidiaryexport: "",
    customerexport: "CustomerExport",
    employeeexport: "EmployeeExport"
  };
  _CustexportProcessFlowName: any = '';
  _EmpexportProcessFlowName: any = '';

  ngOnInit() {
    const parameters = window.location.href;
    var pieces = parameters.split(/[\s/]+/);
    this._connectorID = (pieces[pieces.length - 1]);
    this.connectorsList();
    this.GetERPFields();
    this.GetSchedulerData();
  }
  GetSchedulerData() {
    var Result = this.connectService.GetSchedulerListItems();
    this._SchedulerFrequencyList = (Result[0].Frequency);
    this._SchedulerStartTimeList = (Result[0].Time);
    this._SchedulerDaysList = (Result[0].DayList);
  }
  GetERPFields() {
    this.connectService.GetItemFieldMapping('Customer', this._connectorID).subscribe((data: any) => {
      this.blockUI.stop();
      if (data.status != "Error") {
        this._erpFields = data.results.columnStandardFields;
      }
    })
    this.connectService.GetItemFieldMapping('Employee', this._connectorID).subscribe((data: any) => {
      this.blockUI.stop();
      if (data.status != "Error") {
        this._erpEmployeeFields = data.results.columnStandardFields;
      }
    })

  }
  CheckInArray(Arr, Value) {
    Arr = Arr.map(String);
    if (Arr.indexOf(Value.toString()) > -1) {
      return true;
    }
    return false;
  }
  connectorsList() {
    this.connectService.ListConnector(this._connectorID).subscribe((data: any) => {
      this._flowNames = JSON.parse(data.results[0].flowNames);
      this._SubsidiaryFlowName = (this._flowNames[0]).SubsidiaryExport;
      this._CustomerFlowName = (this._flowNames[0]).CustomerExport;
      this._EmployeeFlowName = (this._flowNames[0]).EmployeesExport;

      var MappingFields = JSON.parse(data.results[0].connectorFields);
      for (var k = 0; k < MappingFields.length; k++) {
        if ((MappingFields[k].RecordType.toUpperCase()) == 'CUSTOMER' && (MappingFields[k].IsAccessible.toUpperCase()) == "PUBLIC") {
          this._MarketPlaceFields.push({ label: MappingFields[k].Label, value: MappingFields[k].Title })
        }
      }

      for (var k = 0; k < MappingFields.length; k++) {
        if ((MappingFields[k].RecordType.toUpperCase()) == 'EMPLOYEE' && (MappingFields[k].IsAccessible.toUpperCase()) == "PUBLIC") {
          this._EmployeeMarketPlaceFields.push({ label: MappingFields[k].Label, value: MappingFields[k].Title })
        }
      }

      this._customerExportRunning = (data.results[0].customer_ExportRunning)
      this._EmployeeExportRunning = (data.results[0].employee_ExportRunning)
      this._connectorData = data;
      if (data.results[0].s_Customer_Export == true)
        this._customerExportSubscribed = true;

      if (data.results[0].customer_Export == true)
        this._customerExportEnabled = true;

      if (data.results[0].employee_Export == true)
        this._employeeExportEnabled = true;

      if (this._connectorData.results[0].customer_ExportFieldsMapping != null && this._connectorData.results[0].customer_ExportFieldsMapping != "") {
        var CustomerExFieldsMapping = JSON.parse(this._connectorData.results[0].customer_ExportFieldsMapping);
        for (var i = 0; i < CustomerExFieldsMapping.length; i++) {
          this._CustomerFieldMappingResult.push({ label: CustomerExFieldsMapping[i].label, NetSuite: CustomerExFieldsMapping[i].NetSuite, MarketPlace: CustomerExFieldsMapping[i].MarketPlace, Default: CustomerExFieldsMapping[i].Default });
        }
      }

      if (this._connectorData.results[0].employee_ExportFieldsMapping != null && this._connectorData.results[0].employee_ExportFieldsMapping != "") {
        var employeeExFieldsMapping = JSON.parse(this._connectorData.results[0].employee_ExportFieldsMapping);
        for (var i = 0; i < employeeExFieldsMapping.length; i++) {
          this._EmployeeFieldMappingResult.push({ label: employeeExFieldsMapping[i].label, NetSuite: employeeExFieldsMapping[i].NetSuite, MarketPlace: employeeExFieldsMapping[i].MarketPlace, Default: employeeExFieldsMapping[i].Default });
        }
      }

      this._CustomerFieldMappingResult.push({ MarketPlace: '', NetSuite: '', Default: '' });
      this._EmployeeFieldMappingResult.push({ MarketPlace: '', NetSuite: '', Default: '' });

      if (this._connectorData.results[0].customer_ExportScheduletime != null) {
        this._customerExportScheduleTime = (JSON.parse(this._connectorData.results[0].customer_ExportScheduletime));
        this._CustomerexportScheduleTimeDays = this._customerExportScheduleTime[0].Days.split(',');
      }
      if (this._connectorData.results[0].employee_ExportScheduletime != null) {
        this._employeeExportScheduleTime = (JSON.parse(this._connectorData.results[0].employee_ExportScheduletime));
        this._employeeexportScheduleTimeDays = this._employeeExportScheduleTime[0].Days.split(',');
      }
    })
  }

  PostCustomerExportScheduler() {
    var StandardItemImport = [];
    StandardItemImport.push({ "Date": (<HTMLInputElement>document.getElementById("_Customer_ScheduleFrom")).value, "Frequency": this.GetSelectedValueFromId("_customerExp_Frequency"), "Time": this.GetSelectedValueFromId("_Customer_Time"), "Days": this.GetSelectedMultipleValuesFromID("_Customer_Days") })
    this.connectService.Srv_PostImportFieldMapping(StandardItemImport, "Customer_ExportScheduletime", this._connectorID).subscribe((data: any) => {

    })
  }

  PostEmployeeExportScheduler() {
    var StandardItemImport = [];
    StandardItemImport.push({ "Date": (<HTMLInputElement>document.getElementById("_Employee_ScheduleFrom")).value, "Frequency": this.GetSelectedValueFromId("_employeeExp_Frequency"), "Time": this.GetSelectedValueFromId("_employee_Time"), "Days": this.GetSelectedMultipleValuesFromID("_employee_Days") })
    this.connectService.Srv_PostImportFieldMapping(StandardItemImport, "Employee_ExportScheduletime", this._connectorID).subscribe((data: any) => {

    })
  }

  GetSelectedMultipleValuesFromID(ID) {
    let selectElement = (document.getElementById(ID)) as HTMLSelectElement;
    let selectedValues = Array.from(selectElement.selectedOptions).map(option => option.value);
    return ((selectedValues).toString());
  }

  GetSelectedValueFromId(ID) {
    try {
      var D_e = (document.getElementById(ID)) as HTMLSelectElement;
      var D_sel = D_e.selectedIndex;
      var D_opt = D_e.options[D_sel];
      var DestValue = ((<HTMLOptionElement>D_opt).value)
      return DestValue;
    }
    catch (e) {
      return "";
    }
  }

  _OnChangeCustomerMapping() {
    var index_id = (this._CustomerFieldMappingResult.length) - 1;
    var SiD = "custErpField_" + index_id;
    var DiD = "CustMarketField_" + index_id;
    var DfD = "CustDefault_" + index_id;
    var D_e = (document.getElementById("" + DiD)) as HTMLSelectElement;
    var D_sel = D_e.selectedIndex;
    var D_opt = D_e.options[D_sel];
    var SourceValue = (<HTMLInputElement>document.getElementById(SiD)).value;
    var DestValue = ((<HTMLOptionElement>D_opt).value)
    var DefaultValue = (<HTMLInputElement>document.getElementById(DfD)).value;
    if (DestValue != "" && (SourceValue != "" || DefaultValue != "")) {
      this._CustomerFieldMappingResult.push({ NetSuite: '', MarketPlace: '', Default: '' });
    }
  }
  PostCustomerFieldMapping() {
    this._updatedCustomerFieldMapping;
    for (var i = 0; i < this._CustomerFieldMappingResult.length; i++) {
      var SiD = "custErpField_" + i;
      var DiD = "CustMarketField_" + i;
      var DfD = "CustDefault_" + i;
      var D_e = (document.getElementById("" + DiD)) as HTMLSelectElement;
      var D_sel = D_e.selectedIndex;
      var D_opt = D_e.options[D_sel];
      var SourceValue = this.GetValueFromArray(this._erpFields, ((<HTMLInputElement>document.getElementById(SiD)).value));
      var DataType = this.GetFieldTypeFromArray(this._erpFields, ((<HTMLInputElement>document.getElementById(SiD)).value));
      var DestValue = ((<HTMLOptionElement>D_opt).value)
      var DefaultValue = (<HTMLInputElement>document.getElementById(DfD)).value;
      if (DestValue != "" && (SourceValue != "" || DefaultValue != "")) {
        if ((this.MarketPlaceFieldExists(this._updatedCustomerFieldMapping, DestValue)) == false) {
          this._updatedCustomerFieldMapping.push({ label: (<HTMLInputElement>document.getElementById(SiD)).value, NetSuite: SourceValue, MarketPlace: DestValue, Default: DefaultValue, fieldType: 'Standard', DataType: DataType })
        }
      }
    }
    this.connectService.Srv_PostImportFieldMapping(this._updatedCustomerFieldMapping, "Customer_ExportFieldsMapping", this._connectorID).subscribe((data: any) => {
      this.connectorsList();
    })
  }

  _OnChangeEmployeeMapping() {
    var index_id = (this._EmployeeFieldMappingResult.length) - 1;
    var SiD = "empErpField_" + index_id;
    var DiD = "empMarketField_" + index_id;
    var DfD = "empDefault_" + index_id;
    var D_e = (document.getElementById("" + DiD)) as HTMLSelectElement;
    var D_sel = D_e.selectedIndex;
    var D_opt = D_e.options[D_sel];
    var SourceValue = (<HTMLInputElement>document.getElementById(SiD)).value;
    var DestValue = ((<HTMLOptionElement>D_opt).value)
    var DefaultValue = (<HTMLInputElement>document.getElementById(DfD)).value;
    if (DestValue != "" && (SourceValue != "" || DefaultValue != "")) {
      this._EmployeeFieldMappingResult.push({ NetSuite: '', MarketPlace: '', Default: '' });
    }
  }

  PostEmployeeFieldMapping() {
    this._updatedEmployeeFieldMapping;
    for (var i = 0; i < this._EmployeeFieldMappingResult.length; i++) {
      var SiD = "empErpField_" + i;
      var DiD = "empMarketField_" + i;
      var DfD = "empDefault_" + i;
      var D_e = (document.getElementById("" + DiD)) as HTMLSelectElement;
      var D_sel = D_e.selectedIndex;
      var D_opt = D_e.options[D_sel];
      var SourceValue = this.GetValueFromArray(this._erpFields, ((<HTMLInputElement>document.getElementById(SiD)).value));
      var DataType = this.GetFieldTypeFromArray(this._erpFields, ((<HTMLInputElement>document.getElementById(SiD)).value));
      var DestValue = ((<HTMLOptionElement>D_opt).value)
      var DefaultValue = (<HTMLInputElement>document.getElementById(DfD)).value;
      if (DestValue != "" && (SourceValue != "" || DefaultValue != "")) {
        if ((this.MarketPlaceFieldExists(this._updatedEmployeeFieldMapping, DestValue)) == false) {
          this._updatedEmployeeFieldMapping.push({ label: (<HTMLInputElement>document.getElementById(SiD)).value, NetSuite: SourceValue, MarketPlace: DestValue, Default: DefaultValue, fieldType: 'Standard', DataType: DataType })
        }
      }
    }
    this.connectService.Srv_PostImportFieldMapping(this._updatedEmployeeFieldMapping, "Employee_ExportFieldsMapping", this._connectorID).subscribe((data: any) => {
      this.connectorsList();
    })
  }
  UpdateFlowStatus(FlowUpdateVariable) {
    this.connectService.UpdateFlowStatusByConnector(FlowUpdateVariable, this._connectorID).subscribe((data: any) => {

    })
  }

  MarketPlaceFieldExists(Arr, value) {
    if (value != "") {
      return Arr.some(function (el) {
        var status = (el.MarketPlace === value);
        return status;
      });
    }
    else {
      return false;
    }
  }

  GetFieldTypeFromArray(JsonArray, Value) {
    if (Value) {
      var index = -1;
      var filteredObj = JsonArray.find(function (item, i) {
        if ((item.fieldName).toUpperCase() === Value.toUpperCase()) {
          index = i;
        }
      });
      if (index == -1) {
        return Value;
      }
      else {
        return JsonArray[index].fieldType;
      }
    }
    else {
      return "";
    }
  }
  GetValueFromArray(JsonArray, Value) {
    if (Value) {
      var index = -1;
      var filteredObj = JsonArray.find(function (item, i) {
        if ((item.fieldName).toUpperCase() === Value.toUpperCase()) {
          index = i;
        }
      });
      if (index == -1)
        return Value;
      else
        return JsonArray[index].scriptId;
    }
    else {
      return "";
    }
  }

  RemoveDivById(divString: any, i) {
    if (divString.length - 1 == i) {

    }
    else {
      var ans = confirm('Are you sure you want to remove this field?');
      if (ans) {
        divString.splice(i, 1);
      }
    }
  }

  RunIntegrator(ApiMethodName, RunningMethodName, ProcessingName) {
    this['' + RunningMethodName] = true;
    this['' + ProcessingName] = 'Initiated...';
    setTimeout(() => {
      setTimeout(() => {
        this['' + RunningMethodName] = false;
      }, 3000)
      this['' + ProcessingName] = 'Processing...';
    }, 3000)
    this.connectService.RunIntegrator(ApiMethodName, this._connectorID).subscribe((data: any) => {

    })
  }

}
