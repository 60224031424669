import { Component, OnInit, OnDestroy } from '@angular/core';
import { ConnectorsService } from '../servicess/connectors.service';
import { takeUntil, take } from 'rxjs/operators';
import { Subject, timer, Subscription } from 'rxjs';
@Component({
  selector: 'app-inactive-timer',
  templateUrl: './inactive-timer.component.html',
  styleUrls: ['./inactive-timer.component.css']
})
export class InactiveTimerComponent implements OnInit, OnDestroy {

  constructor(private connectorServices: ConnectorsService) { }
  minutesDisplay = 0;
  secondsDisplay = 0;

  endTime = 30;

  unsubscribe$: Subject<void> = new Subject();
  timerSubscription: Subscription;

  ngOnInit() {
    this.resetTimer();
    this.connectorServices.userActionOccured.pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe(() => {
      if (this.timerSubscription) {
        this.timerSubscription.unsubscribe();
      }
      this.resetTimer();
    });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  resetTimer(endTime: number = this.endTime) {
    const interval = 1000;
    const duration = endTime * 60;
    this.timerSubscription = timer(0, interval).pipe(
      take(duration)
    ).subscribe(value =>
      this.render((duration - +value) * interval),
      err => { },
      () => {
        this.connectorServices.automaticlogout();
      }
    )
  }

  private render(count) {
    this.secondsDisplay = this.getSeconds(count);
    this.minutesDisplay = this.getMinutes(count);
  }

  private getSeconds(ticks: number) {
    const seconds = ((ticks % 60000) / 1000).toFixed(0);
    return this.pad(seconds);
  }

  private getMinutes(ticks: number) {
    const minutes = Math.floor(ticks / 60000);
    return this.pad(minutes);
  }

  private pad(digit: any) {
    return digit <= 9 ? '0' + digit : digit;
  }

}
