import { Component, ViewChild, OnInit, AfterViewInit, ElementRef } from '@angular/core';
import * as Chart from 'chart.js'

@Component({
  selector: 'app-connector-dashboard',
  templateUrl: './connector-dashboard.component.html',
  styleUrls: ['./connector-dashboard.component.css']
})
export class ConnectorDashboardComponent implements OnInit {
  piecanvas: any;
  piectx: any;
  constructor() { }
  ngAfterViewInit() {

  }
  ngOnInit() {
    this.displayPiechart()
  }
  displayPiechart() {
    this.piecanvas = document.getElementById('myChart');
    this.piectx = this.piecanvas.getContext('2d');
    let myChart = new Chart(this.piectx, {
      type: 'pie',
      data: {
        labels: ["New", "In Progress", "On Hold"],
        datasets: [{
          label: '# of Votes',
          data: [1, 2, 3],
          backgroundColor: [
            'rgba(255, 99, 132, 1)',
            'rgba(54, 162, 235, 1)',
            'rgba(255, 206, 86, 1)'
          ],
          borderWidth: 1
        }]
      },
      options: {
        responsive: false
      }
    });
  }
  displaybarchart() { }
}
