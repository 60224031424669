import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { SignupComponent } from './signup/signup.component';
import { NetSuiteDetailsComponent } from './net-suite-details/net-suite-details.component';
import { ForgotpassComponent } from './forgotpass/forgotpass.component';
import { GettComponent } from './gett/gett.component';
import { HomeComponent } from './home/home.component';
import { MarketPlaceComponent } from './market-place/market-place.component';
import { WebStoresComponent } from './web-stores/web-stores.component';
import { ThreePLComponent } from './three-pl/three-pl.component';
import { OtherConnectorsComponent } from './other-connectors/other-connectors.component';
import { DynamicComponent } from './layout/dynamic/dynamic.component';
import { SupportComponent } from './support/support.component';
import { WhatsnewComponent } from './whatsnew/whatsnew.component';
import { IpassconnectorsComponent } from './ipassconnectors/ipassconnectors.component';
import { EmailvalidComponent } from './emailvalid/emailvalid.component';
import { SidenavComponent } from './sidenavlayout/sidenav/sidenav.component';
import { SidenavbarComponent } from './sidenavlayout/sidenavbar/sidenavbar.component';
import { AuthGuard } from './guard/auth.guard';
import { HomeeComponent } from './HOMEE/homee/homee.component';
import { PagenotfoundComponent } from './pagenotfound/pagenotfound.component';
import { LogoutComponent } from './exit/logout/logout.component';
import { AutologoutComponent } from './exit/autologout/autologout.component';
import { ResendEmailComponent } from './resend-email/resend-email.component';
import { GeneralComponent } from './general/general.component';
import { OrderComponent } from './order/order.component';
import { FulfillmentComponent } from './fulfillment/fulfillment.component';
import { InventoryComponent } from './inventory/inventory.component';
import { BillingsComponent } from './billings/billings.component';
import { ProductComponent } from './product/product.component';
import { ImagesComponent } from './images/images.component';
import { CancellationComponent } from './cancellation/cancellation.component';
import { RefundComponent } from './refund/refund.component';
import { ErrorsComponent } from './errors/errors.component';
import { SubscriptionComponent } from './subscription/subscription.component';
import { ShipmentComponent } from './shipment/shipment.component';
import { OverviewComponent } from './overview/overview.component';
import { AccessKeysComponent } from './access-keys/access-keys.component';
import { RaiseTicketComponent } from './raise-ticket/raise-ticket.component';
import { AdduserComponent } from './adduser/adduser.component';
import { FaqComponent } from './faq/faq.component';
import { SetpasswordComponent } from './setpassword/setpassword.component';
import { CustomersComponent } from './customers/customers.component';
import { AdminDashboardComponent } from './admin-dashboard/admin-dashboard.component';
import { AdminParentDirectoryComponent } from './AdminLayout/admin-parent-directory/admin-parent-directory.component';
import { RaiseQuoteComponent } from './raise-quote/raise-quote.component';
import { ConnectorsComponent } from './connectors/connectors.component';
import { DefaultMappingsComponent } from './default-mappings/default-mappings.component';
import { CustomerDetailsComponent } from './customer-details/customer-details.component';
import { SupportTicketsComponent } from './support-tickets/support-tickets.component';
import { MyProfileComponent } from './my-profile/my-profile.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { FileCabinetComponent } from './file-cabinet/file-cabinet.component';
import { TestComponent } from './test/test.component';
import { TestcomponentComponent } from './testcomponent/testcomponent.component';
import { NewHomePageComponent } from './new-home-page/new-home-page.component';
import { AuditRecordsComponent } from './audit-records/audit-records.component';
import { ErrorrecordsComponent } from './errorrecords/errorrecords.component';
import { ConnectorDashboardComponent } from './connector-dashboard/connector-dashboard.component';
import { ReportsComponent } from './reports/reports.component';
import { ConnectorAnalyticsComponent } from './connector-analytics/connector-analytics.component';
import { HelpComponent } from './help/help.component';
import { TemplatetwoComponent } from './templatetwo/templatetwo.component';
import { ChatBotsComponent } from './chat-bots/chat-bots.component';
import { AcumaticaCredentialsComponent } from './acumatica-credentials/acumatica-credentials.component';
import { DRNetSuiteConnectionComponent } from './drnet-suite-connection/drnet-suite-connection.component';
import { NetSuiteConnectionsDRComponent } from './net-suite-connections-dr/net-suite-connections-dr.component';
import { MastersComponent } from './masters/masters.component';

const routes: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: 'HomePage', component: NewHomePageComponent },
  { path: 'home', component: HomeeComponent },
  { path: 'login', component: LoginComponent },
  { path: 'signup', component: SignupComponent },
  { path: 'SetPassword', component: SetpasswordComponent },
  { path: 'NetSuiteDetails', component: NetSuiteDetailsComponent },
  { path: 'forgetpassword', component: ForgotpassComponent },
  { path: 'ResetPassword', component: ResetPasswordComponent },
  { path: 'gett', component: GettComponent },
  { path: "active", component: EmailvalidComponent },
  { path: 'resend', component: ResendEmailComponent },
  { path: 'Test', component: TestComponent },
  { path: 'TestingCompomemt', component: TestcomponentComponent },
  { path: 'Templatetwo', component: TemplatetwoComponent },
  { path: 'ChatBots', component: ChatBotsComponent },
  { path: 'DRNetSuiteConnection/:id', component: DRNetSuiteConnectionComponent },
  {
    path: '', component: SidenavComponent,
    children: [
      { path: 'sidebar', component: SidenavbarComponent },
      { path: '', redirectTo: 'Overview', pathMatch: 'full' },
      { path: 'general/:id', component: GeneralComponent },
      { path: 'ConnectorAnalytics/:id', component: ConnectorAnalyticsComponent },
      { path: 'Overview/:id', component: OverviewComponent },
      { path: 'order/:id', component: OrderComponent },
      { path: 'shipment/:id', component: ShipmentComponent },
      { path: 'Masters/:id', component: MastersComponent },
      { path: 'product/:id', component: ProductComponent },
      { path: 'inventory/:id', component: InventoryComponent },
      { path: 'billing/:id', component: BillingsComponent },
      { path: 'images/:id', component: ImagesComponent },
      { path: 'cancel/:id', component: CancellationComponent },
      { path: 'refund/:id', component: RefundComponent },
      { path: 'errors/:id', component: ErrorsComponent },
      { path: 'Auditrecords/:id', component: AuditRecordsComponent },
      { path: 'Errorrecords/:id', component: ErrorrecordsComponent },
      { path: 'ConnectorDS/:id', component: ConnectorDashboardComponent },
      { path: 'NetSuiteConnection/:id', component: NetSuiteConnectionsDRComponent },

    ]
  },
  {
    path: '',
    component: DynamicComponent,
    children: [
      { path: '', redirectTo: '/dashBoard', pathMatch: 'full' },
      { path: 'dashBoard', component: HomeComponent },
      { path: 'market', component: MarketPlaceComponent },
      { path: 'WebStores', component: WebStoresComponent },
      { path: '3PL', component: ThreePLComponent },
      { path: 'Others', component: OtherConnectorsComponent },
      { path: 'whatsnew', component: WhatsnewComponent },
      { path: 'ipasCon', component: IpassconnectorsComponent },
      { path: 'Accesskeys', component: AccessKeysComponent },
      { path: 'AcumaticaDetails', component: AcumaticaCredentialsComponent },
      { path: 'logout', component: LogoutComponent },
      { path: 'Profile', component: MyProfileComponent },
      { path: 'Support', component: RaiseTicketComponent },
      { path: 'AddUser', component: AdduserComponent },
      { path: 'Faq', component: FaqComponent },
      { path: 'RaiseQuote', component: RaiseQuoteComponent },
      { path: 'Reports', component: ReportsComponent },
      { path: 'Help', component: HelpComponent }
    ]
  },
  {
    path: '',
    component: AdminParentDirectoryComponent,
    children: [
      { path: '', redirectTo: '/AdminDashboard', pathMatch: 'full' },
      { path: 'AdminDashboard', component: AdminDashboardComponent, },
      { path: 'Customers', component: CustomersComponent },
      { path: 'Connectors', component: ConnectorsComponent },
      { path: 'DefaultMapping', component: DefaultMappingsComponent },
      { path: 'CustomerDetails', component: CustomerDetailsComponent },
      { path: 'Subscriptions', component: SubscriptionComponent },
      { path: 'SupportTickets/:id', component: SupportTicketsComponent },
      { path: 'Filecabinet', component: FileCabinetComponent },

    ]
  },
  { path: 'not-found', component: PagenotfoundComponent },
  { path: '**', redirectTo: '/not-found' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
