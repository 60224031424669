import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { ConnectorsService } from '../../servicess/connectors.service'
import { Event, NavigationStart, NavigationEnd, NavigationError } from '@angular/router';
import { AuthservService } from '../../servicess/authserv.service';

@Component({
  selector: 'app-sidenavbar',
  templateUrl: './sidenavbar.component.html',
  styleUrls: ['./sidenavbar.component.css']
})
export class SidenavbarComponent implements OnInit {

  constructor(private activeRoute: ActivatedRoute, private route: Router, private connectSer: ConnectorsService, private authServ: AuthservService,) {
    this.route.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {

      }
      if (event instanceof NavigationEnd) {
        var item = (this.route.url).split('/')[1];
        switch (item) {
          case 'Overview': {
            this.ChangeLiActive('Overview')
            break;
          }
          case 'general': {
            this.ChangeLiActive('General')
            break;
          }
          case 'Masters': {
            if (this._mastersTabEnable == true)
              this.ChangeLiActive('Masters')
            break;
          }
          case 'product': {
            if (this._productTabEnable == true)
              this.ChangeLiActive('Product')
            break;
          }
          case 'inventory': {
            if (this._inventoryTabEnable == true)
              this.ChangeLiActive('Inventory')
            break;
          }
          case 'images': {
            if (this._imageTabEnable == true)
              this.ChangeLiActive('Images')
            break;
          }
          case 'order': {
            if (this._ordersTabEnable == true)
              this.ChangeLiActive('Orders')
            break;
          }
          case 'billing': {
            if (this._billingTabEnable == true)
              this.ChangeLiActive('Billing')
            break;
          }
          case 'shipment': {
            if (this._shippingTabEnable == true)
              this.ChangeLiActive('Shipments')
            break;
          }
          case 'cancel': {
            if (this._cancellationTabEnable == true)
              this.ChangeLiActive('cancel')
            break;
          }
          case 'refund': {
            if (this._refundsTabEnable == true)
              this.ChangeLiActive('Refunds')
            break;
          }
          case 'errors': {
            this.ChangeLiActive('Errors')
            break;
          }
          default: {
            //statements; 
            break;
          }
        }

      }

      if (event instanceof NavigationError) {

      }
    });
  }
  public id: any;
  public id1: any;
  public message = "";
  info: any;
  IntegratorConnectionData: any;
  ConnectorDashboardName: any = localStorage.getItem('connectorName');
  clickedItem: any = localStorage.getItem('ActiveLiName');;
  _connectorID: any;
  _drConnectionLink: any = '';
  _dashboardtabroutelink: any = '';
  _overviewtabroutelink: any = '';
  _producttabroutelink: any = '';
  _mastertabroutelink: any = '';
  _inventorytabroutelink: any = '';
  _imagestabroutelink: any = '';
  _orderstabroutelink: any = '';
  _billingtabroutelink: any = '';
  _shipmentstabroutelink: any = '';
  _cancellationstabroutelink: any = '';
  _returnstabroutelink: any = '';
  _audittabroutelink: any = '';
  ngOnInit() {
    const parameters = window.location.href;
    var pieces = parameters.split(/[\s/]+/);
    this._connectorID = (pieces[pieces.length - 1]);
    this.getIntegratorDetails(this._connectorID);
    this._drConnectionLink = '/NetSuiteConnection/' + this._connectorID;
    this._dashboardtabroutelink = '/ConnectorAnalytics/' + this._connectorID;
    this._overviewtabroutelink = '/Overview/' + this._connectorID;
    this._mastertabroutelink = '/Masters/' + this._connectorID;
    this._producttabroutelink = '/product/' + this._connectorID;
    this._inventorytabroutelink = '/inventory/' + this._connectorID;
    this._imagestabroutelink = '/images/' + this._connectorID;
    this._orderstabroutelink = '/order/' + this._connectorID;
    this._billingtabroutelink = '/billing/' + this._connectorID;
    this._shipmentstabroutelink = '/shipment/' + this._connectorID;
    this._cancellationstabroutelink = '/cancel/' + this._connectorID;
    this._returnstabroutelink = '/refund/' + this._connectorID;
    this._audittabroutelink = '/errors/' + this._connectorID;
    this.listData();
  }

  getIntegratorDetails(IntegratorID) {
    this.authServ.getIntegratorDetailsService(IntegratorID).subscribe((data: any) => {
      if (data.results[0].netScoreProduct != null) {
        this.isNetScoreProduct = true;
        this.isNonNetScoreProduct = false;
        this.route.navigate(['/general', IntegratorID])
      }
      else {
        this.isNetScoreProduct = false;
        this.isNonNetScoreProduct = true;
        this.route.navigate(['/ConnectorAnalytics', IntegratorID])
      }
    })
  }

  isinvalid: boolean = true;
  onSubmit() {
  }
  data1: any = [];
  ids: any;

  isNetScoreProduct: any = false;
  isNonNetScoreProduct: any = false;
  order: any;
  fullfillment: any;
  inventory: any;
  billing: any;
  images: any;
  cancel: any;
  refund: any;
  errors: any;
  product: any;
  product1: any;
  general1: any;
  order1: any;
  refund1: any;
  full: any;
  invent: any;
  productTab: any = true;
  _productTabEnable: any = false;
  _mastersTabEnable: any = true;
  _inventoryTabEnable: any = false;
  _imageTabEnable: any = false;
  _ordersTabEnable: any = false;
  _billingTabEnable: any = false;
  _shippingTabEnable: any = false;
  _cancellationTabEnable: any = false;
  _refundsTabEnable: any = false;
  _credentials: any;
  lists() {
    this.route.navigate(['/general', this._connectorID]);
  }

  listData() {
    this.connectSer.ListConnector(this._connectorID).subscribe((data: any) => {
      this.IntegratorConnectionData = data;
      if (this.IntegratorConnectionData.results[0].s_Item_Import || this.IntegratorConnectionData.results[0].s_Item_Export || this.IntegratorConnectionData.results[0].s_Price_Import || this.IntegratorConnectionData.results[0].s_Price_Export)
        this._mastersTabEnable = true;
      if (this.IntegratorConnectionData.results[0].s_Item_Import || this.IntegratorConnectionData.results[0].s_Item_Export || this.IntegratorConnectionData.results[0].s_Price_Import || this.IntegratorConnectionData.results[0].s_Price_Export)
        this._productTabEnable = true;
      if (this.IntegratorConnectionData.results[0].s_Inventory_Import || this.IntegratorConnectionData.results[0].s_Inventory_Export)
        this._inventoryTabEnable = true;
      if (this.IntegratorConnectionData.results[0].s_Image_Import || this.IntegratorConnectionData.results[0].s_Image_Export)
        this._imageTabEnable = true;
      if (this.IntegratorConnectionData.results[0].s_Orders_Import || this.IntegratorConnectionData.results[0].s_Orders_Export)
        this._ordersTabEnable = true;
      if (this.IntegratorConnectionData.results[0].s_Billing_Import || this.IntegratorConnectionData.results[0].s_Billing_Export)
        this._billingTabEnable = true;
      if (this.IntegratorConnectionData.results[0].s_Shipments_Import || this.IntegratorConnectionData.results[0].s_Shipments_Export)
        this._shippingTabEnable = true;
      if (this.IntegratorConnectionData.results[0].s_Cancellation_Import || this.IntegratorConnectionData.results[0].s_Cancellation_Export)
        this._cancellationTabEnable = true;
      if (this.IntegratorConnectionData.results[0].s_Refunds_Import || this.IntegratorConnectionData.results[0].s_Refunds_Export || this.IntegratorConnectionData.results[0].s_Returns_Import || this.IntegratorConnectionData.results[0].s_Returns_Export)
        this._refundsTabEnable = true;


      if (this.IntegratorConnectionData.results[0].add_Item_Import || this.IntegratorConnectionData.results[0].add_Item_Export || this.IntegratorConnectionData.results[0].add_Price_Import || this.IntegratorConnectionData.results[0].add_Price_Export)
        this._productTabEnable = true;
      if (this.IntegratorConnectionData.results[0].add_Inventory_Import || this.IntegratorConnectionData.results[0].add_Inventory_Export)
        this._inventoryTabEnable = true;
      if (this.IntegratorConnectionData.results[0].add_Image_Import || this.IntegratorConnectionData.results[0].add_Image_Export)
        this._imageTabEnable = true;
      if (this.IntegratorConnectionData.results[0].add_Orders_Import || this.IntegratorConnectionData.results[0].add_Orders_Export)
        this._ordersTabEnable = true;
      if (this.IntegratorConnectionData.results[0].add_Billing_Import || this.IntegratorConnectionData.results[0].add_Billing_Export)
        this._billingTabEnable = true;
      if (this.IntegratorConnectionData.results[0].add_Shipments_Import || this.IntegratorConnectionData.results[0].add_Shipments_Export)
        this._shippingTabEnable = true;
      if (this.IntegratorConnectionData.results[0].add_Cancellation_Import || this.IntegratorConnectionData.results[0].add_Cancellation_Export)
        this._cancellationTabEnable = true;
      if (this.IntegratorConnectionData.results[0].add_Refunds_Import || this.IntegratorConnectionData.results[0].add_Refunds_Export || this.IntegratorConnectionData.results[0].add_Returns_Import || this.IntegratorConnectionData.results[0].add_Returns_Export)
        this._refundsTabEnable = true;

      this._credentials = data.results[0].credentialAuthJson;
      if (this._credentials == null || this._credentials == "") {
        this._productTabEnable = false;
        this._mastersTabEnable = false;
        this._inventoryTabEnable = false;
        this._imageTabEnable = false;
        this._ordersTabEnable = false;
        this._billingTabEnable = false;
        this._shippingTabEnable = false;
        this._cancellationTabEnable = false;
        this._refundsTabEnable = false;
      }
    })
  }
  onClick(item) {
    switch (item) {
      case 'DRNetSuiteConnection': {
        this.ChangeLiActive(item)
        break;
      }
      case 'ConnectorAnalytics': {
        this.ChangeLiActive(item)
        break;
      }
      case 'Overview': {
        this.ChangeLiActive(item)
        break;
      }
      case 'General': {
        this.ChangeLiActive(item)
        break;
      }
      case 'Master': {
        if (this._mastersTabEnable == true)
          this.ChangeLiActive(item)
        break;
      }
      case 'Product': {
        if (this._productTabEnable == true)
          this.ChangeLiActive(item)
        break;
      }
      case 'Inventory': {
        if (this._inventoryTabEnable == true)
          this.ChangeLiActive(item)
        break;
      }
      case 'Images': {
        if (this._imageTabEnable == true)
          this.ChangeLiActive(item)
        break;
      }
      case 'Orders': {
        if (this._ordersTabEnable == true)
          this.ChangeLiActive(item)
        break;
      }
      case 'Billing': {
        if (this._billingTabEnable == true)
          this.ChangeLiActive(item)
        break;
      }
      case 'Shipments': {
        if (this._shippingTabEnable == true)
          this.ChangeLiActive(item)
        break;
      }
      case 'cancel': {
        if (this._cancellationTabEnable == true)
          this.ChangeLiActive(item)
        break;
      }
      case 'Refunds': {
        if (this._refundsTabEnable == true)
          this.ChangeLiActive(item)
        break;
      }
      case 'Errors': {
        this.ChangeLiActive(item)
        break;
      }
      default: {
        //statements; 
        break;
      }
    }

    // if (item == 'Product') {
    //   if (this._productTabEnable == true) {
    //     this.ChangeLiActive(item)
    //   }
    // }
    // if (item == 'Inventory') {
    //   if (this._inventoryTabEnable == true) {
    //     this.ChangeLiActive(item)
    //   }
    // }
    // if (item == 'Images') {
    //   if (this._imageTabEnable == true) {
    //     this.ChangeLiActive(item)
    //   }
    // }
  }
  ChangeLiActive(item) {
    this.clickedItem = item;
  }
}