import { Component, OnInit } from '@angular/core';
import { AuthservService } from '../../servicess/authserv.service'
import { Router } from "@angular/router";
@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.css']
})
export class LogoutComponent implements OnInit {

  constructor(public authServices: AuthservService, private route: Router) { }

  ngOnInit() {
  }

  logoutUser() {

  }

}

