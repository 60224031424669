import { Component } from '@angular/core';

@Component({
  selector: 'app-spredirect',
  templateUrl: './spredirect.component.html',
  styleUrls: ['./spredirect.component.css']
})
export class SpredirectComponent {

}
