import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router, UrlTree, CanActivate } from '@angular/router';
import { Observable } from 'rxjs';
import { map, tap, take } from 'rxjs/operators';
import { AuthservService } from '../servicess/authserv.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private authservice: AuthservService, private router: Router) {

  }
  a: any;
  canActivate():
    boolean {
    if (this.authservice.loggedIn()) {
      return true;
    }
    else {
      this.router.navigate(['/home'])
      return false
    }
  }
}