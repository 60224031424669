import { ConnectorsService } from '../servicess/connectors.service';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit, ViewChild, AfterViewInit, AfterContentInit, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { NgForm } from '@angular/forms';
import { BehaviorSubject, Observable } from 'rxjs';
import { AuthservService } from '../servicess/authserv.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';

@Component({
  selector: 'app-net-suite-details',
  templateUrl: './net-suite-details.component.html',
  styleUrls: ['./net-suite-details.component.css']
})
export class NetSuiteDetailsComponent implements OnInit {
  id: any;
  submitted: boolean = false;
  Message: any;
  currentTime: any;
  time_Data: Array<any> = [];
  time_parameter: any;
  @ViewChild('f') indexForm: NgForm;
  _JsonBodyData: any;
  @BlockUI() blockUI: NgBlockUI;
  constructor(private authserve: AuthservService, private http: HttpClient, private connectorService: ConnectorsService, private Router: Router, private activatedRoute: ActivatedRoute) {
    this.activatedRoute.queryParams.subscribe(params => {
      this.id = params['id'];
      alert(this.id);
    });
    setTimeout(() => {
      this.CheckToken();
    }, 2000)
  }
  ngOnInit() {
    this.connectorService.GetTimeZones().subscribe((data: any) => {
      if (data.status == "Success") {
        this.time_Data = data.results;
      }
    });
  }
  @HostListener("window:beforeunload", ["$event"]) unloadHandler(event: Event) {
    // Do more processing...
    event.returnValue = false;
  }
  public tzNames: string[];
  public userTz: string;
  public selectedTz: string;
  public utcTz: string;
  public displayTz: string;
  public selectedLocale: string;
  public date: string;
  public fromNow: string;
  public aFormat: string;
  public aUtcFormat: string;
  public aDateFormat: string;
  public aTimeFormat: string;
  onChange(data: any) {
  }

  CheckToken() {
    this.connectorService.CheckUserToken(this.id).subscribe((data: any) => {
      if (data.status == "Success") {
        this.Router.navigate(['/home']);
      }
      else {
        this.Message = data.errorMessage;
      }
    });
  }
  onSubmit() {
    this.VerifyEmail();
  }
  OnSkip() {
    this.blockUI.start('Processing...');
    this.submitted = true;
    this._JsonBodyData = {
      "NS_Account": this.indexForm.value.NS_Account,
      "NS_AppID": this.indexForm.value.NS_AppID,
      "NS_ConsumerKey": this.indexForm.value.NS_ConsumerID,
      "NS_ConsumerSecret": this.indexForm.value.NS_ConsumerSecret,
      "NS_TokenID": this.indexForm.value.NS_TokenID,
      "NS_TokenSecret": this.indexForm.value.NS_TokenSecret,
      "TokenId": this.id,
      "TimeZone": this.indexForm.value.timeZone
    }
    this.connectorService.SkipNsDetails(this._JsonBodyData).subscribe((data: any) => {
      this.blockUI.stop();
      if (data.status == "Success") {
        // this.Message = "Credentials updated.";
        this.indexForm.resetForm();
        this.blockUI.start('Account successfully activated. Redirect to login shortly...');
        setTimeout(() => {
          this.blockUI.stop();
          this.Router.navigate(['/home']);
        }, 2000)
      }
      else {
        this.Message = data.errorMessage;
      }
    }, error => {
      this.blockUI.stop();
    })
  }

  VerifyEmail() {
    this.blockUI.start('Processing...');
    this.submitted = true;
    this._JsonBodyData = {
      "NS_Account": this.indexForm.value.NS_Account,
      "NS_AppID": this.indexForm.value.NS_AppID,
      "NS_ConsumerKey": this.indexForm.value.NS_ConsumerID,
      "NS_ConsumerSecret": this.indexForm.value.NS_ConsumerSecret,
      "NS_TokenID": this.indexForm.value.NS_TokenID,
      "NS_TokenSecret": this.indexForm.value.NS_TokenSecret,
      "TokenId": this.id,
      "TimeZone": this.indexForm.value.timeZone
    }
    this.connectorService.UpdateNsDetails(this._JsonBodyData).subscribe((data: any) => {
      this.blockUI.stop();
      if (data.status == "Success") {
        this.Message = "Credentials updated.";
        this.indexForm.resetForm();
        this.blockUI.start('Account successfully activated. Redirect to login shortly...');
        setTimeout(() => {
          this.blockUI.stop();
          this.Router.navigate(['/home']);
        }, 2000)
      }
      else {
        this.Message = data.errorMessage;
      }
    }, error => {
      this.blockUI.stop();
    })
  }
}