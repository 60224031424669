import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { LogoutComponent } from '../exit/logout/logout.component';
import { AutologoutComponent } from '../exit/autologout/autologout.component';

//const rootURL = "https://localhost:44336/";
//const rootURL = "http://54.164.21.152:8088/";
const rootURL = "https://flexicon.netscoretech.com:446/";

const url = "https://jsonplaceholder.typicode.com/posts?userId=1";
@Injectable({
  providedIn: 'root'
})
export class AuthservService {

  constructor(private http: HttpClient, private route: Router) { }
  headers = new HttpHeaders({
    "Content-Type": "application/json"
  });


  options = { headers: this.headers };

  onGet() {
    return this.http.get(url);
  }

  /*1. signup */
  SignUp(info: any) {
    return this.http.post(rootURL + 'api/User/Register', info, this.options)
  }
  ForgetPassword(domain: any, Username: any) {
    return this.http.post(rootURL + 'api/User/ForgetPassword?domain=' + domain + '&Username=' + Username, "", this.options)
  }

  /*2. Email verify after signup */
  VerifyRequest(id: any) {
    let headers = new HttpHeaders({
      "Content-Type": "application/json"
    });
    let urlStr = rootURL + "api/Customer/EmailVerify?token=" + encodeURIComponent(id);
    return this.http.get(urlStr, {
      headers: headers
    })
  }

  /*3.login */
  Login(info2: any) {
    let data1: any;
    data1 = 'username=' + info2.lg_UserName + '&password=' + info2.lg_Password + '&grant_type=password&client_id=ngAuthApp';
    let headers2 = new HttpHeaders({
      "Content-Type": "application/x-www-form-urlencoded"
    });
    return this.http.post(rootURL + 'token', data1, {
      headers: new HttpHeaders({
        "Content-Type": "application/x-www-form-urlencoded",
      })
    });
  }
  /* refresh token */
  headerss = new HttpHeaders({
    "Content-Type": "application/x-www-form-urlencoded"

  });
  /* RefreshToken() {
     let data2: any;
     let headers2 = new HttpHeaders({
       "Content-Type": "application/x-www-form-urlencoded" 
     });
     data2 = "refresh_token=localStorage.getItem('refresh_token')&grant_type=refresh_token&client_id=ngAuthApp";
      return this.http.post(rootURL+'token',data2,{headers : new HttpHeaders({
        "Content-Type" : "application/x-www-form-urlencoded"
      })})
   } 
 */
  /* Resend EMAIL */
  resendEmail(Domain: any, emailId: any) {
    let headers = new HttpHeaders({
      "Content-Type": "application/json"
    });

    let urlStr = rootURL + "api/Customer/EmailResend?email=" + encodeURIComponent(emailId) + "&Domain=" + encodeURIComponent(Domain);
    return this.http.get(urlStr, {
      headers: headers
    })
  }

  /* 4. Display all iPaas connectors  */



  /* verify login */
  logVerify() {
    let tokenKey = localStorage.getItem('token');
    return this.http.get(rootURL + "api/Customer/EmailVerify?token=" + encodeURIComponent(tokenKey), this.options)
  }

  /* for auth guard */
  loggedIn() {
    return !!localStorage.getItem('token')
  }

  /* verify logout */
  logOutVerify() {
    // let tokenKey1 = localStorage.getItem('token');
    // return this.http.delete(rootURL + "api/RefreshTokens?tokenId=" + tokenKey1, this.options);
  }
  exptimmer: any;

  /* userlogout */
  userLogout() {
    let RefreshToken = localStorage.getItem('refreshToken');
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem("IDs");
    localStorage.clear();
    let customParams = new HttpParams();
    customParams.append("tokenId", encodeURI(RefreshToken));
    this.http.delete(rootURL + "/api/RefreshTokens?tokenId=" + encodeURI(RefreshToken), this.options).subscribe((data: any) => {
      return data;
    }, (err: HttpErrorResponse) => {
      return err;
    })
    this.route.navigate(['/home'])
  }

  /* autologout */
  autoLogout(expTime: any) {
    this.exptimmer = setTimeout(() => {

    }, expTime)
  }

  /* getIntercepts */
  getIntercepts() {

  }

  getConnectors() {
    return this.http.get(rootURL + "api/Customer/GetSubscribedConnectors?customerId=" + localStorage.getItem('customerID'), {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + localStorage.getItem('accessToken')
      })
    })
  }

  showConnectorDetails() {
    return this.http.get(rootURL + "api/Customer/GetSubscribedConnectors?customerId=" + localStorage.getItem('customerID'), {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + localStorage.getItem('accessToken')
      })
    })
  }

  getIntegratorDetailsService(integratorID) {
    return this.http.get(rootURL + "api/Customer/GetIntegratorData?integrationId=" + integratorID, {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + localStorage.getItem('accessToken')
      })
    })
  }
  setaccessToken() {
    /*this.http.get(rootURL).*/
  }
  CheckLoggedUser(username) {
    return this.http.get(rootURL + "api/CheckLoggedUser?username=" + username, {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + localStorage.getItem('accessToken')
      })
    })
  }
}