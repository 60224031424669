import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpHandler, HttpHeaders, HttpErrorResponse, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, flatMap, switchMap, mergeMap } from 'rxjs/operators';
import { AuthservService } from '../servicess/authserv.service';
import { ConnectorsService } from '../servicess/connectors.service';
import { throwError, BehaviorSubject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class AuthinterceptorService {

  constructor(private http: HttpClient, private authenticationService: AuthservService, public connectorService: ConnectorsService) { }
  refreshTokenInProgress: boolean = false;
  refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (localStorage.getItem('refreshToken')) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`
        }
      });
    }
    return next.handle(request).pipe(catchError(err => {
      if (err.status === 401) {
        if (err.error.message == "Authorization has been denied for this request.") {
          this.connectorService.update_AccessToken().subscribe((data: any) => {
            if (data.error == null) {
              let storeObj: any = {};
              storeObj.token = data.access_token;
              storeObj.rToken = data.refresh_token;
              localStorage.setItem('accessToken', data.access_token);
              localStorage.setItem('refreshToken', data.refresh_token);
              localStorage.setItem('IDs', JSON.stringify(storeObj));
              request = request.clone({
                setHeaders: {
                  Authorization: `Bearer ${data.access_token}`
                }
              });
              location.reload(true);
            }
            else {
              //Logout from account
              this.authenticationService.userLogout();
            }
          });
        }
        else {
          this.authenticationService.userLogout();
        }
      }

      else if (err.status === 400) {
        if (err.error.error == "invalid_grant") {
          this.authenticationService.userLogout();
        }
      }
      return Observable.throw(err);
    }))
  }
}
