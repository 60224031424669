import { Component, OnInit } from '@angular/core';
import { ActivatedRoute ,Params} from '@angular/router';


@Component({
  selector: 'app-ipassconnectors',
  templateUrl: './ipassconnectors.component.html',
  styleUrls: ['./ipassconnectors.component.css']
})
export class IpassconnectorsComponent implements OnInit {

  constructor(private route: ActivatedRoute) { }
public myid;
  ngOnInit() {
    this.myid = this.route.snapshot.paramMap.get('id');
   /* alert(this.myid);
    alert("your select id is"+this.myid) */
  }
isvalid = false;
onSubmit() {
  this.isvalid = !this.isvalid;
}
}
