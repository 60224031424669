import { Component, OnInit } from '@angular/core';
import { AuthservService } from '../servicess/authserv.service';
import { ConnectorsService } from '../servicess/connectors.service'
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

@Component({
  selector: 'app-three-pl',
  templateUrl: './three-pl.component.html',
  styleUrls: ['./three-pl.component.css']
})
export class ThreePLComponent implements OnInit {
  constructor(private route: Router, private authserve: AuthservService, private http: HttpClient, private connectService: ConnectorsService) { }
  _connectorslist: any = [];
  _descriptionlist: any = [];
  _datalist: any;
  _datalist_connector: any = "";
  _datalist_descrption: any = "";
  _datalist_descriptionlist: any = {};
  _datalist_logo: any = "";
  _connectorID: any;
  ngOnInit() {
    this.GetConnectors()
  }
  GetConnectors() {
    this.connectService.GetAllConnectors(3).subscribe((data: any) => {
      if (data.status != "Error") {
        this._connectorslist = (data.results);
        this._connectorslist.map((item, i) => {
          this._descriptionlist.push(JSON.parse(this._connectorslist[i].description));
        })
      }
    })
  }
  RaiseQuote(id) {
    this.route.navigate(['/RaiseQuote'], { queryParams: { Connectorid: id } });
  }
  showFullDetails(id) {
    this._datalist = this._connectorslist[id];
    this._datalist_connector = this._connectorslist[id].connectorName;
    this._datalist_logo = this._connectorslist[id].connectorlogo;
    this._datalist_descriptionlist = (JSON.parse(this._connectorslist[id].description))
    this._datalist_descrption = this._datalist_descriptionlist.Description;
    this._connectorID = this._connectorslist[id].pK_ConnectorID;
  }
}