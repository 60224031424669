import { Component, OnInit, EventEmitter, Output, Inject, ViewChild } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MapDataComponent } from '../map-data/map-data.component';
import { HttpClient } from '@angular/common/http';
import { ConnectorsService } from '../servicess/connectors.service';
import { NgxPaginationModule } from 'ngx-pagination';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { from } from 'rxjs';

interface PeriodicElement {
  name: string;
  position: number;
  weight: number;
  symbol: string;
}

@Component({
  selector: 'app-errorrecords',
  templateUrl: './errorrecords.component.html',
  styleUrls: ['./errorrecords.component.css']
})
export class ErrorrecordsComponent implements OnInit {
  dataSourceOne: MatTableDataSource<PeriodicElement>;
  displayedColumnsOne: string[] = ['Process', 'StartTime', 'Processed', 'Failed'];
  @ViewChild('TableOnePaginator') tableOnePaginator: MatPaginator;
  @ViewChild('TableOneSort') tableOneSort: MatSort;
  @BlockUI() blockUI: NgBlockUI;

  dataSourceTwo: MatTableDataSource<PeriodicElement>;
  displayedColumnsTwo: string[] = ['Date', 'Process', 'ErrorMessage', 'Run'];
  @ViewChild('TableTwoPaginator') tableTwoPaginator: MatPaginator;
  @ViewChild('TableTwoSort') tableTwoSort: MatSort;
  constructor(private router: ActivatedRoute, public dialog: MatDialog, private route: Router, private http: HttpClient, private connectSer: ConnectorsService) {
    this.dataSourceOne = new MatTableDataSource;
    this.dataSourceTwo = new MatTableDataSource;
  }
  _AuditLogList: any = [];
  _jsonErrorList: any = [];
  p: any;
  _connectorID: any = "";
  ngOnInit() {
    const parameters = window.location.href;
    var pieces = parameters.split(/[\s/]+/);
    this._connectorID = (pieces[pieces.length - 1]);
    localStorage.setItem('ActiveLiName', 'Errors');
    this.ErrorLogList();
  }

  ErrorLogList() {
    this.connectSer.GetErrorLog(this._connectorID).subscribe((data: any) => {
      if (data.status == "Success") {
        this.dataSourceTwo.data = data.results;
        this.dataSourceTwo.paginator = this.tableTwoPaginator;
        this.dataSourceTwo.sort = this.tableTwoSort;
      }
    })
  }
  GetRecords() {
    var Fromdate = (<HTMLInputElement>document.getElementById("txtFromDate")).value;
    var Todate = (<HTMLInputElement>document.getElementById("txtToDate")).value;
    if (Fromdate != "" && Todate != "") {
      this.blockUI.start('Loading...');
      // this.dataSourceOne.data = null;
      // this.dataSourceOne.paginator = null;
      // this.dataSourceOne.sort = null;
      this.connectSer.GetErrorRecords(Fromdate, Todate, this._connectorID).subscribe((data: any) => {
        if (data.status == "Success") {
          this.blockUI.stop();
          this.dataSourceTwo.data = data.results;
          this.dataSourceTwo.paginator = this.tableTwoPaginator;
          this.dataSourceTwo.sort = this.tableTwoSort;
        }
      })
    }
  }

  applyFilterTwo(filterValue: string) {
    this.dataSourceTwo.filter = filterValue.trim().toLowerCase();
  }
  GetErrorLog(errorData) {
    this._jsonErrorList = JSON.parse(errorData);
  }
  RetryError(Pk_errorId) {
    this.blockUI.start('Background process will start...');
    this.connectSer.OrderReprocess(Pk_errorId, this._connectorID).subscribe((data: any) => {
      if (data.status == "Success") {
        this.blockUI.stop();
        this.blockUI.start('Record reprocess success');
        this.ErrorLogList();
      }
      if (data.status == "Error") {
        this.blockUI.stop();
        this.blockUI.start('Record reprocess failed');
      }
      setTimeout(() => {
        this.blockUI.stop();
        location.reload();
      }, 3000);
    })
  }
  checkProcess(val) {
    if (val != "" && val != null && val != undefined) {
      if ((val.toLowerCase().includes('order')) && (val.toLowerCase().includes('import')))
        return true;
      else
        return false;
    }
    else
      return true;
  }
}
export interface ErrorLog {
  Date: string; Process: string; ErrorMessage: string, Run: string;
}
