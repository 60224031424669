import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthservService } from './servicess/authserv.service';
import { ConnectorsService } from './servicess/connectors.service';
import { GlobalTypeScriptService } from './servicess/global-type-script.service';
import { LoginComponent } from './login/login.component';
import { SignupComponent } from './signup/signup.component';
import { ForgotpassComponent } from './forgotpass/forgotpass.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HttpRequest, HttpHandler, HttpErrorResponse, HttpEvent, HttpHeaders, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MustMatchDirective } from './directivess/must-match.directive';
import { GettComponent } from './gett/gett.component';
import { DynamicComponent } from './layout/dynamic/dynamic.component';
import { HeaderComponent } from './layout/header/header.component';
import { FooterComponent } from './layout/footer/footer.component';
import { HomeComponent } from './home/home.component';
import { MarketPlaceComponent } from './market-place/market-place.component';
import { SupportComponent } from './support/support.component';
import { WhatsnewComponent } from './whatsnew/whatsnew.component';
import { AuthGuard } from './guard/auth.guard';
import { MaterialModule } from './material/material.module';
import { IpassconnectorsComponent } from './ipassconnectors/ipassconnectors.component';
import { EmailvalidComponent } from './emailvalid/emailvalid.component';
import { SidenavComponent } from './sidenavlayout/sidenav/sidenav.component';
import { SidenavbarComponent } from './sidenavlayout/sidenavbar/sidenavbar.component';
import { HomeeComponent } from './HOMEE/homee/homee.component';
import { MatPaginator, MatSort, MatTableDataSource, MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material';
import { MapDataComponent } from './map-data/map-data.component';
import { PagenotfoundComponent } from './pagenotfound/pagenotfound.component';
import { LogoutComponent } from './exit/logout/logout.component';
import { AutologoutComponent } from './exit/autologout/autologout.component';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { ResendEmailComponent } from './resend-email/resend-email.component';
import { ConnectorspopupComponent } from './connectorspopup/connectorspopup.component';
import { LocationStrategy, HashLocationStrategy, PathLocationStrategy } from '@angular/common';
import { ScrollspyDirective } from './directivess/scrollspy.directive';

import { GeneralComponent } from './general/general.component';
import { OrderComponent } from './order/order.component';
import { FulfillmentComponent } from './fulfillment/fulfillment.component';
import { InventoryComponent } from './inventory/inventory.component';
import { BillingsComponent } from './billings/billings.component';
import { ProductComponent } from './product/product.component';
import { ImagesComponent } from './images/images.component';
import { CancellationComponent } from './cancellation/cancellation.component';
import { RefundComponent } from './refund/refund.component';
import { ErrorsComponent } from './errors/errors.component';
import { SubscriptionComponent } from './subscription/subscription.component';
import { SortfilterPipe } from './pipes/sortfilter.pipe';
import { ShipmentComponent } from './shipment/shipment.component';
import { InactiveTimerComponent } from './inactive-timer/inactive-timer.component';
import { BlockUIModule } from 'ng-block-ui';
import { NetSuiteDetailsComponent } from './net-suite-details/net-suite-details.component';
import { OverviewComponent } from './overview/overview.component';
import { AccessKeysComponent } from './access-keys/access-keys.component';
import { RaiseTicketComponent } from './raise-ticket/raise-ticket.component';
import { WebStoresComponent } from './web-stores/web-stores.component';
import { ThreePLComponent } from './three-pl/three-pl.component';
import { OtherConnectorsComponent } from './other-connectors/other-connectors.component';
import { AuthinterceptorService } from './interceptors/authinterceptor.service';
import { AdduserComponent } from './adduser/adduser.component';
import { FaqComponent } from './faq/faq.component';
import { SetpasswordComponent } from './setpassword/setpassword.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { CustomersComponent } from './customers/customers.component';
import { AdminDashboardComponent } from './admin-dashboard/admin-dashboard.component';
import { AdminLayoutHeaderComponent } from './AdminLayout/admin-layout-header/admin-layout-header.component';
import { AdminParentDirectoryComponent } from './AdminLayout/admin-parent-directory/admin-parent-directory.component';
import { RaiseQuoteComponent } from './raise-quote/raise-quote.component';
import { ConnectorsComponent } from './connectors/connectors.component';
import { DefaultMappingsComponent } from './default-mappings/default-mappings.component';
import { CustomerDetailsComponent } from './customer-details/customer-details.component';
import { SupportTicketsComponent } from './support-tickets/support-tickets.component';
import { MyProfileComponent } from './my-profile/my-profile.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { HttpModule } from '@angular/http';
import { FileCabinetComponent } from './file-cabinet/file-cabinet.component';
import { TestComponent } from './test/test.component';
import { RunComponent } from './run/run.component';
import { TestcomponentComponent } from './testcomponent/testcomponent.component';
import { NewHomePageComponent } from './new-home-page/new-home-page.component';
import { AuditRecordsComponent } from './audit-records/audit-records.component';
import { ErrorrecordsComponent } from './errorrecords/errorrecords.component';
import { ConnectorDashboardComponent } from './connector-dashboard/connector-dashboard.component';
import { ReportsComponent } from './reports/reports.component';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { ChartsModule, ThemeService } from 'ng2-charts';
import { ConnectorAnalyticsComponent } from './connector-analytics/connector-analytics.component';
import { HelpComponent } from './help/help.component';
import { TemplatetwoComponent } from './templatetwo/templatetwo.component';
import { ChatBotsComponent } from './chat-bots/chat-bots.component';
import { SpredirectComponent } from './spredirect/spredirect.component';
import { AcumaticaCredentialsComponent } from './acumatica-credentials/acumatica-credentials.component';
import { DRNetSuiteConnectionComponent } from './drnet-suite-connection/drnet-suite-connection.component';
import { NetScoreLayoutComponent } from './net-score-layout/net-score-layout.component';
import { NetSuiteConnectionsDRComponent } from './net-suite-connections-dr/net-suite-connections-dr.component';
import { MastersComponent } from './masters/masters.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    SignupComponent,
    ForgotpassComponent,
    MustMatchDirective,
    GettComponent,
    DynamicComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    MarketPlaceComponent,
    SupportComponent,
    WhatsnewComponent,
    IpassconnectorsComponent,
    EmailvalidComponent,
    GeneralComponent,
    SidenavComponent,
    SidenavbarComponent,
    OrderComponent,
    FulfillmentComponent,
    HomeeComponent,
    MapDataComponent,
    PagenotfoundComponent,
    LogoutComponent,
    AutologoutComponent,
    ResendEmailComponent,
    ConnectorspopupComponent,
    ScrollspyDirective,
    InventoryComponent,
    BillingsComponent,
    ProductComponent,
    ImagesComponent,
    CancellationComponent,
    RefundComponent,
    ErrorsComponent,
    SubscriptionComponent,
    SortfilterPipe,
    ShipmentComponent,
    InactiveTimerComponent,
    NetSuiteDetailsComponent,
    OverviewComponent,
    AccessKeysComponent,
    RaiseTicketComponent,
    WebStoresComponent,
    ThreePLComponent,
    OtherConnectorsComponent,
    AdduserComponent,
    FaqComponent,
    SetpasswordComponent,
    CustomersComponent,
    AdminDashboardComponent,
    AdminLayoutHeaderComponent,
    AdminParentDirectoryComponent,
    RaiseQuoteComponent,
    ConnectorsComponent,
    DefaultMappingsComponent,
    CustomerDetailsComponent,
    SupportTicketsComponent,
    MyProfileComponent,
    ResetPasswordComponent,
    FileCabinetComponent,
    TestComponent,
    RunComponent,
    TestcomponentComponent,
    NewHomePageComponent,
    AuditRecordsComponent,
    ErrorrecordsComponent,
    ConnectorDashboardComponent,
    ReportsComponent,
    ConnectorAnalyticsComponent,
    HelpComponent,
    TemplatetwoComponent,
    ChatBotsComponent,
    SpredirectComponent,
    AcumaticaCredentialsComponent,
    DRNetSuiteConnectionComponent,
    NetScoreLayoutComponent,
    NetSuiteConnectionsDRComponent,
    MastersComponent,

  ],

  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    MaterialModule,
    MatDialogModule,
    Ng2SearchPipeModule,
    ReactiveFormsModule,
    NgxPaginationModule,
    BlockUIModule.forRoot(),
    NgCircleProgressModule.forRoot({
      "outerStrokeLinecap": "butt",
      "showUnits": true,
      "titleFontSize": "30",
      "unitsFontSize": "30",
      'showSubtitle': false
    }),
    ChartsModule
  ],
  entryComponents: [
    MapDataComponent,
    ConnectorspopupComponent
  ],
  providers: [AuthservService, AuthGuard, ConnectorsService, GlobalTypeScriptService, ThemeService,
    { provide: LocationStrategy, useClass: HashLocationStrategy }, { provide: HTTP_INTERCEPTORS, useClass: AuthinterceptorService, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
