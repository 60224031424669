import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConnectorsService } from '../servicess/connectors.service';
import { FormBuilder, FormGroup, FormControl, FormArray, Validators, AbstractControl } from '@angular/forms';
import { Observable } from 'rxjs';
@Component({
  selector: 'app-map-data',
  templateUrl: './map-data.component.html',
  styleUrls: ['./map-data.component.css']
})
export class MapDataComponent implements OnInit {
  searchText: any;
  info: any = {};
  daat: any[] = [];
  daatyyy: any[] = [];
  searchText1: any;
  form: FormGroup;
  registrationForm: FormGroup;
  heroForm: FormGroup;
  constructor(public dialogRef: MatDialogRef<MapDataComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, public connectorService: ConnectorsService, private formBuilder: FormBuilder) {

    this.registrationForm = this.formBuilder.group({
      toppings: ['', [Validators.required]], toppings2: ['', [Validators.required]]
    })
  }
  first: any = [];
  second: any = []; ooooo: any = [];
  ngOnInit() {
    this.form = this.formBuilder.group({
      credentials: this.formBuilder.array([]),
    });
  }
  save() {
    this.dialogRef.close("data is saved");
  }
  columnStandard: Array<any> = [
    { fieldName: "amazonrule", scriptId: "amazonrule" },
    { fieldName: "shopifyrule", scriptId: "shopifyrule" },
    { fieldName: "netscorerule", scriptId: "netscorerule" },
    { fieldName: "ebayrule", scriptId: "ebayrule" },
    { fieldName: "magentorule", scriptId: "magentorule" }];
  customFields: Array<any> = [
    { fieldName: "Send Item to Amazon", scriptId: "amazon" }
    , { fieldName: "item to shopify", scriptId: "shopify" },
    { fieldName: "item to netscore", scriptId: "netscore" },
    { fieldName: "item to ebay", scriptId: "ebay" },
    { fieldName: "item to magento", scriptId: "magento" }];

  sandeep(data: any) {
    if ((this.registrationForm.value.toppings != '') && (this.registrationForm.value.toppings2 != '') && (this.registrationForm.value.toppings != null) && (this.registrationForm.value.toppings2 != null)) {
      if (this.form.value.credentials.length > 0) {
        this.form.value.credentials.reset()
      } else {
        this.addCredsrrr();
      }
    }
  }
  createForm() {
    this.heroForm = this.formBuilder.group({
      skills: this.formBuilder.array([])
    });
  }

  addCreds() {
    const creds = this.heroForm.controls.skills as FormArray;
    creds.push(this.formBuilder.group({
      sectionTitle: '',
      sectionTitle2: '',
    }));
  }
  get mails() {
    return (<FormArray>this.heroForm.controls['skills']);
  }
  initSection() {
    return new FormGroup({
      sectionTitle: new FormControl(''),
      sectionTitle2: new FormControl('')
    });
  }
  appana(k) {
    if (k < this.form.value.credentials.length) {
      if (k == 0 && this.form.value.credentials.length > 1) { } else {
        if (k == (this.form.value.credentials.length - 1)) { this.hhh(k) }

      }


    }
    else {
      this.hhh(k)
    }

    // if (k < this.form.value.credentials.length) {

    //   if (this.form.value.credentials.length == 1 && k == 0) {

    //     if ((this.form.value.credentials[k].username != '') && (this.form.value.credentials[k].password != '') && (this.form.value.credentials[k].username != null) && (this.form.value.credentials[k].password != null)) {
    //       this.addCredsrrr()
    //     }



    //   } else { }
    // }
    // else {


    //   if ((this.form.value.credentials[k].username != '') && (this.form.value.credentials[k].password != '') && (this.form.value.credentials[k].username != null) && (this.form.value.credentials[k].password != null)) {
    //     this.addCredsrrr()
    //   }
    // }



  }
  onsubmit2() {
    this.registrationForm.value.credential = this.form.value.credentials;
  }
  hhh(k) {
    if ((this.form.value.credentials[k].username != '') && (this.form.value.credentials[k].password != '') && (this.form.value.credentials[k].username != null) && (this.form.value.credentials[k].password != null)) {

      this.addCredsrrr()
    }
    else {



    }

  }
  onSubmit() {
  }
  addCredsrrr() {
    const creds = this.form.controls.credentials as FormArray;
    creds.push(this.formBuilder.group({
      username: '',
      password: '',
    }));
  }
}