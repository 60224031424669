import { Component, OnInit } from '@angular/core';
import { NgxPaginationModule } from 'ngx-pagination';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.css']
})
export class FaqComponent implements OnInit {
  collection = [];
  constructor() {
    for (let i = 1; i <= 100; i++) {
      let Obj = { 'name': `Employee Name ${i}`, 'code': `EMP00 ${i}` }
      this.collection.push(Obj);
    }
  }
  _FaqData: any = [];
  ngOnInit() {
    this._FaqData = [
      {
        "Question": "Question Place Here",
        "Answer": "Answer Place Here"
      },
      {
        "Question": "Question Place Here",
        "Answer": "Answer Place Here"
      },
      {
        "Question": "Question Place Here",
        "Answer": "Answer Place Here"
      },
      {
        "Question": "Question Place Here",
        "Answer": "Answer Place Here"
      },
      {
        "Question": "Question Place Here",
        "Answer": "Answer Place Here"
      },
      {
        "Question": "Question Place Here",
        "Answer": "Answer Place Here"
      },
      {
        "Question": "Question Place Here",
        "Answer": "Answer Place Here"
      },
      {
        "Question": "Question Place Here",
        "Answer": "Answer Place Here"
      },
      {
        "Question": "Question Place Here",
        "Answer": "Answer Place Here"
      },
      {
        "Question": "Question Place Here",
        "Answer": "Answer Place Here"
      },
      {
        "Question": "Question Place Here",
        "Answer": "Answer Place Here"
      }
    ];
  }

}
