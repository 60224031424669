import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-net-score-layout',
  templateUrl: './net-score-layout.component.html',
  styleUrls: ['./net-score-layout.component.css']
})
export class NetScoreLayoutComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
