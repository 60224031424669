import { Component, OnInit, EventEmitter, Output, Inject, ViewChild } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MapDataComponent } from '../map-data/map-data.component';
import { HttpClient } from '@angular/common/http';
import { ConnectorsService } from '../servicess/connectors.service';
import { NgForm } from '@angular/forms';
//import { FormArray, FormBuilder, FormGroup, Validators,ValidatorFn,AbstractControl, FormControl } from '@angular/forms';
import { FormBuilder, FormGroup, Validators, FormArray, FormControl } from "@angular/forms";
@Component({
  selector: 'app-connectors',
  templateUrl: './connectors.component.html',
  styleUrls: ['./connectors.component.css']
})
export class ConnectorsComponent implements OnInit {

  constructor(private router: ActivatedRoute, public dialog: MatDialog, private route: Router, private http: HttpClient, private connectSer: ConnectorsService, private fb: FormBuilder) { }
  @ViewChild('f') indexForm: NgForm;
  _credentialsList: Array<any> = [];
  _faqQuestionsList: Array<any> = [];
  _connectorTypes: Array<any> = [];
  _connectorsList: Array<any> = [];
  _arr_ErpRecords: Array<any> = [];
  _arr_NetScoreRecords: Array<any> = [];
  _message: any = "";
  ERPType: any = "";
  ConnectorType: any = "";
  ConnectorName: any = "";
  DashBoardName: any = "";
  ImagePath: any = "";
  IntegratorLogo: any = "";
  ApiName: any = "";
  _selectedConnector: any = 0;
  _selectedERP: any = 0;
  _selectedProduct: any = 0;
  _sampleFieldMapping: any = [[
    {
      "Title": "body_html",
      "Label": "Description",
      "DataType": "String",
      "RecordType": "Item",
      "IsAccessible": "public",
      "IsRequired": 0
    },
    {
      "Title": "created_at",
      "Label": "Created At",
      "DataType": "String",
      "RecordType": "Item",
      "IsAccessible": "private",
      "IsRequired": 0
    }
  ], [
    {
      "ProductImport": "Import Products from Shopify to NetSuite",
      "ProductMatrixImport": "Import Varient Products from Shopify to NetSuite",
      "ProductKitImport": "Import Kit Products from Shopify to NetSuite",
      "ProductItemGroupImport": "Import Itemgroup Products from Shopify to NetSuite",
      "ProductExport": "Export Items from NetSuite to Shopify",
      "ProductMatrixExport": "Export Matrix Items from NetSuite to Shopify",
      "ProductKitExport": "Export Kit Item from NetSuite to Shopify",
      "ProductItemGroupExport": "Export Itemgroup Products from NetSuite to Shopify",
      "PriceImport": "Import Price from Shopify to NetSuite",
      "PriceExport": "Export Price from NetSuite to Shopify",
      "InventoryImport": "Import Inventory from Shopify to NetSuite",
      "InventoryExport": "Export Inventory from NetSuite to Shopify",
      "ImagesImport": "Import Images from Shopify to NetSuite",
      "ImagesExport": "Export Images from NetSuite to Shopify",
      "OrdersImport": "Import Orders from Shopify to NetSuite",
      "OrdersExport": "Export Orders from NetSuite to Shopify",
      "ShipmentsImport": "Import Shipments from Shopify to NetSuite",
      "ShipmentsExport": "Export Shipments from NetSuite to Shopify",
      "CancellationsImport": "Import Cancellations from Shopify to NetSuite",
      "CancellationsExport": "Export Cancellations from NetSuite to Shopify",
      "ReturnsImport": "Import Returns from Shopify to NetSuite",
      "ReturnsExport": "Export Returns from NetSuite to Shopify",
      "RefundsImport": "Import Refunds from Shopify to NetSuite",
      "RefundsExport": "Export Refunds from NetSuite to Shopify",
      "InvoicesImport": "Import Invoices from Shopify to NetSuite",
      "InvoicesExport": "Export Invoices from NetSuite to Shopify"
    }
  ], {
    "logopath": "assets/images/ConnectorLogo/Shopify.png",
    "Description": "NetScore – Amazon connector supports separate items of a catalog and then attributes for Amazon marketplace and selling on Amazon. This allows you to easily map all attributes in Amazon Marketplace and catalog fields of NetSuite.",
    "Points": [
      {
        "point": "Automatically Sync Products"
      },
      {
        "point": "Effcient Catalog Management"
      },
      {
        "point": "Automatic Inventory & Price Sync"
      },
      {
        "point": "Real-time Order & Customer Sync"
      },
      {
        "point": "Accurate Shipment Tracking"
      },
      {
        "point": "Connect Multiple Stores"
      },
      {
        "point": "Accurate Accounting"
      }
    ]
  }, {
    "Heading": "Connect Shopify Seller Central to NetSuite with Ease",
    "SubHeading": "Integrate Shopify with NetSuite with Ease",
    "ImageUrl": "/assets/images/FlowImages/shopify_flow.png",
    "Content": [
      {
        "Heading": "Items Export from NetSuite to Shopify",
        "Content": "Item exporting from NetSuite makes the process easier for user to create or update the Product catalogue in Shopify."
      },
      {
        "Heading": "Updates Price and Inventory to Shopify",
        "Content": "Item prices and Inventory in Shopify are consistently and continuously update from NetSuite which ensures that your sales are not affected and can be fulfilled on time."
      },
      {
        "Heading": "Update Images to Shopify",
        "Content": "Multiple images will update to Shopify products from NetSuite."
      }
    ]
  }]
  ngOnInit() {
    this.GetConnectorTypes();
    this.GetConnectorsList();
    this.GetERPRecords();
    this.GetNetScoreProductRecords();
    this._credentialsList.push({ Header: "", Displayname: "" });
    this._faqQuestionsList.push({ Header: "", Displayname: "" });
  }
  GetConnectorTypes() {
    this.connectSer.GetConnectorTypes().subscribe((data: any) => {
      if (data.status != "Error") {
        this._connectorTypes = data.results;
      }
    })
  }
  GetConnectorsList() {
    this.connectSer.GetConnectorsList().subscribe((data: any) => {
      if (data.status != "Error") {
        this._connectorsList = data.results;
      }
    })
  }
  GetERPRecords() {
    this.connectSer.GetERPRecords().subscribe((data: any) => {
      if (data.status != "Error") {
        this._arr_ErpRecords = data.results;
      }
    })
  }
  GetNetScoreProductRecords() {
    this.connectSer.GetNetScoreProductRecords().subscribe((data: any) => {
      if (data.status != "Error") {
        this._arr_NetScoreRecords = data.results;
      }
    })
  }
  ResetForm() {
    this.indexForm.resetForm();
    this._selectedConnector = 0;
    this._credentialsList = [];
    this._faqQuestionsList = [];
    this._credentialsList.push({ Header: "", Displayname: "" });
    this._faqQuestionsList.push({ Header: "", Displayname: "" });
    (<HTMLInputElement>document.getElementById("txtFieldMapping")).value = '';
    (<HTMLInputElement>document.getElementById("txtFlowName")).value = '';
    (<HTMLInputElement>document.getElementById("txtDescription")).value = '';
    (<HTMLInputElement>document.getElementById("txtOverView")).value = '';
  }
  _onChangeCredentailsList() {
    var index_id = (this._credentialsList.length) - 1;
    var SiD = "_chead" + index_id;
    var DiD = "_cdisplay" + index_id;
    var CHead = (<HTMLInputElement>document.getElementById(SiD)).value;
    var CDisplay = (<HTMLInputElement>document.getElementById(DiD)).value;
    if (CHead != "" && CDisplay != "") {
      this._credentialsList.push({ Header: "", Displayname: "" });
    }
  }
  _onChangeFaq() {
    var index_id = (this._faqQuestionsList.length) - 1;
    var SiD = "_faqQuestion" + index_id;
    var DiD = "_faqAnswer" + index_id;
    var CHead = (<HTMLInputElement>document.getElementById(SiD)).value;
    var CDisplay = (<HTMLInputElement>document.getElementById(DiD)).value;
    if (CHead != "" && CDisplay != "") {
      this._faqQuestionsList.push({ Header: "", Displayname: "" });
    }
  }
  RemoveDivById(divString: any, i) {
    if (divString.length - 1 == i) {

    }
    else {
      var ans = confirm('Are you sure you want to remove this field?');
      if (ans) {
        divString.splice(i, 1);
      }
    }
  }
  onSubmit() {
    var formJson = {};
    var FAQ = []; var CredentialsHeaders = ""; var CredentialsDisplayNames = "";
    for (var i = 0; i < this._faqQuestionsList.length; i++) {
      var SiD = "_faqQuestion" + i;
      var DiD = "_faqAnswer" + i;
      if ((<HTMLInputElement>document.getElementById(SiD)).value != "" && (<HTMLInputElement>document.getElementById(DiD)).value != "") {
        FAQ.push({ Question: (<HTMLInputElement>document.getElementById(SiD)).value, Answer: (<HTMLInputElement>document.getElementById(DiD)).value })
      }
    }
    for (var i = 0; i < this._credentialsList.length; i++) {
      var SiD = "_chead" + i;
      var DiD = "_cdisplay" + i;
      if ((<HTMLInputElement>document.getElementById(SiD)).value != "" && (<HTMLInputElement>document.getElementById(DiD)).value != "") {
        if (CredentialsHeaders == "")
          CredentialsHeaders = (<HTMLInputElement>document.getElementById(SiD)).value;
        else
          CredentialsHeaders = CredentialsHeaders + "," + (<HTMLInputElement>document.getElementById(SiD)).value;

        if (CredentialsDisplayNames == "")
          CredentialsDisplayNames = (<HTMLInputElement>document.getElementById(DiD)).value;
        else
          CredentialsDisplayNames = CredentialsDisplayNames + "," + (<HTMLInputElement>document.getElementById(DiD)).value;
      }
    }
    formJson = {
      "Pk_ConnectorID": this._selectedConnector,
      "ErpType": this.indexForm.value.ddlERPType,
      "ConnectorType": this.indexForm.value.ddlConnectorType,
      "ConnectorName": this.indexForm.value.txtConnectorName,
      "DashboardName": this.indexForm.value.txtDashBoardName,
      "LogoPath": this.indexForm.value.txtConnectorLogoPath,
      "IntegratorPath": this.indexForm.value.txtintegratorpath,
      "APIName": this.indexForm.value.txtApiname,
      "ProductType": this.indexForm.value.ddlProductType,
      "FieldMapping": (<HTMLInputElement>document.getElementById("txtFieldMapping")).value,
      "FlowNames": (<HTMLInputElement>document.getElementById("txtFlowName")).value,
      "Description": (<HTMLInputElement>document.getElementById("txtDescription")).value,
      "Overview": (<HTMLInputElement>document.getElementById("txtOverView")).value,
      "FAQ": JSON.stringify(FAQ),
      "CredentialsHeaders": CredentialsHeaders,
      "CredentialsDisplayNames": CredentialsDisplayNames
    };
    this.connectSer.PostConnector(formJson).subscribe((data: any) => {
      if (data.status != "Error") {
        this.ResetForm();
        this._message = "Data submitted successfully";
      }
      else {
        this._message = data.errorMessage;
      }
      setTimeout(() => {
        this._message = "";
      }, 3000)
    })
  }

  editIntegrator(id) {
    this.connectSer.GetConnectorByID(id).subscribe((data: any) => {
      this.ResetForm();
      if (data.status != "Error") {
        console.clear();
        console.log(data.results[0]);
        this._selectedConnector = data.results[0].pk_ConnectorID;
        this.ConnectorType = data.results[0].connectorType;
        this.ConnectorName = data.results[0].connectorName;
        this.DashBoardName = data.results[0].dashboardName;
        this.ImagePath = data.results[0].logoPath;
        this.IntegratorLogo = data.results[0].integratorPath;
        this._selectedERP = data.results[0].erpType;
        this._selectedProduct = data.results[0].productType;
        this.ApiName = data.results[0].apiName;
        (<HTMLInputElement>document.getElementById("txtFieldMapping")).value = data.results[0].fieldMapping;
        (<HTMLInputElement>document.getElementById("txtFlowName")).value = data.results[0].flowNames;
        (<HTMLInputElement>document.getElementById("txtDescription")).value = data.results[0].description;
        (<HTMLInputElement>document.getElementById("txtOverView")).value = data.results[0].overview;
        this._credentialsList = [];
        this._faqQuestionsList = [];
        var faqJson = JSON.parse(data.results[0].faq);
        for (var i = 0; i < faqJson.length; i++) {
          this._faqQuestionsList.push({ Header: faqJson[i].Question, Displayname: faqJson[i].Answer });
        }
        var CredentialsHeaders = (data.results[0].credentialsHeaders).split(',');
        var CredentialsNames = (data.results[0].credentialsDisplayNames).split(',');
        for (var k = 0; k < CredentialsHeaders.length; k++) {
          this._credentialsList.push({ Header: CredentialsHeaders[k], Displayname: CredentialsNames[k] });
        }
        this._credentialsList.push({ Header: "", Displayname: "" });
        this._faqQuestionsList.push({ Header: "", Displayname: "" });
      }
    })
  }
  checkInvalid() {
    let invalidFields = [].slice.call(document.getElementsByClassName('ng-invalid'));
    invalidFields[1].focus();
  }
  copyFieldMappingJson(indexValue) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = JSON.stringify(this._sampleFieldMapping[indexValue]);
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }
}