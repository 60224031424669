import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MapDataComponent } from '../map-data/map-data.component';
import { HttpClient } from '@angular/common/http';
import { ConnectorsService } from '../servicess/connectors.service';
import { GlobalTypeScriptService } from '../servicess/global-type-script.service';
import { AuthservService } from '../servicess/authserv.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-setpassword',
  templateUrl: './setpassword.component.html',
  styleUrls: ['./setpassword.component.css']
})
export class SetpasswordComponent implements OnInit {
  _Username: any;
  _Token: any;
  _LoginId: any;
  Message: any;
  constructor(private authserve: AuthservService, private router: ActivatedRoute, public dialog: MatDialog, private route: Router, private http: HttpClient, private connectService: ConnectorsService, private activatedRoute: ActivatedRoute) {
    this.activatedRoute.queryParams.subscribe(params => {
      this._Token = params['id'];
    });
  }
  @ViewChild('f') indexForm: NgForm;
  ngOnInit() {
    this.CheckSubUserToken();
  }
  CheckSubUserToken() {
    this.connectService.CheckSubUserToken(this._Token).subscribe((data: any) => {
      if (data.status != "Error") {
        this._Username = data.results.userName;
        this._LoginId = data.results.pK_LoginID;
      }
      else {
        this.Message = data.errorMessage;
        setTimeout(() => {
          this.route.navigate(['/home'])
        }, 3000)
      }
    })
  }
  onSubmit() {
    var UserData = {
      LoginId: this._LoginId,
      Password: this.indexForm.value.txtPassword
    }
    this.connectService.UpdatePassword(UserData).subscribe((data: any) => {
      if (data.status != "Error") {
        this.Message = "Password Updated Success.";
        setTimeout(() => {
          this.route.navigate(['/home'])
        }, 3000)
      }
      else {
        this.Message = data.errorMessage;
        setTimeout(() => {
          this.Message = "";
        }, 3000)
      }
    })
  }
}
