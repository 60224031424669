import { Component, HostListener, OnInit } from '@angular/core';
import { ConnectorsService } from './servicess/connectors.service'
import { Router, Event, NavigationStart, NavigationEnd, NavigationError } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'ipass';
  constructor(private connectorservice: ConnectorsService, private router: Router) {
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        const parameters = window.location.href;
        var pieces = parameters.split(/[\s/]+/);
        var connectorid = (pieces[pieces.length - 1]);
        var Customerid = localStorage.getItem('customerID');
        this.connectorservice.validateUserConnection(Customerid, connectorid).subscribe((data: any) => {
          if (data.status == 'Error') {
            this.router.navigate(['/dashBoard'])
          }
        })
      }
    })
  }
  @HostListener('document:keyup', ['$event'])
  @HostListener('document:click', ['$event'])
  @HostListener('document:wheel', ['$event'])
  resetTimer() {
    this.connectorservice.notifyUserAction();
  }
}
