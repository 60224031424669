import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MapDataComponent } from '../map-data/map-data.component';
import { HttpClient } from '@angular/common/http';
import { ConnectorsService } from '../servicess/connectors.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';

@Component({
  selector: 'app-shipment',
  templateUrl: './shipment.component.html',
  styleUrls: ['./shipment.component.css']
})
export class ShipmentComponent implements OnInit {
  @BlockUI() blockUI: NgBlockUI;
  constructor(private router: ActivatedRoute, public dialog: MatDialog, private route: Router, private http: HttpClient, private connectService: ConnectorsService) { }
  _shipmentImportSubscribed: any = false;
  _shipmentExportSubscribed: any = false;
  _shipmentImportEnabled: any = false;
  _shipmentExportEnabled: any = false;
  _orderStatusEnabled: any = false;

  _StandFieldMappingSrcJSON: Array<any> = [];
  _StandFieldMappingDstJSON: Array<any> = [];
  _MarketPlaceFieldMapping: Array<any> = [];
  _EXMarketPlaceFieldMapping: Array<any> = [];
  _shipmentImportRunning: any = false;
  _shipmentExportRunning: any = false;
  _OrderStatusExportRunning: any = false;
  _importLockFieldMappingResult: Array<any> = [];
  _importStdFieldMappingResult: Array<any> = [];
  _exportLockFieldMappingResult: Array<any> = [];
  _exportStdFieldMappingResult: Array<any> = [];
  _updatedImportFieldMapping: Array<any> = [];
  _updatedExportFieldMapping: Array<any> = [];
  _connectorData: any;
  _flowNames: Array<any> = [];
  _importFlowName: any;
  _exportFlowName: any;
  _SchedulerFrequencyList: any = [];
  _SchedulerStartTimeList: any;
  _SchedulerDaysList: any;
  _marketPlaceFields: any;

  _importScheduleTime: any = [{ "Date": "", "Frequency": "", "Time": "", "Days": "" }];
  _exportScheduleTime: any = [{ "Date": "", "Frequency": "", "Time": "", "Days": "" }];
  _orderStatusScheduleTime: any = [{ "Date": "", "Frequency": "", "Time": "", "Days": "" }];
  _importScheduleTimeDays: any = [];
  _exportScheduleTimeDays: any = [];
  _OrderStatusScheduleTimeDays: any = [];

  _shipmentexportRunning: any = false;
  _importProcessingName: any = false; _exportProcessingName: any = false; _orderStatusProcessingName: any = false;
  _serviceAPI: any = {
    shipmentimport: "ShipmentImport",
    shipmentexport: "ShipmentExport",
    OrderStatus: "OrderStatusUpdate"
  };
  _arr_ShippingMethodsMapping: Array<any> = [];
  _ShippingMethodsMapping: Array<any> = [];

  _isShopify: any;
  _isWooCommerce: any;
  _connectorID: any = "";
  _ddl_pluginType: any = "";
  _isShipstation = false;
  _orderupdateprocessname = "Order Status Update";

  ngOnInit() {
    const parameters = window.location.href;
    var pieces = parameters.split(/[\s/]+/);
    this._connectorID = (pieces[pieces.length - 1]);
    localStorage.setItem('ActiveLiName', 'Shipments');
    // this.blockUI.start('Loading...');
    this.connectorsList()
    this.GetMarketPlaceFields();
    this.GetSchedulerData();
    this.GetAdditionalInfo();
    switch ((localStorage.getItem('SelectedconnectorName')).toLowerCase()) {
      case 'shopify':
        {
          this._isShopify = true;
          break;
        }
      case 'woocommerce': {
        this._isWooCommerce = true;
        break;
      }
      case 'shipstation': {
        this._isShipstation = true;
        this._orderupdateprocessname = "Order Update";
        break;
      }
      default: {
        break;
      }
    }
  }

  GetAdditionalInfo() {
    this.connectService.GetOrderAdditionalInfo().subscribe((data: any) => {
      this._arr_ShippingMethodsMapping = data.results.shippingMethods;
    })
  }

  GetSchedulerData() {
    var Result = this.connectService.GetSchedulerListItems();
    this._SchedulerFrequencyList = (Result[0].Frequency);
    this._SchedulerStartTimeList = (Result[0].Time);
    this._SchedulerDaysList = (Result[0].DayList);
  }
  GetMarketPlaceFields() {
    this.connectService.GetItemFieldMapping('Shipments', this._connectorID).subscribe((data: any) => {
      this.blockUI.stop();
      if (data.status != "Error") {
        this._marketPlaceFields = data.results.columnStandardFields;
      }
    })
  }
  connectorsList() {
    this.EmptyConnectorVariables();
    this.connectService.ListConnector(this._connectorID).subscribe((data: any) => {
      this._flowNames = JSON.parse(data.results[0].flowNames);
      this._importFlowName = (this._flowNames[0]).ShipmentsImport;
      this._exportFlowName = (this._flowNames[0]).ShipmentsExport;
      this._serviceAPI = {
        shipmentimport: (data.results[0].api_Shipments_Import != "" && data.results[0].api_Shipments_Import != null) ? data.results[0].api_Shipments_Import : "ShipmentImport",
        shipmentexport: (data.results[0].api_Shipments_Export != "" && data.results[0].api_Shipments_Export != null) ? data.results[0].api_Shipments_Export : "ShipmentExport",
        OrderStatus: "OrderStatusUpdate"
      };
      var MappingFields = JSON.parse(data.results[0].connectorFields);
      for (var k = 0; k < MappingFields.length; k++) {
        if ((MappingFields[k].RecordType.toUpperCase()) == 'SHIPMENTS') {
          this._MarketPlaceFieldMapping.push({ label: MappingFields[k].Label, value: MappingFields[k].Title })
        }
        if ((MappingFields[k].RecordType.toUpperCase()) == 'SHIPMENTS' && (MappingFields[k].IsAccessible.toUpperCase()) == "PUBLIC") {
          this._EXMarketPlaceFieldMapping.push({ label: MappingFields[k].Label, value: MappingFields[k].Title })
        }
      }
      // this._shipmentImportRunning = (data.results[0].shipment_ImportRunning)
      // this._shipmentExportRunning = (data.results[0].shipment_ExportRunning)
      this._connectorData = data;
      if (data.results[0].s_Shipments_Import == true || data.results[0].add_Shipments_Import == true)
        this._shipmentImportSubscribed = true;
      if (data.results[0].s_Shipments_Export == true || data.results[0].add_Shipments_Export == true)
        this._shipmentExportSubscribed = true;
      if (data.results[0].shipments_Import == true)
        this._shipmentImportEnabled = true;
      if (data.results[0].shipments_Export == true)
        this._shipmentExportEnabled = true;
      if (data.results[0].orderStatus == true)
        this._orderStatusEnabled = true;
      if (this._connectorData.results[0].shipments_ImportFieldsMapping != null && this._connectorData.results[0].shipments_ImportFieldsMapping != "") {
        var FieldsMapping = JSON.parse(this._connectorData.results[0].shipments_ImportFieldsMapping)
        var ItemImportJsonMapping = FieldsMapping;
        for (var i = 0; i < ItemImportJsonMapping.length; i++) {
          if (ItemImportJsonMapping[i].fieldType == 'Lock')
            this._importLockFieldMappingResult.push({ MarketPlace: ItemImportJsonMapping[i].MarketPlace, NetSuite: ItemImportJsonMapping[i].NetSuite, Default: ItemImportJsonMapping[i].Default });
          if (ItemImportJsonMapping[i].fieldType == 'Standard')
            this._importStdFieldMappingResult.push({ label: ItemImportJsonMapping[i].label, MarketPlace: ItemImportJsonMapping[i].MarketPlace, NetSuite: ItemImportJsonMapping[i].NetSuite, Default: ItemImportJsonMapping[i].Default });
        }
      }
      if (this._connectorData.results[0].shipments_ExportFieldsMapping != null && this._connectorData.results[0].shipments_ExportFieldsMapping != "") {
        var ExFieldsMapping = JSON.parse(this._connectorData.results[0].shipments_ExportFieldsMapping);
        var ItemExportJsonMapping = ExFieldsMapping;
        for (var i = 0; i < ItemExportJsonMapping.length; i++) {
          if (ItemExportJsonMapping[i].fieldType == 'Lock')
            this._exportLockFieldMappingResult.push({ NetSuite: ItemExportJsonMapping[i].NetSuite, MarketPlace: ItemExportJsonMapping[i].MarketPlace, Default: ItemExportJsonMapping[i].Default });
          if (ItemExportJsonMapping[i].fieldType == 'Standard')
            this._exportStdFieldMappingResult.push({ label: ItemExportJsonMapping[i].label, NetSuite: ItemExportJsonMapping[i].NetSuite, MarketPlace: ItemExportJsonMapping[i].MarketPlace, Default: ItemExportJsonMapping[i].Default });
        }
      }
      this._importStdFieldMappingResult.push({ MarketPlace: '', NetSuite: '', Default: '' });
      this._exportStdFieldMappingResult.push({ NetSuite: '', MarketPlace: '', Default: '' });

      if (this._connectorData.results[0].shipments_ExportScheduletime != null) {
        this._exportScheduleTime = (JSON.parse(this._connectorData.results[0].shipments_ExportScheduletime));
        this._exportScheduleTimeDays = this._exportScheduleTime[0].Days.split(',');
      }
      if (this._connectorData.results[0].shipments_ImportScheduletime != null) {
        this._importScheduleTime = (JSON.parse(this._connectorData.results[0].shipments_ImportScheduletime));
        this._importScheduleTimeDays = this._importScheduleTime[0].Days.split(',');
      }
      if (this._connectorData.results[0].orderStatus_ScheduleTime != null) {
        this._orderStatusScheduleTime = (JSON.parse(this._connectorData.results[0].orderStatus_ScheduleTime));
        this._OrderStatusScheduleTimeDays = this._orderStatusScheduleTime[0].Days.split(',');
      }

      if (this._isWooCommerce == true) {
        if (this._connectorData.results[0].orderStatus_FieldMapping != null) {
          var parsedData = JSON.parse(this._connectorData.results[0].orderStatus_FieldMapping);
          try {
            if (parsedData.shippingmethods)
              this._ShippingMethodsMapping = parsedData.shippingmethods;
            if (parsedData.PluginType)
              this._ddl_pluginType = parsedData.PluginType;
          }
          catch (ex) {

          }
        }
      }
      this._ShippingMethodsMapping.push({ NetSuite: "", MarketPlace: "", Carrier: "" });
    })
  }
  UpdateFlowStatus(FlowUpdateVariable) {
    this.connectService.UpdateFlowStatusByConnector(FlowUpdateVariable, this._connectorID).subscribe((data: any) => {

    })
  }
  _OnChangeStandardImportFieldMapping() {
    var index_id = (this._importStdFieldMappingResult.length) - 1;
    var SiD = "StdSrc_" + index_id;
    var DiD = "StdDst_" + index_id;
    var DfD = "DFTStd_" + index_id;
    var S_e = (document.getElementById("" + SiD)) as HTMLSelectElement;
    var S_sel = S_e.selectedIndex;
    var S_opt = S_e.options[S_sel];
    var ExpValue = (<HTMLInputElement>document.getElementById(DiD)).value;
    var DestValue = (<HTMLInputElement>document.getElementById(DfD)).value;
    if (ExpValue != "" && (((<HTMLOptionElement>S_opt).value) != "" || DestValue != "")) {
      this._importStdFieldMappingResult.push({ MarketPlace: '', NetSuite: '', Default: '' });
    }
  }

  _OnChangeStandardExportFieldMapping() {
    var index_id = (this._exportStdFieldMappingResult.length) - 1;
    var SiD = "ExStdSrc_" + index_id;
    var DiD = "ExStdDst_" + index_id;
    var DfD = "DFTStdEx_" + index_id;
    var D_e = (document.getElementById("" + DiD)) as HTMLSelectElement;
    var D_sel = D_e.selectedIndex;
    var D_opt = D_e.options[D_sel];
    var SourceValue = (<HTMLInputElement>document.getElementById(SiD)).value;
    var DestValue = ((<HTMLOptionElement>D_opt).value)
    var DefaultValue = (<HTMLInputElement>document.getElementById(DfD)).value;
    if (DestValue != "" && (SourceValue != "" || DefaultValue != "")) {
      this._exportStdFieldMappingResult.push({ NetSuite: '', MarketPlace: '', Default: '' });
    }
  }

  PostImportFieldMapping() {
    this._updatedExportFieldMapping.length = 0;
    for (var i = 0; i < this._importStdFieldMappingResult.length; i++) {
      var SiD = "StdDst_" + i;
      var DiD = "StdSrc_" + i;
      var DfD = "DFTStd_" + i;
      var D_e = (document.getElementById("" + DiD)) as HTMLSelectElement;
      var D_sel = D_e.selectedIndex;
      var D_opt = D_e.options[D_sel];
      var SourceValue = this.GetValueFromArray(this._marketPlaceFields, ((<HTMLInputElement>document.getElementById(SiD)).value));
      var DataType = this.GetFieldTypeFromArray(this._marketPlaceFields, ((<HTMLInputElement>document.getElementById(DiD)).value));
      var DestValue = ((<HTMLOptionElement>D_opt).value)
      var DefaultValue = (<HTMLInputElement>document.getElementById(DfD)).value;
      if (DestValue != "" && (SourceValue != "" || DefaultValue != "")) {
        if ((this.NetSuiteFieldExists(this._updatedExportFieldMapping, SourceValue)) == false)
          this._updatedExportFieldMapping.push({ label: (<HTMLInputElement>document.getElementById(SiD)).value, NetSuite: SourceValue, MarketPlace: DestValue, Default: DefaultValue, fieldType: 'Standard', DataType: DataType })
      }
    }
    for (var k = 0; k < this._importLockFieldMappingResult.length; k++) {
      var SiD = "SpnImpLockDes_" + k;
      var DiD = "SpnImpLockSrc_" + k;
      var DfD = "SpnImLockDef_" + k;
      var SIMLockValue = ((<HTMLInputElement>document.getElementById(SiD)).value);
      var DefIMLockValue = this.GetValueFromArray(this._marketPlaceFields, ((<HTMLInputElement>document.getElementById(DiD)).value));
      var DataType = this.GetFieldTypeFromArray(this._marketPlaceFields, ((<HTMLInputElement>document.getElementById(DiD)).value));
      var DesIMLockValue = (<HTMLInputElement>document.getElementById(DfD)).innerHTML
      this._updatedExportFieldMapping.push({ NetSuite: SIMLockValue, label: ((<HTMLInputElement>document.getElementById(DiD)).value), MarketPlace: DefIMLockValue, Default: DesIMLockValue, fieldType: 'Lock', DataType: DataType })
    }

    this.connectService.Srv_PostImportFieldMapping(this._updatedExportFieldMapping, "shipments_ImportFieldsMapping", this._connectorID).subscribe((data: any) => {
      this.connectorsList()
    })
  }

  PostExportFieldMapping() {
    this._updatedExportFieldMapping.length = 0;
    for (var i = 0; i < this._exportStdFieldMappingResult.length; i++) {
      var SiD = "ExStdSrc_" + i;
      var DiD = "ExStdDst_" + i;
      var DfD = "DFTStdEx_" + i;
      var D_e = (document.getElementById("" + DiD)) as HTMLSelectElement;
      var D_sel = D_e.selectedIndex;
      var D_opt = D_e.options[D_sel];
      var SourceValue = this.GetValueFromArray(this._marketPlaceFields, ((<HTMLInputElement>document.getElementById(SiD)).value));
      var DataType = this.GetFieldTypeFromArray(this._marketPlaceFields, ((<HTMLInputElement>document.getElementById(DiD)).value));
      var DestValue = ((<HTMLOptionElement>D_opt).value)
      var DefaultValue = (<HTMLInputElement>document.getElementById(DfD)).value;
      if (DestValue != "" && (SourceValue != "" || DefaultValue != "")) {
        if ((this.MarketPlaceFieldExists(this._updatedExportFieldMapping, DestValue)) == false)
          this._updatedExportFieldMapping.push({ label: (<HTMLInputElement>document.getElementById(SiD)).value, NetSuite: SourceValue, MarketPlace: DestValue, Default: DefaultValue, fieldType: 'Standard', DataType: DataType })
      }
    }
    for (var k = 0; k < this._exportLockFieldMappingResult.length; k++) {
      var SiD = "SpnExpLockSrc_" + k;
      var DiD = "SpnExpLockDes_" + k;
      var DfD = "SpnExpLockDef_" + k;
      var SIMLockValue = ((<HTMLInputElement>document.getElementById(SiD)).value);
      var DefIMLockValue = this.GetValueFromArray(this._marketPlaceFields, ((<HTMLInputElement>document.getElementById(DiD)).value));
      var DataType = this.GetFieldTypeFromArray(this._marketPlaceFields, ((<HTMLInputElement>document.getElementById(DiD)).value));
      var DesIMLockValue = (<HTMLInputElement>document.getElementById(DfD)).innerHTML
      this._updatedExportFieldMapping.push({ NetSuite: SIMLockValue, label: ((<HTMLInputElement>document.getElementById(DiD)).value), MarketPlace: DefIMLockValue, Default: DesIMLockValue, fieldType: 'Lock', DataType: DataType })
    }

    this.connectService.Srv_PostImportFieldMapping(this._updatedExportFieldMapping, "shipments_ExportFieldsMapping", this._connectorID).subscribe((data: any) => {
      this.connectorsList()
    })
  }
  _OnChangeShippingMethods() {
    var index_id = (this._ShippingMethodsMapping.length) - 1;
    var NSID = "_SMNS_" + index_id;
    var MPID = "_SMMP_" + index_id;
    var NS = this.GetSelectedValueFromId(NSID);
    var MP = (<HTMLInputElement>document.getElementById(MPID)).value;
    if (NS != "" && MP != "") {
      this._ShippingMethodsMapping.push({ NetSuite: "", MarketPlace: "" });
    }
  }
  PostOrderStatusFieldMapping() {
    this.blockUI.start('Data Updating...');
    var plugintype = (this._ddl_pluginType);
    var ShippingMethods = [];
    for (var i = 0; i < this._ShippingMethodsMapping.length; i++) {
      var NSID = "_SMNS_" + i;
      var MPID = "_SMMP_" + i;

      var NS = this.GetSelectedValueFromId(NSID);
      var MP = (<HTMLInputElement>document.getElementById(MPID)).value;
      if (NS != "" && MP != "") {
        ShippingMethods.push({ NetSuite: NS, MarketPlace: MP })
      }

    }
    var Orderstatusfieldmapping = { "PluginType": this._ddl_pluginType, "shippingmethods": ShippingMethods };
    this.connectService.Srv_PostImportFieldMapping(Orderstatusfieldmapping, "OrderStatus_FieldMapping", this._connectorID).subscribe((data: any) => {

    })
    setTimeout(() => {
      this.blockUI.stop();
    }, 1000);
  }
  RemoveDivById(divString: any, i) {
    if (divString.length - 1 == i) {

    }
    else {
      var ans = confirm('Are you sure you want to remove this field?');
      if (ans) {
        divString.splice(i, 1);
      }
    }
  }

  GetValueFromArray(JsonArray, Value) {
    if (Value) {
      var index = -1;
      var filteredObj = JsonArray.find(function (item, i) {
        if ((item.fieldName).toUpperCase() === Value.toUpperCase()) {
          index = i;
        }
      });
      if (index == -1)
        return Value;
      else
        return JsonArray[index].scriptId;
    }
    else {
      return "";
    }
  }
  GetFieldTypeFromArray(JsonArray, Value) {
    if (Value) {
      var index = -1;
      var filteredObj = JsonArray.find(function (item, i) {
        if ((item.fieldName).toUpperCase() === Value.toUpperCase()) {
          index = i;
        }
      });
      if (index == -1) {
        return Value;
      }
      else {
        return JsonArray[index].fieldType;
      }
    }
    else {
      return "";
    }
  }

  PostIESchedulerJSON() {
    var StandardItemImport = [];
    StandardItemImport.push({ "Date": (<HTMLInputElement>document.getElementById("_IEIS_ScheduleFrom")).value, "Frequency": this.GetSelectedValueFromId("_IEIS_Frequency"), "Time": this.GetSelectedValueFromId("_IEIS_Time"), "Days": this.GetSelectedMultipleValuesFromID("_IEIS_Days") })
    this.connectService.Srv_PostImportFieldMapping(StandardItemImport, "shipments_ExportScheduletime", this._connectorID).subscribe((data: any) => {

    })
  }
  PostIISchedulerJSON() {
    var StandardItemImport = [];
    StandardItemImport.push({ "Date": (<HTMLInputElement>document.getElementById("_IMIS_ScheduleFrom")).value, "Frequency": this.GetSelectedValueFromId("_IMIS_Frequency"), "Time": this.GetSelectedValueFromId("_IMIS_Time"), "Days": this.GetSelectedMultipleValuesFromID("_IMIS_Days") })
    this.connectService.Srv_PostImportFieldMapping(StandardItemImport, "shipments_ImportScheduletime", this._connectorID).subscribe((data: any) => {

    })
  }
  PostOrderStatusSchedulerJSON() {
    var StandardItemImport = [];
    StandardItemImport.push({ "Date": (<HTMLInputElement>document.getElementById("_OrdStat_ScheduleFrom")).value, "Frequency": this.GetSelectedValueFromId("_OrdStat_Frequency"), "Time": this.GetSelectedValueFromId("_OrdStat_Time"), "Days": this.GetSelectedMultipleValuesFromID("_OrdStat_Days") })
    this.connectService.Srv_PostImportFieldMapping(StandardItemImport, "OrderStatus_ScheduleTime", this._connectorID).subscribe((data: any) => {

    })
  }
  GetSelectedValueFromId(ID) {
    try {
      var D_e = (document.getElementById(ID)) as HTMLSelectElement;
      var D_sel = D_e.selectedIndex;
      var D_opt = D_e.options[D_sel];
      var DestValue = ((<HTMLOptionElement>D_opt).value)
      return DestValue;
    }
    catch (e) {
      return "";
    }
  }
  GetSelectedMultipleValuesFromID(ID) {
    let selectElement = (document.getElementById(ID)) as HTMLSelectElement;
    let selectedValues = Array.from(selectElement.selectedOptions).map(option => option.value);
    return ((selectedValues).toString());
  }
  CheckInArray(Arr, Value) {
    Arr = Arr.map(String);
    if (Arr.indexOf(Value.toString()) > -1) {
      return true;
    }
    return false;
  }

  NetSuiteFieldExists(Arr, value) {
    if (value != "") {
      return Arr.some(function (el) {
        var status = (el.NetSuite === value);
        return status;
      });
    }
    else {
      return false;
    }
  }
  MarketPlaceFieldExists(Arr, value) {
    if (value != "") {
      return Arr.some(function (el) {
        var status = (el.MarketPlace === value);
        return status;
      });
    }
    else {
      return false;
    }
  }
  EmptyConnectorVariables() {
    this._MarketPlaceFieldMapping.length = 0;
    this._EXMarketPlaceFieldMapping.length = 0;
    this._importLockFieldMappingResult.length = 0;
    this._importStdFieldMappingResult.length = 0;
    this._exportStdFieldMappingResult.length = 0;
  }

  RunIntegrator(ApiMethodName, RunningMethodName, ProcessingName) {
    this['' + RunningMethodName] = true;
    this['' + ProcessingName] = 'Initiated...';
    setTimeout(() => {
      setTimeout(() => {
        this['' + RunningMethodName] = false;
      }, 3000)
      this['' + ProcessingName] = 'Processing...';
    }, 3000)
    this.connectService.RunIntegrator(ApiMethodName, this._connectorID).subscribe((data: any) => {

    })
  }
}