import { Component, OnInit, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { AuthservService } from '../servicess/authserv.service';
import { map } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  public msg: any = {};
  public display: any;
  public data: any;
  tokenstorage: any;
  id: any;
  constructor(private route: Router, private router: ActivatedRoute, private http: HttpClient, private authserve: AuthservService) {
    this.router.queryParams.subscribe(params => {
      this.id = params['id'];
    });
  }

  ngOnInit() {
    this.getIt();
  }

  submitted = false;
  public res;
  token1: any = [];
  token: any;
  tokenData = new EventEmitter<any>();
  statusText: any;
  info: any;
  accessToken: any;
  RefreshToken: any;
  onSubmit(data: { UserName: any, Password: any }) {
    this.submitted = true;
    this.res = this.authserve.Login(data)
      .subscribe((responsee) => {
        var data: any = responsee;
        if (this.statusText == "Bad Request") {
          let msg: any = {};
          msg.Message = data.Message;
          this.msg = msg;
        }
        else {
          this.token1 = responsee;
          this.token = this.token1.LoginAccessToken;
          this.accessToken = responsee['access_token'];
          localStorage.setItem('accessToken', this.accessToken);
          this.RefreshToken = responsee['refresh_token']
          localStorage.setItem('refreshToken', this.RefreshToken);

          this.authserve.CheckLoggedUser(responsee['userName']).subscribe((data: any) => {
            localStorage.setItem('customerID', data.results.customerID);
            localStorage.setItem('loggedId', data.results.loggedId);
            localStorage.setItem('userType', data.results.userType);
            localStorage.setItem('imageUrl', data.results.imageUrl);
            localStorage.setItem('loggedName', data.results.fullName);
            localStorage.setItem('LoggedRole', data.results.roleID);
            localStorage.setItem('isNSPending', data.results.nsPending);

            if (data.status != "Error") {
              if (data.results.userType == "Admin") {
                this.route.navigate(['/AdminDashboard']);
              }
              else {
                this.route.navigate(['/dashBoard']);
              }
            }
          })
        }
      }, error => {
        this.data = "Invalid Login Credentials"
        this.display = this.data;
        setTimeout(() => {
          this.display = "";
        }, 3000)
      })
  }
  isloaded: any = [];


  getIt() {
    if (this.id) {
      this.authserve.VerifyRequest(this.id).subscribe((data: any) => {
        this.isloaded = data;
        this.route.navigate(['/login']);
      })
    }
  }
}