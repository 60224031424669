import { Component, OnInit, EventEmitter, Output, Inject } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MapDataComponent } from '../map-data/map-data.component';
import { HttpClient } from '@angular/common/http';
import { ConnectorsService } from '../servicess/connectors.service';
import { GlobalTypeScriptService } from '../servicess/global-type-script.service';
import { AuthservService } from '../servicess/authserv.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'app-product',
  styleUrls: ['./product.component.css'],
  templateUrl: './product.component.html',
})
export class ProductComponent implements OnInit {
  @BlockUI() blockUI: NgBlockUI;
  @ViewChild('closeItemSpecModal') closeItemSpecModal: ElementRef;
  constructor(private authserve: AuthservService, private router: ActivatedRoute, public dialog: MatDialog, private route: Router, private http: HttpClient, private connectService: ConnectorsService, private GTS: GlobalTypeScriptService) {

  }
  _isBasePrice: any = false;
  _connectorData: any;
  _itemImportSubscribed: any = false;
  _itemExportSubscribed: any = false;
  _itemImportEnabled: any = false;
  _itemExportEnabled: any = false;
  _priceImportEnabled: any = false;
  _priceExportEnabled: any = false;
  _categoriesImportEnabled: any = false;
  _attributeImportEnabled: any = false;
  _tagsImportEnabled: any = false;
  _termsImportEnabled: any = false;
  _DefaultFieldMappingSrcJSON: any;
  _DefaultFieldMappingDstJSON: any;
  _StandFieldMappingSrcJSON: any;
  _StandKitFieldMappingSrcJSON: any;
  _StandItemGroupFieldMappingSrcJSON: any;
  _StandFieldMappingDstJSON: any;

  _importLockFieldMappingResult: Array<any> = [];
  _importStdFieldMappingResult: Array<any> = [];
  _MatriximportLockFieldMappingResult: Array<any> = [];
  _MatriximportStdFieldMappingResult: Array<any> = [];
  _KitimportLockFieldMappingResult: Array<any> = [];
  _KitimportStdFieldMappingResult: Array<any> = [];
  _ItemGroupimportLockFieldMappingResult: Array<any> = [];
  _ItemGroupimportStdFieldMappingResult: Array<any> = [];
  _importKitLockFieldMappingResult: Array<any> = [];
  _importKitStdFieldMappingResult: Array<any> = [];
  _importItemGroupLockFieldMappingResult: Array<any> = [];
  _importItemGroupStdFieldMappingResult: Array<any> = [];
  _PriceimportLockFieldMappingResult: Array<any> = [];
  _PriceimportStdFieldMappingResult: Array<any> = [];
  _ExPriceimportLockFieldMappingResult: Array<any> = [];
  _ExPriceimportStdFieldMappingResult: Array<any> = [];

  _exportLockFieldMappingResult: Array<any> = [];
  _exportLockServiceFieldMappingResult: Array<any> = [];
  _exportFieldMappingResult: Array<any> = [];

  _exportMatrixLockFieldMappingResult: Array<any> = [];
  _exportMatrixStdFieldMappingResult: Array<any> = [];
  _exportKitLockFieldMappingResult: Array<any> = [];
  _exportKitStdFieldMappingResult: Array<any> = [];
  _exportItemGroupLockFieldMappingResult: Array<any> = [];
  _exportLItemGroupStdFieldMappingResult: Array<any> = [];
  _exportShopifyVariationsStdFieldMappingResult: Array<any> = [];


  _variationFieldMapping: Array<any> = [];

  _updatedImportFieldMapping: Array<any> = [];
  _updatedExportFieldMapping: Array<any> = [];
  _exportStdFieldMappingResult: Array<any> = [];
  _exportNonStdFieldMappingResult: Array<any> = [];
  _exportServiceStdFieldMappingResult: Array<any> = [];
  _itemImportRunning: any = false;
  _itemExportRunning: any = false;
  _nonInventoryRunning: any = false;
  _itemExportMatrixRunning: any = false;
  _itemExportKitRunning: any = false;
  _itemExportGroupRunning: any = false;
  _shopifyExportRunning: any = false;
  _serviceItemExportRunning: any = false;
  _priceImportRunning: any = false;
  _priceExportRunning: any = false;
  _categoriesExportRunning: any = false;
  _attributesExportRunning: any = false;
  _tagsExportRunning: any = false;
  _termsExportRunning: any = false;
  _MarketPlaceFieldMapping: Array<any> = [];
  _EXP_MarketPlaceFieldMapping: Array<any> = [];
  _flowNames: Array<any> = [];
  _importFlowName: any;
  _importMatrixFlowName: any;
  _importKitFlowName: any;
  _importItemGroupFlowName: any;
  _exportFlowName: any;
  _exportNonFlowName: any;
  _exportMatrixFlowName: any;
  _exportKitFlowName: any;
  _exportItemGroupFlowName: any;
  _exportServiceItemFlowName: any;
  _PriceImportFlowName: any;
  _PriceexportFlowName: any;
  _priceImportSubscribed: any;
  _priceExportSubscribed: any;

  _PriceLevelImport: any;
  _PriceLevelExport: any;
  _CurrencyImport: any;
  _CurrencyExport: any;
  _SchedulerFrequencyList: any;
  _SchedulerStartTimeList: any;
  _SchedulerDaysList: any;

  StandardItemImport: any = [{ "Date": "", "Frequency": '', "Time": '', "Days": '' }];
  StandardNonItemImport: any = [{ "Date": "", "Frequency": '', "Time": '', "Days": '' }];
  ServiceItemExport: any = [{ "Date": "", "Frequency": '', "Time": '', "Days": '' }];
  MatrixItemImport: any = [{ "Date": "", "Frequency": '', "Time": '', "Days": '' }];
  KitItemImport: any = [{ "Date": "", "Frequency": '', "Time": '', "Days": '' }];
  ItemGroupItemImport: any = [{ "Date": "", "Frequency": '', "Time": '', "Days": '' }];
  ShopifyVariationItemImport: any = [{ "Date": "", "Frequency": '', "Time": '', "Days": '' }];

  _ItemImportScheduleJson: any = { "StandardItemImport": this.StandardItemImport, "MatrixItemImport": this.MatrixItemImport, "KitItemImport": this.KitItemImport, "ItemGroupItemImport": this.ItemGroupItemImport };
  _ItemExportScheduleJson: any = { "StandardItemImport": this.StandardItemImport, "ServiceItemExport": this.ServiceItemExport, "MatrixItemImport": this.MatrixItemImport, "KitItemImport": this.KitItemImport, "ItemGroupItemImport": this.ItemGroupItemImport, "ShopifyVariationItemImport": this.ShopifyVariationItemImport, "NonInventoryExport": this.StandardNonItemImport };
  _PriceImportScheduleJson: any = [{ "Frequency": '', "Time": '', "Days": '' }];
  _PriceExportScheduleJson: any = [{ "Frequency": '', "Time": '', "Days": '' }];

  _categoriesScheduleJson: any = [{ "Frequency": '', "Time": '', "Days": '' }];
  _attributesScheduleJson: any = [{ "Frequency": '', "Time": '', "Days": '' }];
  _tagsScheduleJson: any = [{ "Frequency": '', "Time": '', "Days": '' }];
  _termsScheduleJson: any = [{ "Frequency": '', "Time": '', "Days": '' }];

  _PriceImportMapping: any = [{ PriceLevel: '', Currency: '' }];
  _PriceExportMapping: any = [{ PriceLevel: '', Currency: '', SalePriceLevel: '', SavedSearch: '', ComparePrice: '', exportType: 'Modified' }];
  _Import_Matrix_offOn: any = false;
  _Import_Kit_offOn: any = false;
  _Import_ItemGroup_offOn: any = false;
  _Export_Matrix_offOn: any = false;
  _Export_nonInventory_offOn: any = false;
  _Export_Kit_offOn: any = false;
  _Export_ItemGroup_offOn: any = false;
  _Export_VS_offOn: any = false;
  _Export_Service_offon: any = false;
  _priceLevel: Array<any> = [];
  _currencyLevel: any;
  _ItemSavedSearchDropDown: any = [];
  _savedSearchResults: any = { ItemExport: "", MatrixItemExport: "", KitItemExport: "", ItemGroupExport: "" };
  import_result: any;
  import_result1: any
  _StdImportDays: any = []; _MatImportDays: any = []; _KitImportDays: any = []; _ItemGroupImportDays: any = []; _priceImportDays: any = [];
  _StdExportDays: any = []; _MatExportDays: any = []; _KitExportDays: any = []; _ItemGroupExportDays: any = []; _ShopifyVariationExportDays: any = []; _priceExportDays: any = []; _serviceItemExportDays: any = []; _noninventoryExportDays: any = [];

  _categoriesImportDays: any = []; _attributesImportDays: any = []; _tagsImportDays: any = []; _termsImportDays: any = [];

  _priceSaveSearch: any = false; _proceNormalFields: any;
  _isShopify: any = false;
  _isWooCommerce: any = false;
  _isMagento: any = false;
  _isEbay: any = false;

  _datatypeArrname: any;
  _datatypeIndexValue: any;

  _imItProcessingName: any; _imMTProcessingName: any; _imKTProcessingName: any; _imGPProcessingName: any;
  _exItProcessingName: any; _exMTProcessingName: any; _exKTProcessingName: any; _exGPProcessingName: any; _exVSProcessingName: any; _serviceExportProcessingName: any;
  _primProcessingName: any; _prexProcessingName: any; _catIProcessingName: any; _attIProcessingName: any;
  _tagIProcessingName: any; _terIProcessingName: any;

  _serviceAPI: any = {
    itemimport: "", kitimport: "", groupimport: "", matriximport: "",
    itemexport: "InventoryItemExport", serviceexport: "ServiceItemExport", nonInventoryExport: 'NonInventoryItemExport',
    kitexport: "KitItemExport", groupexport: "GroupItemExport", matrixexport: "MatrixItemExport", shopifyVariationExport: "",
    priceimport: "", priceexport: "ItemPriceExport", catImport: "CategoriesImport", attImport: "AttributesImport", tagImport: "TagsImport", terImport: ""
  };
  _connectorID: any = "";
  _nameValueList: any = [{ Name: '', Value: '', Default: '' }];

  _inventoryPriceIncluded: any = false;
  _inventoryImageIncluded: any = false;
  _inventoryInvenIncluded: any = false;

  _NoninventoryPriceIncluded: any = false;
  _NoninventoryImageIncluded: any = false;
  _NoninventoryInvenIncluded: any = false;

  _MatrixPriceIncluded: any = false;
  _MatrixImageIncluded: any = false;
  _MatrixInvenIncluded: any = false;

  _KitPriceIncluded: any = false;
  _KitImageIncluded: any = false;
  _KitInvenIncluded: any = false;

  _ItemGroupPriceIncluded: any = false;
  _ItemGroupImageIncluded: any = false;
  _ItemGroupInvenIncluded: any = false;

  _ServicePriceIncluded: any = false;
  _ServiceImageIncluded: any = false;
  _ServiceInvenIncluded: any = false;

  _ebayMatrixItemModel: any = false;

  _invMateMap: any = "";
  _MatMateMap: any = "";
  _KitMateMap: any = "";
  _NonMetaMap: any = "";
  _erpName: any = localStorage.getItem('erpname');
  ngOnInit() {
    const parameters = window.location.href;
    var pieces = parameters.split(/[\s/]+/);
    this._connectorID = (pieces[pieces.length - 1]);
    localStorage.setItem('ActiveLiName', 'Product');
    switch ((localStorage.getItem('SelectedconnectorName')).toLowerCase()) {
      case 'shopify':
        {
          this._isShopify = true;
          break;
        }
      case 'woocommerce': {
        this._isWooCommerce = true;
        break;
      }
      case 'magento': {
        this._isMagento = true;
        break;
      }
      case 'ebay': {
        this._isEbay = true;
      }
      default: {
        break;
      }
    }
    this.blockUI.start('Loading...');
    this.connectorsList();
    this.GetSchedulerData();
    this.GetMappingFeilds();
    this.GetPriceAndCurrency();
  }
  GetPriceAndCurrency() {
    this.connectService.GetProductAdditionlInfo().subscribe((data: any) => {
      if (data.status != "Error") {
        this._priceLevel = data.results.priceLevels;
        this._currencyLevel = data.results.currencyList;
        this._ItemSavedSearchDropDown = data.results.itemSavedSearches;
      }
    })
  }
  GetSchedulerData() {
    var Result = this.connectService.GetSchedulerListItems();
    this._SchedulerFrequencyList = (Result[0].Frequency);
    this._SchedulerStartTimeList = (Result[0].Time);
    this._SchedulerDaysList = (Result[0].DayList);
  }
  GetMappingFeilds() {
    this.connectService.GetItemFieldMapping('Item', this._connectorID).subscribe((data: any) => {
      this.blockUI.stop();
      if (data.status != "Error") {
        this._StandFieldMappingSrcJSON = data.results.columnStandardFields;
        this._StandFieldMappingDstJSON = data.results.columnStandardFields;
      }
    })
    this.connectService.GetItemFieldMapping('KitItem', this._connectorID).subscribe((data: any) => {
      this.blockUI.stop();
      if (data.status != "Error") {
        this._StandKitFieldMappingSrcJSON = data.results.columnStandardFields;
      }
    })
    this.connectService.GetItemFieldMapping('ItemGroup', this._connectorID).subscribe((data: any) => {
      this.blockUI.stop();
      if (data.status != "Error") {
        this._StandItemGroupFieldMappingSrcJSON = data.results.columnStandardFields;
      }
    })
  }
  connectorsList() {
    this.EmptyConnectorVariables();
    this.connectService.ListConnector(this._connectorID).subscribe((data: any) => {
      this.blockUI.stop();
      this._connectorData = data;
      var MappingFields = JSON.parse(data.results[0].connectorFields);
      if (data.results[0].savedSearchJSON != null && data.results[0].savedSearchJSON! + "")
        this._savedSearchResults = JSON.parse(data.results[0].savedSearchJSON);
      this._flowNames = JSON.parse(data.results[0].flowNames);
      this._importFlowName = (this._flowNames[0]).ProductImport;
      this._importMatrixFlowName = (this._flowNames[0]).ProductMatrixImport;
      this._importKitFlowName = (this._flowNames[0]).ProductKitImport;
      this._importItemGroupFlowName = (this._flowNames[0]).ProductItemGroupImport;

      this._exportFlowName = (this._flowNames[0]).ProductExport;
      this._exportNonFlowName = (this._flowNames[0]).ProductNonExport;
      this._exportMatrixFlowName = (this._flowNames[0]).ProductMatrixExport;
      this._exportKitFlowName = (this._flowNames[0]).ProductKitExport;
      this._exportItemGroupFlowName = (this._flowNames[0]).ProductItemGroupExport;
      this._exportServiceItemFlowName = (this._flowNames[0]).ProductServiceItemExport;
      this._PriceImportFlowName = (this._flowNames[0]).PriceImport;
      this._PriceexportFlowName = (this._flowNames[0]).PriceExport;

      if (this._connectorData.results[0].item_ImportScheduletime == null || this._connectorData.results[0].item_ImportScheduletime == "") {

      }
      else {
        this._ItemImportScheduleJson = JSON.parse(this._connectorData.results[0].item_ImportScheduletime);
        this._StdImportDays = this._ItemImportScheduleJson.StandardItemImport[0].Days.split(',');
        this._MatImportDays = this._ItemImportScheduleJson.MatrixItemImport[0].Days.split(',');
        this._KitImportDays = this._ItemImportScheduleJson.KitItemImport[0].Days.split(',');
        this._ItemGroupImportDays = this._ItemImportScheduleJson.ItemGroupItemImport[0].Days.split(',');
      }
      if (this._connectorData.results[0].item_ExportScheduletime == null || this._connectorData.results[0].item_ExportScheduletime == "") {

      }
      else {
        this._ItemExportScheduleJson = JSON.parse(this._connectorData.results[0].item_ExportScheduletime);
        if (this._ItemExportScheduleJson.ShopifyVariationItemImport) {

        } else {
          this._ItemExportScheduleJson.ShopifyVariationItemImport = this.ShopifyVariationItemImport;
        }
        if (this._ItemExportScheduleJson.NonInventoryExport) {
          this._noninventoryExportDays = this._ItemExportScheduleJson.NonInventoryExport[0].Days.split(',');
        }
        else {
          this._ItemExportScheduleJson.NonInventoryExport = this.StandardNonItemImport;
        }

        this._StdExportDays = this._ItemExportScheduleJson.StandardItemImport[0].Days.split(',');
        this._MatExportDays = this._ItemExportScheduleJson.MatrixItemImport[0].Days.split(',');
        this._KitExportDays = this._ItemExportScheduleJson.KitItemImport[0].Days.split(',');
        this._ItemGroupExportDays = this._ItemExportScheduleJson.ItemGroupItemImport[0].Days.split(',');
        this._serviceItemExportDays = this._ItemExportScheduleJson.ServiceItemExport[0].Days.split(',');

      }
      if (this._connectorData.results[0].price_ImportScheduletime == null || this._connectorData.results[0].price_ImportScheduletime == "") {

      }
      else {
        this._PriceImportScheduleJson = JSON.parse(this._connectorData.results[0].price_ImportScheduletime);
        this._priceImportDays = this._PriceImportScheduleJson[0].Days.split(',');
      }
      if (this._connectorData.results[0].price_ExportScheduletime == null || this._connectorData.results[0].price_ExportScheduletime == "") {

      }
      else {
        this._PriceExportScheduleJson = JSON.parse(this._connectorData.results[0].price_ExportScheduletime);
        this._priceExportDays = this._PriceExportScheduleJson[0].Days.split(',');
      }

      if (this._connectorData.results[0].categories_Scheduletime == null || this._connectorData.results[0].categories_Scheduletime == "") {
      }
      else {
        this._categoriesScheduleJson = JSON.parse(this._connectorData.results[0].categories_Scheduletime);
        this._categoriesImportDays = this._categoriesScheduleJson[0].Days.split(',');
      }

      if (this._connectorData.results[0].attributes_Scheduletime == null || this._connectorData.results[0].attributes_Scheduletime == "") {

      }
      else {
        this._attributesScheduleJson = JSON.parse(this._connectorData.results[0].attributes_Scheduletime);
        this._attributesImportDays = this._attributesScheduleJson[0].Days.split(',');
      }
      if (this._connectorData.results[0].tags_Scheduletime == null || this._connectorData.results[0].tags_Scheduletime == "") {

      }
      else {
        this._tagsScheduleJson = JSON.parse(this._connectorData.results[0].tags_Scheduletime);
        this._tagsImportDays = this._tagsScheduleJson[0].Days.split(',');
      }
      if (this._connectorData.results[0].terms_Scheduletime == null || this._connectorData.results[0].terms_Scheduletime == "") {

      }
      else {
        this._termsScheduleJson = JSON.parse(this._connectorData.results[0].terms_Scheduletime);
        this._termsImportDays = this._termsScheduleJson[0].Days.split(',');
      }

      this._serviceAPI = {
        serviceexport: "ServiceItemExport",
        nonInventoryExport: 'NonInventoryItemExport',
        itemimport: (data.results[0].api_Item_Import != "" && data.results[0].api_Item_Import != null) ? data.results[0].api_Item_Import : "",
        kitimport: (data.results[0].api_kitItem_Import != "" && data.results[0].api_kitItem_Import != null) ? data.results[0].api_kitItem_Import : "",
        groupimport: (data.results[0].api_groupItem_Import != "" && data.results[0].api_groupItem_Import != null) ? data.results[0].api_groupItem_Import : "",
        matriximport: (data.results[0].api_matrixItem_Import != "" && data.results[0].api_matrixItem_Import != null) ? data.results[0].api_matrixItem_Import : "",
        itemexport: (data.results[0].api_Item_Export != "" && data.results[0].api_Item_Export != null) ? data.results[0].api_Item_Export : "InventoryItemExport",
        shopifyVariationExport: "",
        kitexport: (data.results[0].api_kitItem_Export != "" && data.results[0].api_kitItem_Export != null) ? data.results[0].api_kitItem_Export : "KitItemExport",
        groupexport: (data.results[0].api_groupItem_Export != "" && data.results[0].api_groupItem_Export != null) ? data.results[0].api_groupItem_Export : "GroupItemExport",
        matrixexport: (data.results[0].api_matrixItem_Export != "" && data.results[0].api_matrixItem_Export != null) ? data.results[0].api_matrixItem_Export : "MatrixItemExport",
        priceimport: (data.results[0].api_Price_Import != "" && data.results[0].api_Price_Import != null) ? data.results[0].api_Price_Import : "",
        priceexport: (data.results[0].api_Price_Export != "" && data.results[0].api_Price_Export != null) ? data.results[0].api_Price_Export : "ItemPriceExport",
        catImport: "CategoriesImport", attImport: "AttributesImport", tagImport: "TagsImport", terImport: ""
      };

      for (var k = 0; k < MappingFields.length; k++) {
        if ((MappingFields[k].RecordType.toUpperCase()) == 'ITEM') {
          this._MarketPlaceFieldMapping.push({ label: MappingFields[k].Label, value: MappingFields[k].Title })
        }
        if ((MappingFields[k].RecordType.toUpperCase()) == 'ITEM' && (MappingFields[k].IsAccessible) == 'public') {
          this._EXP_MarketPlaceFieldMapping.push({ label: MappingFields[k].Label, value: MappingFields[k].Title })
        }
      }


      if (data.results[0].s_Item_Import == true || data.results[0].add_Item_Import == true)
        this._itemImportSubscribed = true;
      if (data.results[0].s_Item_Export == true || data.results[0].add_Item_Export == true)
        this._itemExportSubscribed = true;
      if (data.results[0].s_Price_Import == true || data.results[0].add_Price_Import == true)
        this._priceImportSubscribed = true;
      if (data.results[0].s_Price_Export == true || data.results[0].add_Price_Export == true)
        this._priceExportSubscribed = true;

      if (data.results[0].item_Import == true)
        this._itemImportEnabled = true;
      if (data.results[0].item_Export == true)
        this._itemExportEnabled = true;
      if (data.results[0].price_Import == true)
        this._priceImportEnabled = true;
      if (data.results[0].price_Export == true)
        this._priceExportEnabled = true;

      if (data.results[0].categories == true)
        this._categoriesImportEnabled = true;
      if (data.results[0].attributes == true)
        this._attributeImportEnabled = true;
      if (data.results[0].tags == true)
        this._tagsImportEnabled = true;
      if (data.results[0].terms == true)
        this._termsImportEnabled = true;
      this._Import_Matrix_offOn = this._ItemImportScheduleJson.MatrixItemImport[0].Status;
      this._Import_Kit_offOn = this._ItemImportScheduleJson.KitItemImport[0].Status;
      this._Import_ItemGroup_offOn = this._ItemImportScheduleJson.ItemGroupItemImport[0].Status;
      this._Export_Matrix_offOn = this._ItemExportScheduleJson.MatrixItemImport[0].Status;
      this._Export_Kit_offOn = this._ItemExportScheduleJson.KitItemImport[0].Status;
      this._Export_ItemGroup_offOn = this._ItemExportScheduleJson.ItemGroupItemImport[0].Status;
      try {
        this._Export_VS_offOn = this._ItemExportScheduleJson.ShopifyVariationItemImport[0].Status;
      }
      catch (ex) {

      }
      try {
        this._Export_nonInventory_offOn = this._ItemExportScheduleJson.NonInventoryExport[0].Status;
      }
      catch (ex) {

      }
      this._Export_Service_offon = this._ItemExportScheduleJson.ServiceItemExport[0].Status;

      if (this._connectorData.results[0].item_ImportFieldsMapping != null && this._connectorData.results[0].item_ImportFieldsMapping != "") {
        var FieldsMapping = JSON.parse(this._connectorData.results[0].item_ImportFieldsMapping)
        var ItemImportJsonMapping = FieldsMapping[0].StandartFieldMapping;
        var MatrixImportJsonMapping = FieldsMapping[0].MatrixFieldMapping;
        var KitImportJsonMapping = FieldsMapping[0].KitFieldMapping;
        var ItemGroupImportJsonMapping = FieldsMapping[0].ItemGroupFieldMapping;
        for (var i = 0; i < ItemImportJsonMapping.length; i++) {
          if (ItemImportJsonMapping[i].fieldType == 'Lock')
            this._importLockFieldMappingResult.push({ fieldType: ItemImportJsonMapping[i].fieldType, MarketPlace: ItemImportJsonMapping[i].MarketPlace, NetSuite: ItemImportJsonMapping[i].NetSuite, Default: ItemImportJsonMapping[i].Default });
          if (ItemImportJsonMapping[i].fieldType == 'Standard')
            this._importStdFieldMappingResult.push({ fieldType: ItemImportJsonMapping[i].fieldType, MarketPlace: ItemImportJsonMapping[i].MarketPlace, label: ItemImportJsonMapping[i].label, NetSuite: ItemImportJsonMapping[i].NetSuite, Default: ItemImportJsonMapping[i].Default });
        }
        for (var i = 0; i < MatrixImportJsonMapping.length; i++) {
          if (MatrixImportJsonMapping[i].fieldType == 'Lock')
            this._MatriximportLockFieldMappingResult.push({ fieldType: MatrixImportJsonMapping[i].fieldType, MarketPlace: MatrixImportJsonMapping[i].MarketPlace, NetSuite: MatrixImportJsonMapping[i].NetSuite, Default: MatrixImportJsonMapping[i].Default });
          if (MatrixImportJsonMapping[i].fieldType == 'Standard')
            this._MatriximportStdFieldMappingResult.push({ fieldType: MatrixImportJsonMapping[i].fieldType, MarketPlace: MatrixImportJsonMapping[i].MarketPlace, label: MatrixImportJsonMapping[i].label, NetSuite: MatrixImportJsonMapping[i].NetSuite, Default: MatrixImportJsonMapping[i].Default });
        }
        for (var i = 0; i < KitImportJsonMapping.length; i++) {
          if (KitImportJsonMapping[i].fieldType == 'Lock')
            this._KitimportLockFieldMappingResult.push({ fieldType: KitImportJsonMapping[i].fieldType, MarketPlace: KitImportJsonMapping[i].MarketPlace, NetSuite: KitImportJsonMapping[i].NetSuite, Default: KitImportJsonMapping[i].Default });
          if (KitImportJsonMapping[i].fieldType == 'Standard')
            this._KitimportStdFieldMappingResult.push({ fieldType: KitImportJsonMapping[i].fieldType, MarketPlace: KitImportJsonMapping[i].MarketPlace, label: KitImportJsonMapping[i].label, NetSuite: KitImportJsonMapping[i].NetSuite, Default: KitImportJsonMapping[i].Default });
        }
        for (var i = 0; i < ItemGroupImportJsonMapping.length; i++) {
          if (ItemGroupImportJsonMapping[i].fieldType == 'Lock')
            this._ItemGroupimportLockFieldMappingResult.push({ fieldType: ItemGroupImportJsonMapping[i].fieldType, MarketPlace: ItemGroupImportJsonMapping[i].MarketPlace, NetSuite: ItemGroupImportJsonMapping[i].NetSuite, Default: ItemGroupImportJsonMapping[i].Default });
          if (ItemGroupImportJsonMapping[i].fieldType == 'Standard')
            this._ItemGroupimportStdFieldMappingResult.push({ fieldType: ItemGroupImportJsonMapping[i].fieldType, MarketPlace: ItemGroupImportJsonMapping[i].MarketPlace, label: ItemGroupImportJsonMapping[i].label, NetSuite: ItemGroupImportJsonMapping[i].NetSuite, Default: ItemGroupImportJsonMapping[i].Default });
        }
      }
      if (this._connectorData.results[0].price_ImportFieldsMapping != "" && this._connectorData.results[0].price_ImportFieldsMapping != null)
        this._PriceImportMapping = (JSON.parse(this._connectorData.results[0].price_ImportFieldsMapping));
      if (this._connectorData.results[0].price_ExportFieldsMapping != "" && this._connectorData.results[0].price_ExportFieldsMapping != null)
        this._PriceExportMapping = (JSON.parse(this._connectorData.results[0].price_ExportFieldsMapping));
      try {
        this._isBasePrice = this._PriceExportMapping[0].IsBasePrice;
      }
      catch (ex) {

      }
      if (this._PriceExportMapping[0].SavedSearch != null && this._PriceExportMapping[0].SavedSearch != "") {
        this._priceSaveSearch = false;
        this._PriceExportMapping[0].PriceLevel = "";
        this._PriceExportMapping[0].Currency = "";
        this._PriceExportMapping[0].ComparePrice = "";
        this._PriceExportMapping[0].SalePriceLevel = "";
        this._PriceExportMapping[0].exportType = "";
        this._proceNormalFields = true;
      }
      if (this._connectorData.results[0].item_ExportFieldsMapping != null && this._connectorData.results[0].item_ExportFieldsMapping != "") {
        var ExFieldsMapping = JSON.parse(this._connectorData.results[0].item_ExportFieldsMapping);
        var ItemExportJsonMapping = ExFieldsMapping[0].StandartFieldMapping;
        var ServiceItemJsonMapping = ExFieldsMapping[0].ServiceFiledMapping;
        var MatrixExportJsonMapping = ExFieldsMapping[0].MatrixFieldMapping;
        var KitExportJsonMapping = ExFieldsMapping[0].KitFieldMapping;
        var ItemGroupExportJsonMapping = ExFieldsMapping[0].ItemGroupFieldMapping;
        var NonInventoryMapping = null;
        try {
          this._invMateMap = JSON.stringify(JSON.parse(ExFieldsMapping[0].Inv_MetaMap));
          this._MatMateMap = JSON.stringify(JSON.parse(ExFieldsMapping[0].Matrix_MetaMap));
          this._KitMateMap = JSON.stringify(JSON.parse(ExFieldsMapping[0].Kit_MetaMap));
          this._NonMetaMap = JSON.stringify(JSON.parse(ExFieldsMapping[0].Non_MetaMapping));
        }
        catch (ex) {

        }

        if (ExFieldsMapping[0].NonInventoryMapping) {
          NonInventoryMapping = ExFieldsMapping[0].NonInventoryMapping;
        }
        if (this._isEbay == true) {
          try {
            ItemExportJsonMapping = ExFieldsMapping[0].StandartFieldMapping[0].FieldMapping;
            this._inventoryPriceIncluded = ExFieldsMapping[0].StandartFieldMapping[0].PriceIncluded;
            this._inventoryImageIncluded = ExFieldsMapping[0].StandartFieldMapping[0].ImageIncluded;
            this._inventoryInvenIncluded = ExFieldsMapping[0].StandartFieldMapping[0].InventoryIncluded;

          }
          catch (ex) {

          }
          try {
            NonInventoryMapping = ExFieldsMapping[0].NonInventoryMapping[0].FieldMapping;
            this._NoninventoryPriceIncluded = ExFieldsMapping[0].NonInventoryMapping[0].PriceIncluded;
            this._NoninventoryImageIncluded = ExFieldsMapping[0].NonInventoryMapping[0].ImageIncluded;
            this._NoninventoryInvenIncluded = ExFieldsMapping[0].NonInventoryMapping[0].InventoryIncluded;
          }
          catch (ex) {

          }
          try {
            ServiceItemJsonMapping = ExFieldsMapping[0].ServiceFiledMapping[0].FieldMapping;
            this._ServicePriceIncluded = ExFieldsMapping[0].ServiceFiledMapping[0].PriceIncluded;
            this._ServiceImageIncluded = ExFieldsMapping[0].ServiceFiledMapping[0].ImageIncluded;
            this._ServiceInvenIncluded = ExFieldsMapping[0].ServiceFiledMapping[0].InventoryIncluded;

          }
          catch (ex) {

          }
          try {
            MatrixExportJsonMapping = ExFieldsMapping[0].MatrixFieldMapping[0].FieldMapping;
            this._MatrixPriceIncluded = ExFieldsMapping[0].MatrixFieldMapping[0].PriceIncluded;
            this._MatrixImageIncluded = ExFieldsMapping[0].MatrixFieldMapping[0].ImageIncluded;
            this._MatrixInvenIncluded = ExFieldsMapping[0].MatrixFieldMapping[0].InventoryIncluded;
            this._ebayMatrixItemModel = ExFieldsMapping[0].MatrixFieldMapping[0].ItemType;
          }
          catch (ex) {

          }
          try {
            KitExportJsonMapping = ExFieldsMapping[0].KitFieldMapping[0].FieldMapping;
            this._KitPriceIncluded = ExFieldsMapping[0].KitFieldMapping[0].PriceIncluded;
            this._KitImageIncluded = ExFieldsMapping[0].KitFieldMapping[0].ImageIncluded;
            this._KitInvenIncluded = ExFieldsMapping[0].KitFieldMapping[0].InventoryIncluded;
          }
          catch (ex) {

          }

          try {
            ItemGroupExportJsonMapping = ExFieldsMapping[0].ItemGroupFieldMapping[0].FieldMapping;
            this._ItemGroupPriceIncluded = ExFieldsMapping[0].ItemGroupFieldMapping[0].PriceIncluded;
            this._ItemGroupImageIncluded = ExFieldsMapping[0].ItemGroupFieldMapping[0].ImageIncluded;
            this._ItemGroupInvenIncluded = ExFieldsMapping[0].ItemGroupFieldMapping[0].InventoryIncluded;
          }
          catch (ex) {

          }
        }
        try {
          for (var i = 0; i < ItemExportJsonMapping.length; i++) {
            if (ItemExportJsonMapping[i].fieldType == 'Lock')
              this._exportLockFieldMappingResult.push({ fieldType: ItemExportJsonMapping[i].fieldType, NetSuite: ItemExportJsonMapping[i].NetSuite, MarketPlace: ItemExportJsonMapping[i].MarketPlace, Default: ItemExportJsonMapping[i].Default });
            if (ItemExportJsonMapping[i].fieldType == 'Standard' || ItemExportJsonMapping[i].fieldType == 'Mandatory')
              this._exportStdFieldMappingResult.push({ fieldType: ItemExportJsonMapping[i].fieldType, label: ItemExportJsonMapping[i].label, NetSuite: ItemExportJsonMapping[i].NetSuite, MarketPlace: ItemExportJsonMapping[i].MarketPlace, Default: ItemExportJsonMapping[i].Default });
          }
        }
        catch (ex) {

        }

        try {
          if (NonInventoryMapping != null) {
            for (var i = 0; i < NonInventoryMapping.length; i++) {
              if (NonInventoryMapping[i].fieldType == 'Standard')
                this._exportNonStdFieldMappingResult.push({ fieldType: NonInventoryMapping[i].fieldType, label: NonInventoryMapping[i].label, NetSuite: NonInventoryMapping[i].NetSuite, MarketPlace: NonInventoryMapping[i].MarketPlace, Default: NonInventoryMapping[i].Default });
            }
          }
        }
        catch (ex) {

        }

        try {
          for (var i = 0; i < ServiceItemJsonMapping.length; i++) {
            if (ServiceItemJsonMapping[i].fieldType == 'Lock')
              this._exportLockServiceFieldMappingResult.push({ fieldType: ServiceItemJsonMapping[i].fieldType, NetSuite: ServiceItemJsonMapping[i].NetSuite, MarketPlace: ServiceItemJsonMapping[i].MarketPlace, Default: ServiceItemJsonMapping[i].Default });
            if (ServiceItemJsonMapping[i].fieldType == 'Standard' || ServiceItemJsonMapping[i].fieldType == 'Mandatory')
              this._exportServiceStdFieldMappingResult.push({ fieldType: ServiceItemJsonMapping[i].fieldType, label: ServiceItemJsonMapping[i].label, NetSuite: ServiceItemJsonMapping[i].NetSuite, MarketPlace: ServiceItemJsonMapping[i].MarketPlace, Default: ServiceItemJsonMapping[i].Default });
          }
        }
        catch (ex) {

        }
        try {
          for (var i = 0; i < MatrixExportJsonMapping.length; i++) {
            if (MatrixExportJsonMapping[i].fieldType == 'Lock')
              this._exportMatrixLockFieldMappingResult.push({ fieldType: MatrixExportJsonMapping[i].fieldType, NetSuite: MatrixExportJsonMapping[i].NetSuite, MarketPlace: MatrixExportJsonMapping[i].MarketPlace, Default: MatrixExportJsonMapping[i].Default });
            if (MatrixExportJsonMapping[i].fieldType == 'Standard' || MatrixExportJsonMapping[i].fieldType == 'Mandatory')
              this._exportMatrixStdFieldMappingResult.push({ fieldType: MatrixExportJsonMapping[i].fieldType, label: MatrixExportJsonMapping[i].label, NetSuite: MatrixExportJsonMapping[i].NetSuite, MarketPlace: MatrixExportJsonMapping[i].MarketPlace, Default: MatrixExportJsonMapping[i].Default });
          }
        }
        catch (ex) {

        }
        try {
          for (var i = 0; i < KitExportJsonMapping.length; i++) {
            if (KitExportJsonMapping[i].fieldType == 'Lock')
              this._exportKitLockFieldMappingResult.push({ fieldType: KitExportJsonMapping[i].fieldType, NetSuite: KitExportJsonMapping[i].NetSuite, MarketPlace: KitExportJsonMapping[i].MarketPlace, Default: KitExportJsonMapping[i].Default });
            if (KitExportJsonMapping[i].fieldType == 'Standard' || KitExportJsonMapping[i].fieldType == 'Mandatory')
              this._exportKitStdFieldMappingResult.push({ fieldType: KitExportJsonMapping[i].fieldType, label: KitExportJsonMapping[i].label, NetSuite: KitExportJsonMapping[i].NetSuite, MarketPlace: KitExportJsonMapping[i].MarketPlace, Default: KitExportJsonMapping[i].Default });
          }
        }
        catch (ex) {

        }
        try {
          for (var i = 0; i < ItemGroupExportJsonMapping.length; i++) {
            if (ItemGroupExportJsonMapping[i].fieldType == 'Lock')
              this._exportItemGroupLockFieldMappingResult.push({ fieldType: ItemGroupExportJsonMapping[i].fieldType, NetSuite: ItemGroupExportJsonMapping[i].NetSuite, MarketPlace: ItemGroupExportJsonMapping[i].MarketPlace, Default: ItemGroupExportJsonMapping[i].Default });
            if (ItemGroupExportJsonMapping[i].fieldType == 'Standard' || ItemGroupExportJsonMapping[i].fieldType == 'Mandatory')
              this._exportLItemGroupStdFieldMappingResult.push({ fieldType: ItemGroupExportJsonMapping[i].fieldType, label: ItemGroupExportJsonMapping[i].label, NetSuite: ItemGroupExportJsonMapping[i].NetSuite, MarketPlace: ItemGroupExportJsonMapping[i].MarketPlace, Default: ItemGroupExportJsonMapping[i].Default });
          }
        }
        catch (ex) {

        }
        try {
          if (this._isWooCommerce) {
            var variatioJsonMapping = ExFieldsMapping[0].VariationFiledMapping;
            for (var i = 0; i < variatioJsonMapping.length; i++) {
              this._variationFieldMapping.push({ source: variatioJsonMapping[i].NetSuite, destination: variatioJsonMapping[i].MarketPlace, Default: '', label: '', fieldType: 'Standard' });
            }
          }
          if (this._isShopify) {
            var shopifyVariationMapping = ExFieldsMapping[0].ShopifyVariationMapping;
            for (var i = 0; i < shopifyVariationMapping.length; i++) {
              this._exportShopifyVariationsStdFieldMappingResult.push({ fieldType: 'Standard', label: shopifyVariationMapping[i].label, NetSuite: shopifyVariationMapping[i].NetSuite, MarketPlace: shopifyVariationMapping[i].MarketPlace, Default: shopifyVariationMapping[i].Default });
            }
          }
        }
        catch (ex) {

        }
      }
      this._importStdFieldMappingResult.push({ MarketPlace: '', NetSuite: '', Default: '', label: '', fieldType: 'Standard' });
      this._MatriximportStdFieldMappingResult.push({ MarketPlace: '', NetSuite: '', Default: '', label: '', fieldType: 'Standard' });
      this._KitimportStdFieldMappingResult.push({ MarketPlace: '', NetSuite: '', Default: '', label: '', fieldType: 'Standard' });
      this._ItemGroupimportStdFieldMappingResult.push({ MarketPlace: '', NetSuite: '', Default: '', label: '', fieldType: 'Standard' });
      this._exportStdFieldMappingResult.push({ NetSuite: '', MarketPlace: '', Default: '', label: '', fieldType: 'Standard' });
      this._exportNonStdFieldMappingResult.push({ NetSuite: '', MarketPlace: '', Default: '', label: '', fieldType: 'Standard' });
      this._exportServiceStdFieldMappingResult.push({ NetSuite: '', MarketPlace: '', Default: '', label: '', fieldType: 'Standard' });
      this._exportMatrixStdFieldMappingResult.push({ NetSuite: '', MarketPlace: '', Default: '', label: '', fieldType: 'Standard' });
      this._exportKitStdFieldMappingResult.push({ NetSuite: '', MarketPlace: '', Default: '', label: '', fieldType: 'Standard' });
      this._exportLItemGroupStdFieldMappingResult.push({ NetSuite: '', MarketPlace: '', Default: '', label: '', fieldType: 'Standard' });
      this._exportShopifyVariationsStdFieldMappingResult.push({ NetSuite: '', MarketPlace: '', Default: '', label: '', fieldType: 'Standard' });
      this._variationFieldMapping.push({ source: '', destination: '', Default: '', label: '', fieldType: 'Standard' });
    })
  }
  SavedSearchChange() {
    var SC_e = (document.getElementById("PriceExport_SavedSeach")) as HTMLSelectElement;
    var SC_sel = SC_e.selectedIndex;
    var SC_opt = SC_e.options[SC_sel];
    var SValue = ((<HTMLOptionElement>SC_opt).value);
    if (SValue != null && SValue != "") {
      this._priceSaveSearch = false;
      this._PriceExportMapping[0].PriceLevel = "";
      this._PriceExportMapping[0].Currency = "";
      this._PriceExportMapping[0].ComparePrice = "";
      this._PriceExportMapping[0].SalePriceLevel = "";
      this._PriceExportMapping[0].exportType = "";
      this._proceNormalFields = true;
    }
    else {
      this._proceNormalFields = false;
    }
  }
  UpdateFlowStatus(FlowUpdateVariable) {
    this.connectService.UpdateFlowStatusByConnector(FlowUpdateVariable, this._connectorID).subscribe((data: any) => {

    })
  }

  _OnChangeStandardImportFieldMapping() {
    var index_id = (this._importStdFieldMappingResult.length) - 1;
    var SiD = "StdSrc_" + index_id;
    var DiD = "StdDst_" + index_id;
    var DfD = "DFTStd_" + index_id;
    var S_e = (document.getElementById("" + SiD)) as HTMLSelectElement;
    var S_sel = S_e.selectedIndex;
    var S_opt = S_e.options[S_sel];
    var ExpValue = (<HTMLInputElement>document.getElementById(DiD)).value;
    var DestValue = (<HTMLInputElement>document.getElementById(DfD)).value;
    if (ExpValue != "" && (((<HTMLOptionElement>S_opt).value) != "" || DestValue != "")) {
      this._importStdFieldMappingResult.push({ MarketPlace: '', NetSuite: '', Default: '', fieldType: 'Standard' });
    }
  }

  _OnChangeMatrixImportFieldMapping() {
    var index_id = (this._MatriximportStdFieldMappingResult.length) - 1;
    var SiD = "IM_MatrixSrc_" + index_id;
    var DiD = "IM_MatrixDst_" + index_id;
    var DfD = "DFTMatrixStd_" + index_id;
    var S_e = (document.getElementById("" + SiD)) as HTMLSelectElement;
    var S_sel = S_e.selectedIndex;
    var S_opt = S_e.options[S_sel];
    var ExpValue = (<HTMLInputElement>document.getElementById(DiD)).value;
    var DestValue = (<HTMLInputElement>document.getElementById(DfD)).value;
    if (ExpValue != "" && (((<HTMLOptionElement>S_opt).value) != "" || DestValue != "")) {
      this._MatriximportStdFieldMappingResult.push({ MarketPlace: '', NetSuite: '', Default: '', fieldType: 'Standard' });
    }
  }

  _OnChangeKitImportFieldMapping() {
    var index_id = (this._KitimportStdFieldMappingResult.length) - 1;
    var SiD = "IM_KitSrc_" + index_id;
    var DiD = "IM_KitDst_" + index_id;
    var DfD = "DFTKitStd_" + index_id;
    var S_e = (document.getElementById("" + SiD)) as HTMLSelectElement;
    var S_sel = S_e.selectedIndex;
    var S_opt = S_e.options[S_sel];
    var ExpValue = (<HTMLInputElement>document.getElementById(DiD)).value;
    var DestValue = (<HTMLInputElement>document.getElementById(DfD)).value;
    if (ExpValue != "" && (((<HTMLOptionElement>S_opt).value) != "" || DestValue != "")) {
      this._KitimportStdFieldMappingResult.push({ MarketPlace: '', NetSuite: '', Default: '', fieldType: 'Standard' });
    }
  }

  _OnChangeItemGroupImportFieldMapping() {
    var index_id = (this._ItemGroupimportStdFieldMappingResult.length) - 1;
    var SiD = "IM_ItemGroupSrc_" + index_id;
    var DiD = "IM_ItemGroupDst_" + index_id;
    var DfD = "DFTItemGroupStd_" + index_id;
    var S_e = (document.getElementById("" + SiD)) as HTMLSelectElement;
    var S_sel = S_e.selectedIndex;
    var S_opt = S_e.options[S_sel];
    var ExpValue = (<HTMLInputElement>document.getElementById(DiD)).value;
    var DestValue = (<HTMLInputElement>document.getElementById(DfD)).value;
    if (ExpValue != "" && (((<HTMLOptionElement>S_opt).value) != "" || DestValue != "")) {
      this._ItemGroupimportStdFieldMappingResult.push({ MarketPlace: '', NetSuite: '', Default: '', fieldType: 'Standard' });
    }
  }

  _OnChangeStandardExportFieldMapping() {
    var index_id = (this._exportStdFieldMappingResult.length) - 1;
    var SiD = "ExStdSrc_" + index_id;
    var DiD = "ExStdDst_" + index_id;
    var DfD = "DFTStdEx_" + index_id;
    var D_e = (document.getElementById("" + DiD)) as HTMLSelectElement;
    var D_sel = D_e.selectedIndex;
    var D_opt = D_e.options[D_sel];
    var SourceValue = (<HTMLInputElement>document.getElementById(SiD)).value;
    var DestValue = ((<HTMLOptionElement>D_opt).value)
    var DefaultValue = (<HTMLInputElement>document.getElementById(DfD)).value;
    if (DestValue != "" && (SourceValue != "" || DefaultValue != "")) {
      this._exportStdFieldMappingResult.push({ NetSuite: '', MarketPlace: '', Default: '', fieldType: 'Standard' });
    }
    if (this._isEbay == true) {
      // if (DestValue.includes('ItemSpecifics')) {
      //   var cogid = "coggSrc_" + index_id; ActivatedRoute
      //   document.getElementById(cogid).style.display = "block";
      // }
    }
  }

  _OnChangeStandardNonExportFieldMapping() {
    var index_id = (this._exportNonStdFieldMappingResult.length) - 1;
    var SiD = "ExNonStdSrc_" + index_id;
    var DiD = "ExNonStdDst_" + index_id;
    var DfD = "DFTNonStdEx_" + index_id;
    var D_e = (document.getElementById("" + DiD)) as HTMLSelectElement;
    var D_sel = D_e.selectedIndex;
    var D_opt = D_e.options[D_sel];
    var SourceValue = (<HTMLInputElement>document.getElementById(SiD)).value;
    var DestValue = ((<HTMLOptionElement>D_opt).value)
    var DefaultValue = (<HTMLInputElement>document.getElementById(DfD)).value;
    if (DestValue != "" && (SourceValue != "" || DefaultValue != "")) {
      this._exportNonStdFieldMappingResult.push({ NetSuite: '', MarketPlace: '', Default: '', fieldType: 'Standard' });
    }
  }

  onchangeserviceitemexport() {
    var index_id = (this._exportServiceStdFieldMappingResult.length) - 1;
    var SiD = "ExServiceStdSrc_" + index_id;
    var DiD = "ExServiceStdDst_" + index_id;
    var DfD = "DFTServiceStdEx_" + index_id;
    var D_e = (document.getElementById("" + DiD)) as HTMLSelectElement;
    var D_sel = D_e.selectedIndex;
    var D_opt = D_e.options[D_sel];
    var SourceValue = (<HTMLInputElement>document.getElementById(SiD)).value;
    var DestValue = ((<HTMLOptionElement>D_opt).value)
    var DefaultValue = (<HTMLInputElement>document.getElementById(DfD)).value;
    if (DestValue != "" && (SourceValue != "" || DefaultValue != "")) {
      this._exportServiceStdFieldMappingResult.push({ NetSuite: '', MarketPlace: '', Default: '', fieldType: 'Standard' });
    }
  }

  _OnChangeMatrixExportFieldMapping() {
    var index_id = (this._exportMatrixStdFieldMappingResult.length) - 1;
    var SiD = "ExMatrixStdSrc_" + index_id;
    var DiD = "ExMatrixStdDst_" + index_id;
    var DfD = "DFTMatrixStdEx_" + index_id;
    var D_e = (document.getElementById("" + DiD)) as HTMLSelectElement;
    var D_sel = D_e.selectedIndex;
    var D_opt = D_e.options[D_sel];
    var SourceValue = (<HTMLInputElement>document.getElementById(SiD)).value;
    var DestValue = ((<HTMLOptionElement>D_opt).value)
    var DefaultValue = (<HTMLInputElement>document.getElementById(DfD)).value;
    if (DestValue != "" && (SourceValue != "" || DefaultValue != "")) {
      this._exportMatrixStdFieldMappingResult.push({ NetSuite: '', MarketPlace: '', Default: '', fieldType: 'Standard' });
    }
  }
  _OnChangeKitExportFieldMapping() {
    var index_id = (this._exportKitStdFieldMappingResult.length) - 1;
    var SiD = "ExKitStdSrc_" + index_id;
    var DiD = "ExKitStdDst_" + index_id;
    var DfD = "DFTKitStdEx_" + index_id;
    var D_e = (document.getElementById("" + DiD)) as HTMLSelectElement;
    var D_sel = D_e.selectedIndex;
    var D_opt = D_e.options[D_sel];
    var SourceValue = (<HTMLInputElement>document.getElementById(SiD)).value;
    var DestValue = ((<HTMLOptionElement>D_opt).value)
    var DefaultValue = (<HTMLInputElement>document.getElementById(DfD)).value;
    if (DestValue != "" && (SourceValue != "" || DefaultValue != "")) {
      this._exportKitStdFieldMappingResult.push({ NetSuite: '', MarketPlace: '', Default: '', fieldType: 'Standard' });
    }
  }
  _OnChangeIGExportFieldMapping() {
    var index_id = (this._exportLItemGroupStdFieldMappingResult.length) - 1;
    var SiD = "ExIGStdSrc_" + index_id;
    var DiD = "ExIGStdDst_" + index_id;
    var DfD = "DFTIGStdEx_" + index_id;
    var D_e = (document.getElementById("" + DiD)) as HTMLSelectElement;
    var D_sel = D_e.selectedIndex;
    var D_opt = D_e.options[D_sel];
    var SourceValue = (<HTMLInputElement>document.getElementById(SiD)).value;
    var DestValue = ((<HTMLOptionElement>D_opt).value)
    var DefaultValue = (<HTMLInputElement>document.getElementById(DfD)).value;
    if (DestValue != "" && (SourceValue != "" || DefaultValue != "")) {
      this._exportLItemGroupStdFieldMappingResult.push({ NetSuite: '', MarketPlace: '', Default: '', fieldType: 'Standard' });
    }
  }

  _onChangeShopifyVarientExportFieldMapping() {
    var index_id = (this._exportShopifyVariationsStdFieldMappingResult.length) - 1;
    var SiD = "ExVCStdSrc_" + index_id;
    var DiD = "ExVCStdDst_" + index_id;
    var DfD = "DFTVCStdEx_" + index_id;
    var D_e = (document.getElementById("" + DiD)) as HTMLSelectElement;
    var D_sel = D_e.selectedIndex;
    var D_opt = D_e.options[D_sel];
    var SourceValue = (<HTMLInputElement>document.getElementById(SiD)).value;
    var DestValue = ((<HTMLOptionElement>D_opt).value)
    var DefaultValue = (<HTMLInputElement>document.getElementById(DfD)).value;
    if (DestValue != "" && (SourceValue != "" || DefaultValue != "")) {
      this._exportShopifyVariationsStdFieldMappingResult.push({ NetSuite: '', MarketPlace: '', Default: '', fieldType: 'Standard' });
    }
  }

  _OnChangeVariationFieldMapping() {
    var index_id = (this._variationFieldMapping.length) - 1;
    var SiD = "variSrc_" + index_id;
    var DiD = "variDes_" + index_id;
    var SourceValue = (<HTMLInputElement>document.getElementById(SiD)).value;
    var DestValue = (<HTMLInputElement>document.getElementById(DiD)).value;
    if (DestValue != "" && SourceValue != "") {
      this._variationFieldMapping.push({ source: '', destination: '', Default: '', fieldType: 'Standard' });
    }
  }

  PostImportFieldMapping() {
    this._updatedImportFieldMapping = [];
    var StandartFieldMapping = []; var MatrixFieldMapping = []; var KitFieldMapping = []; var ItemGroupFieldMapping = [];
    for (var j = 0; j < this._importLockFieldMappingResult.length; j++) {
      var SiD = "SpnImpLockSrc_" + j;
      var DiD = "SpnImpLockDes_" + j;
      var DfD = "SpnImLockDef_" + j;
      var SIMLockValue = (<HTMLInputElement>document.getElementById(SiD)).innerHTML
      var DefIMLockValue = (<HTMLInputElement>document.getElementById(DiD)).innerHTML
      var DesIMLockValue = (<HTMLInputElement>document.getElementById(DfD)).innerHTML
      StandartFieldMapping.push({ Source: SIMLockValue, NetSuite: DefIMLockValue, Default: DesIMLockValue, fieldType: 'Lock' })
    }
    for (var i = 0; i < this._importStdFieldMappingResult.length; i++) {
      var SiD = "StdSrc_" + i;
      var DiD = "StdDst_" + i;
      var DfD = "DFTStd_" + i;
      var S_e = (document.getElementById("" + SiD)) as HTMLSelectElement;
      var S_sel = S_e.selectedIndex;
      var S_opt = S_e.options[S_sel];
      var ExpValue = this.GetValueFromArray(this._StandFieldMappingDstJSON, ((<HTMLInputElement>document.getElementById(DiD)).value));
      var DataType = this.GetFieldTypeFromArray(this._StandFieldMappingDstJSON, ((<HTMLInputElement>document.getElementById(DiD)).value));
      var DestValue = (<HTMLInputElement>document.getElementById(DfD)).value;
      if (ExpValue != "" && (((<HTMLOptionElement>S_opt).value) != "" || DestValue != "")) {
        if ((this.NetSuiteFieldExists(StandartFieldMapping, ExpValue)) == false)
          StandartFieldMapping.push({ MarketPlace: ((<HTMLOptionElement>S_opt).value), label: ((<HTMLInputElement>document.getElementById(DiD)).value), NetSuite: ExpValue, Default: DestValue, fieldType: this._importStdFieldMappingResult[i].fieldType, DataType: DataType })
      }
    }
    for (var j = 0; j < this._MatriximportLockFieldMappingResult.length; j++) {
      var SiD = "SpnMatrixImpLockSrc_" + j;
      var DiD = "SpnMatrixImpLockDes_" + j;
      var DfD = "SpnMatrixImLockDef_" + j;
      var SIMLockValue = (<HTMLInputElement>document.getElementById(SiD)).innerHTML
      var DefIMLockValue = (<HTMLInputElement>document.getElementById(DiD)).innerHTML
      var DesIMLockValue = (<HTMLInputElement>document.getElementById(DfD)).innerHTML
      MatrixFieldMapping.push({ Source: SIMLockValue, NetSuite: DefIMLockValue, Default: DesIMLockValue, fieldType: 'Lock' })
    }
    for (var i = 0; i < this._MatriximportStdFieldMappingResult.length; i++) {
      var SiD = "IM_MatrixSrc_" + i;
      var DiD = "IM_MatrixDst_" + i;
      var DfD = "DFTMatrixStd_" + i;
      var S_e = (document.getElementById("" + SiD)) as HTMLSelectElement;
      var S_sel = S_e.selectedIndex;
      var S_opt = S_e.options[S_sel];
      var ExpValue = this.GetValueFromArray(this._StandFieldMappingDstJSON, ((<HTMLInputElement>document.getElementById(DiD)).value));
      var DataType = this.GetFieldTypeFromArray(this._StandFieldMappingDstJSON, ((<HTMLInputElement>document.getElementById(DiD)).value));
      var DestValue = (<HTMLInputElement>document.getElementById(DfD)).value;
      if (ExpValue != "" && (((<HTMLOptionElement>S_opt).value) != "" || DestValue != "")) {
        if ((this.NetSuiteFieldExists(MatrixFieldMapping, ExpValue)) == false)
          MatrixFieldMapping.push({ MarketPlace: ((<HTMLOptionElement>S_opt).value), label: ((<HTMLInputElement>document.getElementById(DiD)).value), NetSuite: ExpValue, Default: DestValue, fieldType: this._MatriximportStdFieldMappingResult[i].fieldType, DataType: DataType })
      }
    }
    for (var j = 0; j < this._KitimportLockFieldMappingResult.length; j++) {
      var SiD = "SpnKitImpLockSrc_" + j;
      var DiD = "SpnKitImpLockDes_" + j;
      var DfD = "SpnKitImLockDef_" + j;
      var SIMLockValue = (<HTMLInputElement>document.getElementById(SiD)).innerHTML
      var DefIMLockValue = (<HTMLInputElement>document.getElementById(DiD)).innerHTML
      var DesIMLockValue = (<HTMLInputElement>document.getElementById(DfD)).innerHTML
      KitFieldMapping.push({ Source: SIMLockValue, NetSuite: DefIMLockValue, Default: DesIMLockValue, fieldType: 'Lock' })
    }
    for (var i = 0; i < this._KitimportStdFieldMappingResult.length; i++) {
      var SiD = "IM_KitSrc_" + i;
      var DiD = "IM_KitDst_" + i;
      var DfD = "DFTKitStd_" + i;
      var S_e = (document.getElementById("" + SiD)) as HTMLSelectElement;
      var S_sel = S_e.selectedIndex;
      var S_opt = S_e.options[S_sel];
      var ExpValue = this.GetValueFromArray(this._StandFieldMappingDstJSON, ((<HTMLInputElement>document.getElementById(DiD)).value));
      var DataType = this.GetFieldTypeFromArray(this._StandFieldMappingDstJSON, ((<HTMLInputElement>document.getElementById(DiD)).value));
      var DestValue = (<HTMLInputElement>document.getElementById(DfD)).value;
      if (ExpValue != "" && (((<HTMLOptionElement>S_opt).value) != "" || DestValue != "")) {
        if ((this.NetSuiteFieldExists(KitFieldMapping, ExpValue)) == false)
          KitFieldMapping.push({ MarketPlace: ((<HTMLOptionElement>S_opt).value), label: ((<HTMLInputElement>document.getElementById(DiD)).value), NetSuite: ExpValue, Default: DestValue, fieldType: this._KitimportStdFieldMappingResult[i].fieldType, DataType: DataType })
      }
    }
    for (var j = 0; j < this._ItemGroupimportLockFieldMappingResult.length; j++) {
      var SiD = "SpnItemGroupImpLockSrc_" + j;
      var DiD = "SpnItemGroupImpLockDes_" + j;
      var DfD = "SpnItemGroupImLockDef_" + j;
      var SIMLockValue = (<HTMLInputElement>document.getElementById(SiD)).innerHTML
      var DefIMLockValue = (<HTMLInputElement>document.getElementById(DiD)).innerHTML
      var DesIMLockValue = (<HTMLInputElement>document.getElementById(DfD)).innerHTML
      ItemGroupFieldMapping.push({ Source: SIMLockValue, NetSuite: DefIMLockValue, Default: DesIMLockValue, fieldType: 'Lock' })
    }
    for (var i = 0; i < this._ItemGroupimportStdFieldMappingResult.length; i++) {
      var SiD = "IM_ItemGroupSrc_" + i;
      var DiD = "IM_ItemGroupDst_" + i;
      var DfD = "DFTItemGroupStd_" + i;
      var S_e = (document.getElementById("" + SiD)) as HTMLSelectElement;
      var S_sel = S_e.selectedIndex;
      var S_opt = S_e.options[S_sel];
      var ExpValue = this.GetValueFromArray(this._StandFieldMappingDstJSON, ((<HTMLInputElement>document.getElementById(DiD)).value));
      var DataType = this.GetFieldTypeFromArray(this._StandFieldMappingDstJSON, ((<HTMLInputElement>document.getElementById(DiD)).value));
      var DestValue = (<HTMLInputElement>document.getElementById(DfD)).value;
      if (ExpValue != "" && (((<HTMLOptionElement>S_opt).value) != "" || DestValue != "")) {
        if ((this.NetSuiteFieldExists(ItemGroupFieldMapping, ExpValue)) == false)
          ItemGroupFieldMapping.push({ MarketPlace: ((<HTMLOptionElement>S_opt).value), label: ((<HTMLInputElement>document.getElementById(DiD)).value), NetSuite: ExpValue, Default: DestValue, fieldType: 'Standard', DataType: DataType })
      }
    }
    this._updatedImportFieldMapping = [];
    this._updatedImportFieldMapping.push({ "StandartFieldMapping": StandartFieldMapping, "MatrixFieldMapping": MatrixFieldMapping, "KitFieldMapping": KitFieldMapping, "ItemGroupFieldMapping": ItemGroupFieldMapping })
    this.connectService.Srv_PostImportFieldMapping(this._updatedImportFieldMapping, "Item_ImportFieldsMapping", this._connectorID).subscribe((data: any) => {
      this.connectorsList();
    })
  }
  PostExportFieldMapping() {
    this._updatedExportFieldMapping = [];
    var StandartFieldMapping = []; var NonInventory = []; var ServiceFieldMapping = []; var MatrixFieldMapping = []; var KitFieldMapping = []; var ItemGroupFieldMapping = []; var ArrvariationFieldMapping = []; var ShopifyVariationFieldMapping = [];
    for (var k = 0; k < this._exportLockFieldMappingResult.length; k++) {
      var SiD = "SpnExpLockSrc_" + k;
      var DiD = "SpnExpLockDes_" + k;
      var DfD = "SpnExpLockDef_" + k;
      var SIMLockValue = (<HTMLInputElement>document.getElementById(SiD)).innerHTML
      var DefIMLockValue = (<HTMLInputElement>document.getElementById(DiD)).innerHTML
      var DesIMLockValue = (<HTMLInputElement>document.getElementById(DfD)).innerHTML
      StandartFieldMapping.push({ NetSuite: SIMLockValue, MarketPlace: DefIMLockValue, Default: DesIMLockValue, fieldType: 'Lock' })
    }

    for (var k = 0; k < this._exportLockServiceFieldMappingResult.length; k++) {
      var SiD = "SpnExpServiceLockSrc_" + k;
      var DiD = "SpnExpServiceLockDes_" + k;
      var DfD = "SpnExpServiceLockDef_" + k;
      var SIMLockValue = (<HTMLInputElement>document.getElementById(SiD)).innerHTML
      var DefIMLockValue = (<HTMLInputElement>document.getElementById(DiD)).innerHTML
      var DesIMLockValue = (<HTMLInputElement>document.getElementById(DfD)).innerHTML
      ServiceFieldMapping.push({ NetSuite: SIMLockValue, MarketPlace: DefIMLockValue, Default: DesIMLockValue, fieldType: 'Lock' })
    }

    for (var i = 0; i < this._exportStdFieldMappingResult.length; i++) {
      var SiD = "ExStdSrc_" + i;
      var DiD = "ExStdDst_" + i;
      var DfD = "DFTStdEx_" + i;
      var D_e = (document.getElementById("" + DiD)) as HTMLSelectElement;
      var D_sel = D_e.selectedIndex;
      var D_opt = D_e.options[D_sel];
      var SourceValue = this.GetValueFromArray(this._StandFieldMappingDstJSON, ((<HTMLInputElement>document.getElementById(SiD)).value));
      var DataType = this.GetFieldTypeFromArray(this._StandFieldMappingDstJSON, ((<HTMLInputElement>document.getElementById(DiD)).value));
      var DestValue = ((<HTMLOptionElement>D_opt).value)
      var DefaultValue = (<HTMLInputElement>document.getElementById(DfD)).value;
      if (DestValue != "" || (SourceValue != "" || DefaultValue != "")) {
        if ((this.MarketPlaceFieldExists(StandartFieldMapping, DestValue)) == false)
          StandartFieldMapping.push({ label: ((<HTMLInputElement>document.getElementById(SiD)).value), NetSuite: SourceValue, MarketPlace: DestValue, Default: DefaultValue, fieldType: this._exportStdFieldMappingResult[i].fieldType, DataType: DataType })
      }
    }

    for (var i = 0; i < this._exportNonStdFieldMappingResult.length; i++) {
      var SiD = "ExNonStdSrc_" + i;
      var DiD = "ExNonStdDst_" + i;
      var DfD = "DFTNonStdEx_" + i;
      var D_e = (document.getElementById("" + DiD)) as HTMLSelectElement;
      var D_sel = D_e.selectedIndex;
      var D_opt = D_e.options[D_sel];
      var SourceValue = this.GetValueFromArray(this._StandFieldMappingDstJSON, ((<HTMLInputElement>document.getElementById(SiD)).value));
      var DataType = this.GetFieldTypeFromArray(this._StandFieldMappingDstJSON, ((<HTMLInputElement>document.getElementById(DiD)).value));
      var DestValue = ((<HTMLOptionElement>D_opt).value)
      var DefaultValue = (<HTMLInputElement>document.getElementById(DfD)).value;
      if (DestValue != "" || (SourceValue != "" || DefaultValue != "")) {
        if ((this.MarketPlaceFieldExists(NonInventory, DestValue)) == false)
          NonInventory.push({ label: ((<HTMLInputElement>document.getElementById(SiD)).value), NetSuite: SourceValue, MarketPlace: DestValue, Default: DefaultValue, fieldType: this._exportNonStdFieldMappingResult[i].fieldType, DataType: DataType })
      }
    }

    for (var i = 0; i < this._exportServiceStdFieldMappingResult.length; i++) {
      var SiD = "ExServiceStdSrc_" + i;
      var DiD = "ExServiceStdDst_" + i;
      var DfD = "DFTServiceStdEx_" + i;
      var D_e = (document.getElementById("" + DiD)) as HTMLSelectElement;
      var D_sel = D_e.selectedIndex;
      var D_opt = D_e.options[D_sel];
      var SourceValue = this.GetValueFromArray(this._StandFieldMappingDstJSON, ((<HTMLInputElement>document.getElementById(SiD)).value));
      var DataType = this.GetFieldTypeFromArray(this._StandFieldMappingDstJSON, ((<HTMLInputElement>document.getElementById(DiD)).value));
      var DestValue = ((<HTMLOptionElement>D_opt).value)
      var DefaultValue = (<HTMLInputElement>document.getElementById(DfD)).value;
      if (DestValue != "" || (SourceValue != "" || DefaultValue != "")) {
        if ((this.MarketPlaceFieldExists(ServiceFieldMapping, DestValue)) == false)
          ServiceFieldMapping.push({ label: ((<HTMLInputElement>document.getElementById(SiD)).value), NetSuite: SourceValue, MarketPlace: DestValue, Default: DefaultValue, fieldType: this._exportStdFieldMappingResult[i].fieldType, DataType: DataType })
      }
    }

    for (var k = 0; k < this._exportMatrixLockFieldMappingResult.length; k++) {
      var SiD = "SpnMatrixLockSrc_" + k;
      var DiD = "SpnMatrixExpLockDes_" + k;
      var DfD = "SpnMatrixExpLockDef_" + k;
      var SIMLockValue = (<HTMLInputElement>document.getElementById(SiD)).innerHTML
      var DefIMLockValue = (<HTMLInputElement>document.getElementById(DiD)).innerHTML
      var DesIMLockValue = (<HTMLInputElement>document.getElementById(DfD)).innerHTML
      MatrixFieldMapping.push({ NetSuite: SIMLockValue, MarketPlace: DefIMLockValue, Default: DesIMLockValue, fieldType: 'Lock' })
    }
    for (var i = 0; i < this._exportMatrixStdFieldMappingResult.length; i++) {
      var SiD = "ExMatrixStdSrc_" + i;
      var DiD = "ExMatrixStdDst_" + i;
      var DfD = "DFTMatrixStdEx_" + i;
      var D_e = (document.getElementById("" + DiD)) as HTMLSelectElement;
      var D_sel = D_e.selectedIndex;
      var D_opt = D_e.options[D_sel];
      var SourceValue = this.GetValueFromArray(this._StandFieldMappingDstJSON, ((<HTMLInputElement>document.getElementById(SiD)).value));
      var DataType = this.GetFieldTypeFromArray(this._StandFieldMappingDstJSON, ((<HTMLInputElement>document.getElementById(DiD)).value));
      var DestValue = ((<HTMLOptionElement>D_opt).value)
      var DefaultValue = (<HTMLInputElement>document.getElementById(DfD)).value;
      if (DestValue != "" || (SourceValue != "" || DefaultValue != "")) {
        if ((this.MarketPlaceFieldExists(MatrixFieldMapping, DestValue)) == false)
          MatrixFieldMapping.push({ label: ((<HTMLInputElement>document.getElementById(SiD)).value), NetSuite: SourceValue, MarketPlace: DestValue, Default: DefaultValue, fieldType: this._exportMatrixStdFieldMappingResult[i].fieldType, DataType: DataType })
      }
    }

    for (var k = 0; k < this._exportKitLockFieldMappingResult.length; k++) {
      var SiD = "SpnKitExpLockSrc_" + k;
      var DiD = "SpnKitExpLockDes_" + k;
      var DfD = "SpnKitExpLockDef_" + k;
      var SIMLockValue = (<HTMLInputElement>document.getElementById(SiD)).innerHTML
      var DefIMLockValue = (<HTMLInputElement>document.getElementById(DiD)).innerHTML
      var DesIMLockValue = (<HTMLInputElement>document.getElementById(DfD)).innerHTML
      KitFieldMapping.push({ NetSuite: SIMLockValue, MarketPlace: DefIMLockValue, Default: DesIMLockValue, fieldType: 'Lock' })
    }
    for (var i = 0; i < this._exportKitStdFieldMappingResult.length; i++) {
      var SiD = "ExKitStdSrc_" + i;
      var DiD = "ExKitStdDst_" + i;
      var DfD = "DFTKitStdEx_" + i;
      var D_e = (document.getElementById("" + DiD)) as HTMLSelectElement;
      var D_sel = D_e.selectedIndex;
      var D_opt = D_e.options[D_sel];
      var SourceValue = (this.GetValueFromArray(this._StandKitFieldMappingSrcJSON, ((<HTMLInputElement>document.getElementById(SiD)).value)));
      var DataType = this.GetFieldTypeFromArray(this._StandKitFieldMappingSrcJSON, ((<HTMLInputElement>document.getElementById(DiD)).value));
      var DestValue = ((<HTMLOptionElement>D_opt).value)
      var DefaultValue = (<HTMLInputElement>document.getElementById(DfD)).value;
      if (DestValue != "" || (SourceValue != "" || DefaultValue != "")) {
        if ((this.MarketPlaceFieldExists(KitFieldMapping, DestValue)) == false)
          KitFieldMapping.push({ label: ((<HTMLInputElement>document.getElementById(SiD)).value), NetSuite: SourceValue, MarketPlace: DestValue, Default: DefaultValue, fieldType: this._exportKitStdFieldMappingResult[i].fieldType, DataType: DataType })
      }
    }

    for (var k = 0; k < this._exportItemGroupLockFieldMappingResult.length; k++) {
      var SiD = "SpnIGExpLockSrc_" + k;
      var DiD = "SpnIGExpLockDes_" + k;
      var DfD = "SpnIGExpLockDef_" + k;
      var SIMLockValue = (<HTMLInputElement>document.getElementById(SiD)).innerHTML
      var DefIMLockValue = (<HTMLInputElement>document.getElementById(DiD)).innerHTML
      var DesIMLockValue = (<HTMLInputElement>document.getElementById(DfD)).innerHTML
      ItemGroupFieldMapping.push({ NetSuite: SIMLockValue, MarketPlace: DefIMLockValue, Default: DesIMLockValue, fieldType: 'Lock' })
    }
    for (var i = 0; i < this._exportLItemGroupStdFieldMappingResult.length; i++) {
      var SiD = "ExIGStdSrc_" + i;
      var DiD = "ExIGStdDst_" + i;
      var DfD = "DFTIGStdEx_" + i;
      var D_e = (document.getElementById("" + DiD)) as HTMLSelectElement;
      var D_sel = D_e.selectedIndex;
      var D_opt = D_e.options[D_sel];
      var SourceValue = this.GetValueFromArray(this._StandItemGroupFieldMappingSrcJSON, ((<HTMLInputElement>document.getElementById(SiD)).value));
      var DataType = this.GetFieldTypeFromArray(this._StandItemGroupFieldMappingSrcJSON, ((<HTMLInputElement>document.getElementById(DiD)).value));
      var DestValue = ((<HTMLOptionElement>D_opt).value)
      var DefaultValue = (<HTMLInputElement>document.getElementById(DfD)).value;
      if (DestValue != "" || (SourceValue != "" || DefaultValue != "")) {
        if ((this.MarketPlaceFieldExists(ItemGroupFieldMapping, DestValue)) == false)
          ItemGroupFieldMapping.push({ label: ((<HTMLInputElement>document.getElementById(SiD)).value), NetSuite: SourceValue, MarketPlace: DestValue, Default: DefaultValue, fieldType: this._exportLItemGroupStdFieldMappingResult[i].fieldType, DataType: DataType })
      }
    }

    if (this._isEbay == true) {
      StandartFieldMapping = [{ PriceIncluded: this._inventoryPriceIncluded, ImageIncluded: this._inventoryImageIncluded, InventoryIncluded: this._inventoryInvenIncluded, FieldMapping: StandartFieldMapping }];
      NonInventory = [{ PriceIncluded: this._NoninventoryPriceIncluded, ImageIncluded: this._NoninventoryImageIncluded, InventoryIncluded: this._NoninventoryInvenIncluded, FieldMapping: NonInventory }];
      ServiceFieldMapping = [{ PriceIncluded: this._ServicePriceIncluded, ImageIncluded: this._ServiceImageIncluded, InventoryIncluded: this._ServiceInvenIncluded, FieldMapping: ServiceFieldMapping }];
      KitFieldMapping = [{ PriceIncluded: this._KitPriceIncluded, ImageIncluded: this._KitImageIncluded, InventoryIncluded: this._KitInvenIncluded, FieldMapping: KitFieldMapping }];
      ItemGroupFieldMapping = [{ PriceIncluded: this._ItemGroupPriceIncluded, ImageIncluded: this._ItemGroupImageIncluded, InventoryIncluded: this._ItemGroupInvenIncluded, FieldMapping: ItemGroupFieldMapping }];
      try {
        let ItemTypevalue = (<HTMLSelectElement>document.getElementById('_ebayMatrixItemType')).value;
        MatrixFieldMapping = [{ ItemType: ItemTypevalue, PriceIncluded: this._MatrixPriceIncluded, ImageIncluded: this._MatrixImageIncluded, InventoryIncluded: this._MatrixInvenIncluded, FieldMapping: MatrixFieldMapping }];
      }
      catch (ex) {

      }
    }

    if (this._isShopify) {
      for (var i = 0; i < this._exportShopifyVariationsStdFieldMappingResult.length; i++) {
        var SiD = "ExVCStdSrc_" + i;
        var DiD = "ExVCStdDst_" + i;
        var DfD = "DFTVCStdEx_" + i;
        var D_e = (document.getElementById("" + DiD)) as HTMLSelectElement;
        var D_sel = D_e.selectedIndex;
        var D_opt = D_e.options[D_sel];
        var SourceValue = this.GetValueFromArray(this._StandFieldMappingDstJSON, ((<HTMLInputElement>document.getElementById(SiD)).value));
        var DataType = this.GetFieldTypeFromArray(this._StandFieldMappingDstJSON, ((<HTMLInputElement>document.getElementById(DiD)).value));
        var DestValue = ((<HTMLOptionElement>D_opt).value)
        var DefaultValue = (<HTMLInputElement>document.getElementById(DfD)).value;
        if (DestValue != "" || (SourceValue != "" || DefaultValue != "")) {
          if ((this.MarketPlaceFieldExists(ShopifyVariationFieldMapping, DestValue)) == false)
            ShopifyVariationFieldMapping.push({ label: ((<HTMLInputElement>document.getElementById(SiD)).value), NetSuite: SourceValue, MarketPlace: DestValue, Default: DefaultValue, fieldType: this._exportShopifyVariationsStdFieldMappingResult[i].fieldType, DataType: DataType })
        }
      }
    }

    if (this._isWooCommerce) {
      for (var v = 0; v < this._variationFieldMapping.length; v++) {
        var SvD = "variSrc_" + v;
        var DvD = "variDes_" + v;
        var variationsSRValue = ((<HTMLInputElement>document.getElementById(SvD)).value)
        var variationDSValue = ((<HTMLInputElement>document.getElementById(DvD)).value)
        if (variationsSRValue != "" && variationDSValue != "") {
          ArrvariationFieldMapping.push({ NetSuite: variationsSRValue, MarketPlace: variationDSValue })
        }
      }
    }
    var Inv_MetaMapping = ((<HTMLInputElement>document.getElementById("txtInvMetaMapping")).value);
    var Matrix_MetaMapping = ((<HTMLInputElement>document.getElementById("txtMatrixMetaMapping")).value);
    var Kit_MetaMapping = ((<HTMLInputElement>document.getElementById("txtKitMetaMapping")).value);
    var Non_MetaMapping = ((<HTMLInputElement>document.getElementById("txtNonInvMetaMapping")).value);
    this._updatedExportFieldMapping = [];

    this._updatedExportFieldMapping.push({ "StandartFieldMapping": StandartFieldMapping, "ServiceFiledMapping": ServiceFieldMapping, "MatrixFieldMapping": MatrixFieldMapping, "KitFieldMapping": KitFieldMapping, "ItemGroupFieldMapping": ItemGroupFieldMapping, "VariationFiledMapping": ArrvariationFieldMapping, "ShopifyVariationMapping": ShopifyVariationFieldMapping, "NonInventoryMapping": NonInventory, Inv_MetaMap: Inv_MetaMapping, Kit_MetaMap: Kit_MetaMapping, Matrix_MetaMap: Matrix_MetaMapping, Non_MetaMapping: Non_MetaMapping })
    this.connectService.Srv_PostImportFieldMapping(this._updatedExportFieldMapping, "Item_ExportFieldsMapping", this._connectorID).subscribe((data: any) => {
      this.connectorsList();
    })
  }

  PostPriceImportFieldMapping() {
    var PriceImportFieldMappingJson = [];
    var S_e = (document.getElementById("_ddlPricelevelimport")) as HTMLSelectElement;
    var S_sel = S_e.selectedIndex;
    var S_opt = S_e.options[S_sel];
    var C_e = (document.getElementById("_ddlCurrencyimport")) as HTMLSelectElement;
    var C_sel = C_e.selectedIndex;
    var C_opt = S_e.options[C_sel];
    PriceImportFieldMappingJson.push({ PriceLevel: ((<HTMLOptionElement>S_opt).value), Currency: ((<HTMLOptionElement>C_opt).value) });
    this.connectService.Srv_PostImportFieldMapping(PriceImportFieldMappingJson, "price_ImportFieldsMapping", this._connectorID).subscribe((data: any) => {

    })
  }
  PostPriceExportFieldMapping() {
    var PriceExportFieldMappingJson = [];
    var S_e = (document.getElementById("_ddlPricelevelExport")) as HTMLSelectElement;
    var S_sel = S_e.selectedIndex;
    var S_opt = S_e.options[S_sel];
    var C_e = (document.getElementById("_ddlCurrencyExport")) as HTMLSelectElement;
    var C_sel = C_e.selectedIndex;
    var C_opt = C_e.options[C_sel];
    var ComparePrice = ''; var SalePriceLevel = '';

    var eCS_e = (document.getElementById("_ddlexportTypeprice")) as HTMLSelectElement;
    var eCS_sel = eCS_e.selectedIndex;
    var eCS_opt = eCS_e.options[eCS_sel];
    var Ex_exportType = ((<HTMLOptionElement>eCS_opt).value);

    // PriceExport_exportType
    if (this._isShopify) {
      var CS_e = (document.getElementById("_ddlComparePricelevelExport")) as HTMLSelectElement;
      var CS_sel = CS_e.selectedIndex;
      var CS_opt = CS_e.options[CS_sel];
      ComparePrice = ((<HTMLOptionElement>CS_opt).value);
    }
    if (this._isWooCommerce) {
      var WS_e = (document.getElementById("_ddlSalePricelevelExport")) as HTMLSelectElement;
      var WS_sel = WS_e.selectedIndex;
      var WS_opt = WS_e.options[WS_sel];
      SalePriceLevel = ((<HTMLOptionElement>WS_opt).value);
    }
    var SC_e = (document.getElementById("PriceExport_SavedSeach")) as HTMLSelectElement;
    var SC_sel = SC_e.selectedIndex;
    var SC_opt = SC_e.options[SC_sel];

    PriceExportFieldMappingJson.push({ PriceLevel: ((<HTMLOptionElement>S_opt).value), Currency: ((<HTMLOptionElement>C_opt).value), SavedSearch: ((<HTMLOptionElement>SC_opt).value), ComparePrice: ComparePrice, SalePrice: SalePriceLevel, exportType: Ex_exportType, IsBasePrice: this._isBasePrice });
    this.connectService.Srv_PostImportFieldMapping(PriceExportFieldMappingJson, "price_ExportFieldsMapping", this._connectorID).subscribe((data: any) => {

    })
  }

  PostIISchedulerJSON() {
    var StandardItemImport = [];
    var MatrixItemImport = [];
    var KitItemImport = [];
    var ItemGroupItemImport = [];
    StandardItemImport.push({ "Date": (<HTMLInputElement>document.getElementById("_IMIS_ScheduleFrom")).value, "Frequency": this.GetSelectedValueFromId("_IMIS_Frequency"), "Time": this.GetSelectedValueFromId("_IMIS_Time"), "Days": this.GetSelectedMultipleValuesFromID("_IMIS_Days") })
    MatrixItemImport.push({ "Date": (<HTMLInputElement>document.getElementById("_MIMIS_ScheduleFrom")).value, "Frequency": this.GetSelectedValueFromId("_MIMIS_Frequency"), "Time": this.GetSelectedValueFromId("_MIMIS_Time"), "Days": this.GetSelectedMultipleValuesFromID("_MIMIS_Days"), Status: this._Import_Matrix_offOn })
    KitItemImport.push({ "Date": (<HTMLInputElement>document.getElementById("_KIMIS_ScheduleFrom")).value, "Frequency": this.GetSelectedValueFromId("_KIMIS_Frequency"), "Time": this.GetSelectedValueFromId("_KIMIS_Time"), "Days": this.GetSelectedMultipleValuesFromID("_KIMIS_Days"), Status: this._Import_Kit_offOn })
    ItemGroupItemImport.push({ "Date": (<HTMLInputElement>document.getElementById("_IGIMIS_ScheduleFrom")).value, "Frequency": this.GetSelectedValueFromId("_IGIMIS_Frequency"), "Time": this.GetSelectedValueFromId("_IGIMIS_Time"), "Days": this.GetSelectedMultipleValuesFromID("_IGIMIS_Days"), Status: this._Import_ItemGroup_offOn })
    var ItemImportSchedulerJson = ({ "StandardItemImport": StandardItemImport, "MatrixItemImport": MatrixItemImport, "KitItemImport": KitItemImport, "ItemGroupItemImport": ItemGroupItemImport });
    this.connectService.Srv_PostImportFieldMapping(ItemImportSchedulerJson, "Item_ImportScheduletime", this._connectorID).subscribe((data: any) => {

    })
  }
  PostIESchedulerJSON() {
    var StandardItemImport = [];
    var NonInventoryExport = [];
    var ServiceItemExport = [];
    var MatrixItemImport = [];
    var KitItemImport = [];
    var ItemGroupItemImport = [];
    var ShopifyVariationItemImport = [];
    StandardItemImport.push({ "Date": (<HTMLInputElement>document.getElementById("_IEIS_ScheduleFrom")).value, "Frequency": this.GetSelectedValueFromId("_IEIS_Frequency"), "Time": this.GetSelectedValueFromId("_IEIS_Time"), "Days": this.GetSelectedMultipleValuesFromID("_IEIS_Days") })
    NonInventoryExport.push({ "Date": (<HTMLInputElement>document.getElementById("_IEIS_Non_ScheduleFrom")).value, "Frequency": this.GetSelectedValueFromId("_IEIS_Non_Frequency"), "Time": this.GetSelectedValueFromId("_IEIS_Non_Time"), "Days": this.GetSelectedMultipleValuesFromID("_IEIS_Non_Days"), Status: this._Export_nonInventory_offOn })
    ServiceItemExport.push({ "Date": (<HTMLInputElement>document.getElementById("_ServiceIEIS_ScheduleFrom")).value, "Frequency": this.GetSelectedValueFromId("_ServiceIEIS_Frequency"), "Time": this.GetSelectedValueFromId("_ServiceIEIS_Time"), "Days": this.GetSelectedMultipleValuesFromID("_ServiceIEIS_Days"), Status: this._Export_Service_offon })
    MatrixItemImport.push({ "Date": (<HTMLInputElement>document.getElementById("_MIEIS_ScheduleFrom")).value, "Frequency": this.GetSelectedValueFromId("_MIEIS_Frequency"), "Time": this.GetSelectedValueFromId("_MIEIS_Time"), "Days": this.GetSelectedMultipleValuesFromID("_MIEIS_Days"), Status: this._Export_Matrix_offOn })
    KitItemImport.push({ "Date": (<HTMLInputElement>document.getElementById("_KIEIS_ScheduleFrom")).value, "Frequency": this.GetSelectedValueFromId("_KIEIS_Frequency"), "Time": this.GetSelectedValueFromId("_KIEIS_Time"), "Days": this.GetSelectedMultipleValuesFromID("_KIEIS_Days"), Status: this._Export_Kit_offOn })
    ItemGroupItemImport.push({ "Date": (<HTMLInputElement>document.getElementById("_IGIEIS_ScheduleFrom")).value, "Frequency": this.GetSelectedValueFromId("_IGIEIS_Frequency"), "Time": this.GetSelectedValueFromId("_IGIEIS_Time"), "Days": this.GetSelectedMultipleValuesFromID("_IGIEIS_Days"), Status: this._Export_ItemGroup_offOn })
    ShopifyVariationItemImport.push({ "Date": (<HTMLInputElement>document.getElementById("_VS_ScheduleFrom")).value, "Frequency": this.GetSelectedValueFromId("_VS_Frequency"), "Time": this.GetSelectedValueFromId("_VS_Time"), "Days": this.GetSelectedMultipleValuesFromID("_VS_Days"), Status: this._Export_VS_offOn })
    var ItemImportSchedulerJson = ({ "StandardItemImport": StandardItemImport, "NonInventoryExport": NonInventoryExport, "MatrixItemImport": MatrixItemImport, "KitItemImport": KitItemImport, "ItemGroupItemImport": ItemGroupItemImport, "ServiceItemExport": ServiceItemExport, "ShopifyVariationItemImport": ShopifyVariationItemImport });
    this.connectService.Srv_PostImportFieldMapping(ItemImportSchedulerJson, "Item_ExportScheduletime", this._connectorID).subscribe((data: any) => {

    })
  }
  PostPISchedulerJSON() {
    var PISchedulerJson = [];
    PISchedulerJson.push({ "Date": (<HTMLInputElement>document.getElementById("_pi_ScheduleFrom")).value, "Frequency": this.GetSelectedValueFromId("_pi_Frequency"), "Time": this.GetSelectedValueFromId("_pi_Time"), "Days": this.GetSelectedMultipleValuesFromID("_pi_Days") })
    this.connectService.Srv_PostImportFieldMapping(PISchedulerJson, "Price_ImportScheduletime", this._connectorID).subscribe((data: any) => {

    })
  }
  PostPESchedulerJSON() {
    var PESchedulerJson = [];
    PESchedulerJson.push({ "Date": (<HTMLInputElement>document.getElementById("_pe_ScheduleFrom")).value, "Frequency": this.GetSelectedValueFromId("_pe_Frequency"), "Time": this.GetSelectedValueFromId("_pe_Time"), "Days": this.GetSelectedMultipleValuesFromID("_pe_Days") })
    this.connectService.Srv_PostImportFieldMapping(PESchedulerJson, "Price_ExportScheduletime", this._connectorID).subscribe((data: any) => {

    })
  }

  PostCISchedulerJSON() {
    var Categories_Scheduletime = [];
    Categories_Scheduletime.push({ "Date": (<HTMLInputElement>document.getElementById("_ci_ScheduleFrom")).value, "Frequency": this.GetSelectedValueFromId("_ci_Frequency"), "Time": this.GetSelectedValueFromId("_ci_Time"), "Days": this.GetSelectedMultipleValuesFromID("_ci_Days") })
    this.connectService.Srv_PostImportFieldMapping(Categories_Scheduletime, "Categories_Scheduletime", this._connectorID).subscribe((data: any) => {

    })
  }
  PostAISchedulerJSON() {
    var ATSchedulerJson = [];
    ATSchedulerJson.push({ "Date": (<HTMLInputElement>document.getElementById("_ai_ScheduleFrom")).value, "Frequency": this.GetSelectedValueFromId("_ai_Frequency"), "Time": this.GetSelectedValueFromId("_ai_Time"), "Days": this.GetSelectedMultipleValuesFromID("_ai_Days") })
    this.connectService.Srv_PostImportFieldMapping(ATSchedulerJson, "Attributes_Scheduletime", this._connectorID).subscribe((data: any) => {

    })
  }
  PostTISchedulerJSON() {
    var TagSchedulerJson = [];
    TagSchedulerJson.push({ "Date": (<HTMLInputElement>document.getElementById("_tag_ScheduleFrom")).value, "Frequency": this.GetSelectedValueFromId("_tag_Frequency"), "Time": this.GetSelectedValueFromId("_tag_Time"), "Days": this.GetSelectedMultipleValuesFromID("_tag_Days") })
    this.connectService.Srv_PostImportFieldMapping(TagSchedulerJson, "Tags_Scheduletime", this._connectorID).subscribe((data: any) => {

    })
  }
  PostTermsSchedulerJSON() {
    var TermsSchedulerJson = [];
    TermsSchedulerJson.push({ "Date": (<HTMLInputElement>document.getElementById("_terms_ScheduleFrom")).value, "Frequency": this.GetSelectedValueFromId("_terms_Frequency"), "Time": this.GetSelectedValueFromId("_terms_Time"), "Days": this.GetSelectedMultipleValuesFromID("_terms_Days") })
    this.connectService.Srv_PostImportFieldMapping(TermsSchedulerJson, "Terms_Scheduletime", this._connectorID).subscribe((data: any) => {

    })
  }

  RemoveDivById(divString: any, i) {
    if (divString.length - 1 == i) {

    }
    else {
      var ans = confirm('Are you sure you want to remove this field?');
      if (ans) {
        divString.splice(i, 1);
      }
    }
  }
  GetSelectedValueFromId(ID) {
    try {
      var D_e = (document.getElementById(ID)) as HTMLSelectElement;
      var D_sel = D_e.selectedIndex;
      var D_opt = D_e.options[D_sel];
      var DestValue = ((<HTMLOptionElement>D_opt).value)
      return DestValue;
    }
    catch (e) {
      return "";
    }
  }
  GetSelectedMultipleValuesFromID(ID) {
    let selectElement = (document.getElementById(ID)) as HTMLSelectElement;
    let selectedValues = Array.from(selectElement.selectedOptions).map(option => option.value);
    return ((selectedValues).toString());
  }
  GetValueFromArray(JsonArray, Value) {
    if (Value) {
      var index = -1;
      var filteredObj = JsonArray.find(function (item, i) {
        if ((item.fieldName).toUpperCase() === Value.toUpperCase()) {
          index = i;
        }
      });
      if (index == -1)
        return Value;
      else
        return JsonArray[index].scriptId;
    }
    else {
      return "";
    }
  }

  UpdateSyncOffOnImport(VariableName) {
    VariableName = (VariableName);
    this.PostIISchedulerJSON()
  }
  UpdateSyncOffOnExport(VariableName) {
    VariableName = (VariableName);
    this.PostIESchedulerJSON()
  }

  PostSavedSearchJSON() {
    var SavedSearchesJSON = {};
    SavedSearchesJSON =
    {
      "ItemExport": this.GetSelectedValueFromId("IE_SavedSeach"),
      "MatrixItemExport": this.GetSelectedValueFromId("MIE_SavedSeach"),
      "KitItemExport": this.GetSelectedValueFromId("KIE_SavedSeach"),
      "ItemGroupExport": this.GetSelectedValueFromId("IGE_SavedSeach"),
      "PriceExport": this.GetSelectedValueFromId("PE_SavedSeach"),
    };
    this.connectService.Srv_PostImportFieldMapping(SavedSearchesJSON, "SavedSearchJSON", this._connectorID).subscribe((data: any) => {

    })
  }

  CheckInArray(Arr, Value) {
    Arr = Arr.map(String);
    if (Arr.indexOf(Value.toString()) > -1) {
      return true;
    }
    return false;
  }
  RunIntegrator(ApiMethodName, RunningMethodName, ProcessingName) {
    this['' + RunningMethodName] = true;
    this['' + ProcessingName] = 'Initiated...';
    setTimeout(() => {
      setTimeout(() => {
        this['' + RunningMethodName] = false;
      }, 3000)
      this['' + ProcessingName] = 'Processing...';
    }, 3000)
    this.connectService.RunIntegrator(ApiMethodName, this._connectorID).subscribe((data: any) => {

    })
  }
  NetSuiteFieldExists(Arr, value) {
    if (value != "") {
      return Arr.some(function (el) {
        var status = (el.NetSuite === value);
        return status;
      });
    }
    else {
      return false;
    }
  }
  MarketPlaceFieldExists(Arr, value) {
    if (value != "") {
      return Arr.some(function (el) {
        var status = (el.MarketPlace === value);
        return status;
      });
    }
    else {
      return false;
    }
  }
  _specArrayName: any;
  _specArrayIndex: any;
  ItemSpecPopupInitialize(Arr, index) {
    try {
      this._specArrayName = Arr;
      this._specArrayIndex = index;
    }
    catch (exception) {

    }
  }
  onchangeSpec() {
    var index_id = (this._nameValueList.length) - 1;
    var Value = "StdSpecVal" + index_id;
    var Default = "StdSpecDef" + index_id;

    var ValueVal = (<HTMLInputElement>document.getElementById(Value)).value;
    var DefVal = (<HTMLInputElement>document.getElementById(Default)).value;
    if (ValueVal != "" || DefVal != "") {
      this._nameValueList.push({ Value: '', Default: '' });
    }
  }
  _NameValueMappingList: Array<any> = [];
  onSubmitItemSpec(Arr) {
    this.closeItemSpecModal.nativeElement.click();
    for (var k = 0; k < this._nameValueList.length; k++) {
      var DiD = "StdSpecVal" + k;
      var DfD = "StdSpecDef" + k;
      var ValueVal = (<HTMLInputElement>document.getElementById(DiD)).value;
      var DefVal = (<HTMLInputElement>document.getElementById(DfD)).value;
      this._NameValueMappingList.push({ Value: ValueVal, Default: DefVal })
    }
    var SpecValue = (<HTMLInputElement>document.getElementById("SpecValue")).value;
    var SpecDef = (<HTMLInputElement>document.getElementById("SpecDefault")).value;
    var _SpecMapping = { SpecValue: SpecValue, SpecDef: SpecDef, NameValueMapping: this._NameValueMappingList }
    this._specArrayName[this._specArrayIndex].ItemSpecMaping = _SpecMapping;
  }
  EmptyConnectorVariables() {
    this._MarketPlaceFieldMapping.length = 0;
    this._EXP_MarketPlaceFieldMapping.length = 0;
    this._importLockFieldMappingResult.length = 0;
    this._importStdFieldMappingResult.length = 0;
    this._MatriximportLockFieldMappingResult.length = 0;
    this._MatriximportStdFieldMappingResult.length = 0;
    this._KitimportLockFieldMappingResult.length = 0;
    this._KitimportStdFieldMappingResult.length = 0;
    this._ItemGroupimportLockFieldMappingResult.length = 0;
    this._ItemGroupimportStdFieldMappingResult.length = 0;
    this._exportLockFieldMappingResult.length = 0;
    this._exportStdFieldMappingResult.length = 0;
    this._exportNonStdFieldMappingResult.length = 0;
    this._exportLockServiceFieldMappingResult.length = 0;
    this._exportServiceStdFieldMappingResult.length = 0;
    this._exportMatrixLockFieldMappingResult.length = 0;
    this._exportMatrixStdFieldMappingResult.length = 0;
    this._exportKitLockFieldMappingResult.length = 0;
    this._exportKitStdFieldMappingResult.length = 0;
    this._exportItemGroupLockFieldMappingResult.length = 0;
    this._exportLItemGroupStdFieldMappingResult.length = 0;
    this._importStdFieldMappingResult.length = 0;
    this._MatriximportStdFieldMappingResult.length = 0;
    this._KitimportStdFieldMappingResult.length = 0;
    this._ItemGroupimportStdFieldMappingResult.length = 0;
    this._exportStdFieldMappingResult.length = 0;
    this._exportMatrixStdFieldMappingResult.length = 0;
    this._exportKitStdFieldMappingResult.length = 0;
    this._exportShopifyVariationsStdFieldMappingResult.length = 0;
    this._variationFieldMapping.length = 0;
  }
  GetFieldTypeFromArray(JsonArray, Value) {
    if (Value) {
      var index = -1;
      var filteredObj = JsonArray.find(function (item, i) {
        if ((item.fieldName).toUpperCase() === Value.toUpperCase()) {
          index = i;
        }
      });
      if (index == -1) {
        return Value;
      }
      else {
        return JsonArray[index].fieldType;
      }
    }
    else {
      return "";
    }
  }

  RunSingleItemExport(Itemtype, fieldID) {
    this.blockUI.start('Requesting is processing.');
    var Erp_SKU = (<HTMLInputElement>document.getElementById(fieldID)).value;
    this.connectService.RunSingleItem(Itemtype, this._connectorID, Erp_SKU).subscribe((data: any) => {
      console.clear();
      console.log(data);
    })
    setTimeout(() => {
      this.blockUI.stop();
      location.reload();
    }, 3000);
  }
}