import { Component, OnInit, EventEmitter, Output, Inject, ViewChild } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MapDataComponent } from '../map-data/map-data.component';
import { HttpClient } from '@angular/common/http';
import { ConnectorsService } from '../servicess/connectors.service';
import { NgxPaginationModule } from 'ngx-pagination';
import { BlockUI, NgBlockUI } from 'ng-block-ui';

interface PeriodicElement {
  name: string;
  position: number;
  weight: number;
  symbol: string;
}

@Component({
  selector: 'app-errors',
  templateUrl: './errors.component.html',
  styleUrls: ['./errors.component.css']
})

export class ErrorsComponent implements OnInit {
  dataSourceOne: MatTableDataSource<PeriodicElement>;
  displayedColumnsOne: string[] = ['Process', 'StartTime', 'Processed', 'Failed'];
  @ViewChild('TableOnePaginator') tableOnePaginator: MatPaginator;
  @ViewChild('TableOneSort') tableOneSort: MatSort;
  @BlockUI() blockUI: NgBlockUI;

  dataSourceTwo: MatTableDataSource<PeriodicElement>;
  displayedColumnsTwo: string[] = ['Date', 'Process', 'ErrorMessage', 'Run'];
  @ViewChild('TableTwoPaginator') tableTwoPaginator: MatPaginator;
  @ViewChild('TableTwoSort') tableTwoSort: MatSort;
  constructor(private router: ActivatedRoute, public dialog: MatDialog, private route: Router, private http: HttpClient, private connectSer: ConnectorsService) {
    this.dataSourceOne = new MatTableDataSource;
    this.dataSourceTwo = new MatTableDataSource;
  }
  _AuditLogList: any = [];
  _ErrorLogList: any = [];
  _jsonErrorList: any = [];
  p: any;
  _connectorID: any = "";
  _auditlogtabroutelink: any = "";
  _errorlogtabroutelink: any = "";

  ngOnInit() {
    const parameters = window.location.href;
    var pieces = parameters.split(/[\s/]+/);
    this._connectorID = (pieces[pieces.length - 1]);
    this._auditlogtabroutelink = "/Auditrecords/" + this._connectorID;
    this._errorlogtabroutelink = "/Errorrecords/" + this._connectorID;

    localStorage.setItem('ActiveLiName', 'Errors');
    this.AuditList();
    this.ErrorLogList();
  }

  applyFilterOne(filterValue: string) {
    this.dataSourceOne.filter = filterValue.trim().toLowerCase();
  }

  applyFilterTwo(filterValue: string) {
    this.dataSourceTwo.filter = filterValue.trim().toLowerCase();
  }

  AuditList() {
    this.blockUI.start('Loading...');
    this.connectSer.GetAuditLog(this._connectorID).subscribe((data: any) => {
      if (data.status == "Success") {
        this.blockUI.stop();
        this.dataSourceOne.data = data.results;
        this.dataSourceOne.paginator = this.tableOnePaginator;
        this.dataSourceOne.sort = this.tableOneSort;
      }
    })
  }

  ErrorLogList() {
    this.connectSer.GetErrorLog(this._connectorID).subscribe((data: any) => {
      if (data.status == "Success") {
        this.dataSourceTwo.data = data.results;
        this.dataSourceTwo.paginator = this.tableTwoPaginator;
        this.dataSourceTwo.sort = this.tableTwoSort;
      }
    })
  }

  GetErrorLog(errorData) {
    this._jsonErrorList = JSON.parse(errorData);
  }

  RetryError(Pk_errorId) {
    this.blockUI.start('Background process will start...');
    this.connectSer.OrderReprocess(Pk_errorId, this._connectorID).subscribe((data: any) => {
      if (data.status == "Success") {
        this.blockUI.stop();
        this.blockUI.start('Record reprocess success');
        this.ErrorLogList();
      }
      if (data.status == "Error") {
        this.blockUI.stop();
        this.blockUI.start('Record reprocess failed');
      }
      setTimeout(() => {
        this.blockUI.stop();
      }, 3000);
    })
  }

  checkProcess(val) {
    if (val != "" && val != null && val != undefined) {
      if ((val.toLowerCase().includes('order')) && (val.toLowerCase().includes('import')))
        return true;
      else
        return false;
    }
    else
      return true;
  }
  checkSourceFile(val) {
    if (val == '' || val == null)
      return false;
    else
      return true;
  }
  // downloadfile(url) {


  //   // var datatext = "";
  //   // var request = new XMLHttpRequest();
  //   // request.open('GET', 'https://flexicon.netscoretech.com:1122//AcumaticaSFTPFiles/Lowes%20Inventory%20Feed_20%204%202024%205%205.txt', true);
  //   // request.send(null);
  //   // request.onreadystatechange = function () {
  //   //   if (request.readyState === 4 && request.status === 200) {
  //   //     var type = request.getResponseHeader('Content-Type');
  //   //     if (type.indexOf("text") !== 1) {
  //   //       datatext = "request.responseText";
  //   //       console.clear();
  //   //       console.log(datatext);
  //   //     }
  //   //   }
  //   // }
  //   // var element = document.createElement('a');
  //   // element.setAttribute('href',
  //   //   'data:text/plain;charset=utf-8, '
  //   //   + encodeURIComponent(datatext));
  //   // element.setAttribute('download', "file");
  //   // document.body.appendChild(element);
  //   // element.click();
  //   // document.body.removeChild(element);
  // }

}

export interface AuditLog {
  pK_AuditID: string; Process: string; StartTime: string; Processed: string; Failed: string; errorDetails: string;
}

export interface ErrorLog {
  Date: string; Process: string; ErrorMessage: string, Run: string;
}