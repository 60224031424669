import { Component, OnInit,EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { AuthservService } from '../servicess/authserv.service';
import { ActivatedRoute } from '@angular/router';
import { ViewChild, ElementRef } from '@angular/core';
import { first } from 'rxjs/operators';
import { NgForm } from '@angular/forms';
import { throwMatDialogContentAlreadyAttachedError } from '@angular/material';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { Location } from '@angular/common';

@Component({
  selector: 'app-testcomponent',
  templateUrl: './testcomponent.component.html',
  styleUrls: ['./testcomponent.component.css']
})
export class TestcomponentComponent implements OnInit {
  @BlockUI() blockUI: NgBlockUI;
  public msg: any = {};
  public display: any;
  public data: any;
  tokenstorage: any;
  id: any;
  returnUrl: string;
  error = '';

  UserName: any;
  Email: any;
  Password: any;
  Address: any;
  emailVerified = false;
  status: any
  DomainName: any;

  submitted = false;
  public res;
  token1: any = [];
  token: any;
  tokenData = new EventEmitter<any>();
  statusText: any;
  info: any;
  accessToken: any;
  RefreshToken: any;

  message: any;
  conformpass: any;
  pass: any;
  mobile: any;
  name: any;
  email: any;
  uname: any;
  CompanyName: any;
  emaill: any;
  u1: any;
  u2: any;

  checkflag: any = 0;
  @ViewChild('f') indexForm: NgForm;
  @ViewChild('signupform') signup_Form: NgForm;
  @ViewChild('closeAddExpenseModal') closeAddExpenseModal: ElementRef;
  @ViewChild('closeLoginModal') closeLoginModal: ElementRef;

  constructor(private route: Router, private router: ActivatedRoute, private http: HttpClient, private authserve: AuthservService, private loc: Location) {
    this.router.queryParams.subscribe(params => {
      this.id = params['id'];
    });
  }

  ngOnInit() {

    this.blockUI.stop();
    this.getIt();
    const angularRoute = this.loc.path();
    this.DomainName = window.location.hostname + (window.location.port ? ':' + window.location.port : '');
   // this.changecontent();
   
  }

  onSubmit(data: { lg_UserName: any, lg_Password: any }) {
    this.blockUI.start('Validating your credentials');
    this.submitted = true;
    this.res = this.authserve.Login(data)
      .subscribe((responsee) => {
        this.blockUI.stop();
        var data: any = responsee;
        if (this.statusText == "Bad Request") {
          let msg: any = {};
          msg.Message = data.Message;
          this.msg = msg;
        }
        else {
          this.token1 = responsee;
          this.token = this.token1.LoginAccessToken;
          this.accessToken = responsee['access_token'];
          localStorage.setItem('accessToken', this.accessToken);
          this.RefreshToken = responsee['refresh_token']
          localStorage.setItem('refreshToken', this.RefreshToken);

          this.authserve.CheckLoggedUser(responsee['userName']).subscribe((data: any) => {
            if (data.results.multiDetails != null)
              localStorage.setItem('LoginMultiDetails', JSON.stringify(data.results.multiDetails));
            else
              localStorage.setItem('LoginMultiDetails', null);
            localStorage.setItem('customerID', data.results.customerID);
            localStorage.setItem('loggedId', data.results.loggedId);
            localStorage.setItem('userType', data.results.userType);
            localStorage.setItem('imageUrl', data.results.imageUrl);
            localStorage.setItem('loggedName', data.results.fullName);
            localStorage.setItem('LoggedRole', data.results.roleID);
            localStorage.setItem('isNSPending', data.results.nsPending);
            localStorage.setItem('company', data.results.company);

            if (data.status != "Error") {
              this.closeLoginModal.nativeElement.click();
              if (data.results.userType == "Admin") {
                this.route.navigate(['/AdminDashboard']);
              }
              else {
                this.route.navigate(['/dashBoard']);
              }
            }
          })
        }
      }, error => {
        this.blockUI.stop();
        this.data = "Invalid Login Credentials"
        this.display = this.data;
        setTimeout(() => {
          this.display = "";
        }, 3000)
      })
  }
  isloaded: any = [];

  getIt() {
    if (this.id) {
      this.authserve.VerifyRequest(this.id).subscribe((data: any) => {
        this.isloaded = data;
        this.route.navigate(['/home']);
      })
    }
  }

  onSignUP(data: any) {
    this.blockUI.start('Registering your account...');
    this.submitted = true,
      this.u1 = this.signup_Form.value.UserName;
    this.u2 = this.signup_Form.value.Email;
    this.data = {
      FullName: this.signup_Form.value.UserName,
      CompanyName: this.signup_Form.value.CompanyName,
      UserName: this.signup_Form.value.Email,
      Email: this.signup_Form.value.Email,
      Password: this.signup_Form.value.Password,
      Phone: this.signup_Form.value.phone,
      Domain: this.DomainName
    }
    this.message = "Please check your gmail";
    this.authserve.SignUp(this.data).subscribe(responseData => {
      var data: any = responseData;
      if (data.status == "Error") {
        let msg: any = {};
        msg.errorMessage = data.errorMessage;
        this.msg = msg;
        if (data.emailVerified == false)
          this.emailVerified = true;
        this.blockUI.stop();
        this.blockUI.start(data.errorMessage);
        setTimeout(() => {
          this.blockUI.stop();
        }, 3000)
      }
      else {
        this.signup_Form.resetForm();
        this.signup_Form.form.markAsPristine();
        this.signup_Form.form.markAsUntouched();
        this.signup_Form.form.updateValueAndValidity();
        this.info = responseData;
        this.Email = this.signup_Form.value.Email;
        this.conformpass = "";
        this.pass = "";
        this.mobile = "";
        this.emaill = data.Email;
        let msg: any = {};
        var data2: any = data;
        this.msg.errorMessage = data2.statusDetails;
        this.blockUI.stop();
        this.blockUI.start('Please check your mail to activate your account.');
        setTimeout(() => {
          this.blockUI.stop();
          this.route.navigate(['/home']);
          this.closeAddExpenseModal.nativeElement.click();
        }, 3000)
      }
    });
  }

  requestData() {
    this.blockUI.start('Resending email to activate your account.');
    this.authserve.resendEmail(this.DomainName, this.data.Email).subscribe((data) => {
      let msg: any = {};
      var data111: any = data;
      this.msg.errorMessage = data111.statusDetails;
      this.emailVerified = false;
      this.blockUI.stop();
      this.blockUI.start('Please check your mail to activate your account.');
      setTimeout(() => {
        this.blockUI.stop();
        this.signup_Form.resetForm();
        this.closeAddExpenseModal.nativeElement.click();
        this.route.navigate(['/home']);
      }, 3000)
    })
  }

}
