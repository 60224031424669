import { Component, OnInit, EventEmitter, Output, Inject, ViewChild, AfterViewInit, ElementRef } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MapDataComponent } from '../map-data/map-data.component';
import { HttpClient } from '@angular/common/http';
import { ConnectorsService } from '../servicess/connectors.service'
import { GlobalTypeScriptService } from '../servicess/global-type-script.service';
import { AuthservService } from '../servicess/authserv.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { NgxPaginationModule } from 'ngx-pagination';
import { Chart } from 'chart.js'
import * as $ from 'jquery';
@Component({
  selector: 'app-connector-analytics',
  templateUrl: './connector-analytics.component.html',
  styleUrls: ['./connector-analytics.component.css']
})
export class ConnectorAnalyticsComponent implements OnInit {
  [x: string]: any;

  constructor(private authserve: AuthservService, private router: ActivatedRoute, public dialog: MatDialog, private route: Router, private http: HttpClient, private connectService: ConnectorsService, private GTS: GlobalTypeScriptService) {

    $(document).ready(function () {
      $(".semi-circle").each(function () {
        var $bar = $(this).find(".semi-circle-bar");
        var $val = $(this).find("span");
        var perc = parseInt($val.text(), 10);

        $({ p: 0 }).animate({ p: perc }, {
          duration: 3000,
          easing: "swing",
          step: function (p) {
            $bar.css({
              transform: "rotate(" + (45 + (p * 1.8)) + "deg)", // 100%=180° so: ° = % * 1.8
              // 45 is to add the needed rotation to have the green borders at the bottom
            });
            $val.text(p | 0);
          }
        });
      });
    });
  }
  //bar-chart-functionality start
  public barChartOptions = {
    scaleShowVerticalLines: false,
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      xAxes: [
        {
          ticks: {
            fontSize: 13
          },
          barThickness: 5,
        }
      ]
    },
    legend: {
      labels: {
        fontSize: 20
      }
    }
  };

  public barChartLabels = [];
  public barChartType = 'bar';
  public barChartLegend = true;


  public barChartData = [
    { data: [], label: 'Sales' }
  ];
  public chartColors: any[] = [{ backgroundColor: ["#0bf", "#0bf", "#0bf", "#0bf", "#0bf", "#0bf", "#0bf", "#0bf", "#0bf", "#0bf", "#0bf", "#0bf"] }];

  _connectorID: any = "";
  _PageResponseData = {
    liveorders: 0, shippedorders: 0, cancelledorders: 0, returns: 0, refunds: 0, errorrecords: 0, openorders: 0,
    notshipped: 0, returnedorders: 0, openrefunds: 0, itemcounts: 0
  }
  _chartData: any;
  ngOnInit() {
    const parameters = window.location.href;
    var pieces = parameters.split(/[\s/]+/);
    this._connectorID = (pieces[pieces.length - 1]);
    localStorage.setItem('ActiveLiName', 'Overview');
    this.GetOverviewContent();
    this.GetConnectorAnalytics();
  }
  GetOverviewContent() {
    this.connectService.GetConnectorOverview(this._connectorID).subscribe((data: any) => {
      if (data.status != "Error") {
        this._OverViewContent = JSON.parse(data.results.overView);
        this.faq = JSON.parse(data.results.faq);
        this._showDivContent = true;
      }
    })
  }
  GetConnectorAnalytics() {
    this.connectService.GetConnectorAnalytics(this._connectorID).subscribe((data: any) => {
      if (data.status != "Error") {
        this._PageResponseData = data.results;
        this._chartData = data.results.barchartData;
        this.changechart();
      }
    })
  }

  changechart() {
    var D_e = (document.getElementById("ddlChartFilter")) as HTMLSelectElement;
    var D_sel = D_e.selectedIndex;
    var D_opt = D_e.options[D_sel];
    var DestValue = ((<HTMLOptionElement>D_opt).value);
    var chartlabels = []; var chartValues = [];
    if (DestValue == "daily") {
      var daychart = this._chartData.daily;
      for (var i = 0; i < daychart.length; i++) {
        chartlabels.push(daychart[i].date);
        chartValues.push(daychart[i].saleCount);
      }
    }
    if (DestValue == "monthly") {
      var daychart = this._chartData.monthly;
      for (var i = 0; i < daychart.length; i++) {
        chartlabels.push(daychart[i].month);
        chartValues.push(daychart[i].saleCount);
      }
    }
    if (DestValue == "year") {
      var daychart = this._chartData.yearly;
      for (var i = 0; i < daychart.length; i++) {
        chartlabels.push(daychart[i].year);
        chartValues.push(daychart[i].saleCount);
      }
    }
    this.barChartLabels = chartlabels;
    this.barChartData = [
      { data: chartValues, label: 'Sales' }
    ];
  }
}

/*  piecanvas: any;
  piectx: any;
  lpiecanvas: any;
  lpiectx: any;
  constructor(private authserve: AuthservService, private router: ActivatedRoute, public dialog: MatDialog, private route: Router, private http: HttpClient, private connectService: ConnectorsService, private GTS: GlobalTypeScriptService) { }
  _MarketPlaceName: any = localStorage.getItem('SelectedconnectorName');
  _OverViewContent: any = [];
  _showDivContent: any = false;
  faq: Array<any> = [];
  p: number;
  _connectorID: any = "";
  _daysIntegrated: any = "";
  _frequency: any = "";
  _recordsProcessed: any = "";
  _failedrecords: any = "";

  ngOnInit() {
    const parameters = window.location.href;
    var pieces = parameters.split(/[\s/]+/);
    this._connectorID = (pieces[pieces.length - 1]);
    localStorage.setItem('ActiveLiName', 'Overview');
    this.GetOverviewContent();
    this.GetOverviewReports();
    this.Getoneyeardata();
    this.displaymultilinechart();
  }
  ngAfterViewInit() {

  }
  GetOverviewContent() {
    this.connectService.GetConnectorOverview(this._connectorID).subscribe((data: any) => {
      if (data.status != "Error") {
        this._OverViewContent = JSON.parse(data.results.overView);
        this.faq = JSON.parse(data.results.faq);
        this._showDivContent = true;
      }
    })
  }
  Getoneyeardata() {
    this.connectService.Getoneyeardata(this._connectorID).subscribe((data: any) => {
      if (data.status != "Error") {
        this.displaylinechart(data.results);
      }
    })
  }

  GetOverviewReports() {
    this.connectService.GetConnectorReport(this._connectorID).subscribe((data: any) => {
      if (data.status != "Error") {
        this._daysIntegrated = data.results[0].days;
        this._frequency = data.results[0].frequency;
        this._recordsProcessed = data.results[0].recordsProcessed;
        this._failedrecords = data.results[0].failedrecords;
        this.displaypiechart(data.results[0].recordsProcessed, data.results[0].failedrecords);
      }
    })
  }

  displaypiechart(processed, failed) {
    this.piecanvas = document.getElementById('pieChart');
    this.piectx = this.piecanvas.getContext('2d');
    let myChart = new Chart(this.piectx, {
      type: 'pie',
      data: {
        labels: ["Processed", "Failed"],
        datasets: [{
          // label: 'Records',
          data: [processed, failed],
          backgroundColor: [
            'rgba(255, 99, 132, 1)',
            'rgba(54, 162, 235, 1)'
          ],
          borderWidth: 1
        }]
      },
      options: {
        responsive: false
      },
    });
  }

  displaylinechart(data) {
    var labels = [], datavalues = [];
    for (var i = 0; i < data.length; i++) {
      labels.push(data[i].date);
      datavalues.push(data[i].totalRecords);
    }
    this.piecanvas = document.getElementById('linechart');
    this.piectx = this.piecanvas.getContext('2d');
    let myChart = new Chart(this.piectx, {
      type: 'line',
      data: {
        labels: labels,
        datasets: [{
          label: 'Yearly Records Processed',
          data: datavalues,
          borderWidth: 1
        }]
      },
      options: {
        responsive: false
      },
    });
  }
  displaymultilinechart() {

    this.piecanvas = document.getElementById('multilinechart');
    this.piectx = this.piecanvas.getContext('2d');
    let myChart = new Chart(this.piectx, {
      type: 'line',
      data: {
        labels: ['Jan', 'Feb', 'March', 'April', 'May', 'June', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        datasets: [{
          label: 'Items',
          data: [14, 25, 36, 74, 85, 96, 78, 45, 12, 32, 65, 98],
          borderWidth: 1
        },
        {
          label: 'Inventory',
          data: [23, 56, 89, 21, 54, 87, 14, 25, 36, 96, 85, 74],
          borderWidth: 1
        },
        {
          label: 'Images',
          data: [45, 56, 12, 23, 78, 89, 63, 47, 52, 84, 15, 95],
          borderWidth: 1
        },
        {
          label: 'Orders',
          data: [11, 21, 51, 42, 85, 96, 75, 95, 93, 59, 2, 12],
          borderWidth: 1
        },
        {
          label: 'Billing',
          data: [1, 5, 8, 3, 4, 9, 6, 75, 52, 69, 44, 55],
          borderWidth: 1
        },
        {
          label: 'Shipments',
          data: [1, 2, 3, 4, 3, 2, 1, 45, 65, 41, 63, 47],
          borderWidth: 1
        },
        {
          label: 'Cancellations',
          data: [21, 52, 63, 47, 85, 96, 14, 25, 36, 74, 78, 96],
          borderWidth: 1
        },
        {
          label: 'Returns & Refunds',
          data: [11, 25, 41, 56, 32, 74, 56, 21, 45, 23, 69, 85],
          borderWidth: 1
        }
        ]
      },
      options: {
        responsive: false
      },
    });
  }
}*/