import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MapDataComponent } from '../map-data/map-data.component';
import { HttpClient } from '@angular/common/http';
import { ConnectorsService } from '../servicess/connectors.service';
import { GlobalTypeScriptService } from '../servicess/global-type-script.service';
import { AuthservService } from '../servicess/authserv.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { NgForm } from '@angular/forms';
@Component({
  selector: 'app-support-tickets',
  templateUrl: './support-tickets.component.html',
  styleUrls: ['./support-tickets.component.css']
})
export class SupportTicketsComponent implements OnInit {
  displayedColumns = ['TicketNumber', 'CustomerName', 'Subject', 'Status'];
  dataSource: MatTableDataSource<UserData>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('closeAddExpenseModal') closeAddExpenseModal: ElementRef;
  @ViewChild('openAddExpenseModal') openAddExpenseModal: ElementRef;
  @ViewChild('f') indexForm: NgForm;
  constructor(private authserve: AuthservService, private router: ActivatedRoute, public dialog: MatDialog, private route: Router, private http: HttpClient, private connectService: ConnectorsService, private GTS: GlobalTypeScriptService) { }

  _Tickets: Array<any> = [];
  _selectedTicket: any;
  _ticketNumber: any;
  _CustomerName: any;
  _Status: any;
  _Subject: any;
  _Description: any;
  _comment: any;
  _ticketReplies: Array<any> = [];
  ngOnInit() {
    this.GetSupportTickets();
  }
  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
  }
  GetSupportTickets() {
    this.connectService._AdminGetSupportTickets().subscribe((data: any) => {
      if (data.status != "Error") {
        this._Tickets = data.results;
        this.dataSource = new MatTableDataSource(data.results);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        if (parseInt(this.router.snapshot.paramMap.get('id')) > 0) {
          this.OnRecordClick(parseInt(this.router.snapshot.paramMap.get('id')))
        }
      }
    },
      (err) => {
      });
  }
  OnRecordClick(row) {
    let SelectedTicket = this._Tickets.find(i => i.pk_TicketID === row);
    this._selectedTicket = (SelectedTicket.pk_TicketID);
    this._ticketNumber = (SelectedTicket.ticketNumber);
    this._CustomerName = (SelectedTicket.customerName);
    this._Status = (SelectedTicket.ticketStatus);
    this._Subject = (SelectedTicket.subject);
    this._Description = (SelectedTicket.description);
    this.connectService.GetSupportComments(SelectedTicket.pk_TicketID).subscribe((data: any) => {
      if (data.status != "Error") {
        this._ticketReplies = data.results;
        this.openAddExpenseModal.nativeElement.click();
      }
    });
  }
  onSubmit() {
    var JsonBody = {
      Pk_TicketID: this._selectedTicket,
      TicketStatus: parseInt(this.indexForm.value.ddlStatus),
      Comment: this.indexForm.value.txtComment
    };
    this.connectService.UpdateSupportTicket(JsonBody).subscribe((data: any) => {
      if (data.status != "Error") {
        this.closeAddExpenseModal.nativeElement.click();
        this.GetSupportTickets();
      }
    },
      (err) => {
      });
  }
}
export interface UserData {
  TicketNumber: string; CustomerName: string; Subject: string; Status: string;
}