import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MapDataComponent } from '../map-data/map-data.component';
import { HttpClient } from '@angular/common/http';
import { ConnectorsService } from '../servicess/connectors.service';
import { GlobalTypeScriptService } from '../servicess/global-type-script.service';
import { AuthservService } from '../servicess/authserv.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { NgForm } from '@angular/forms';
@Component({
  selector: 'app-raise-ticket',
  templateUrl: './raise-ticket.component.html',
  styleUrls: ['./raise-ticket.component.css']
})
export class RaiseTicketComponent implements OnInit {
  displayedColumns = ['TicketNumber', 'date', 'Subject', 'ticketStatus'];
  dataSource: MatTableDataSource<UserData>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('closeAddExpenseModal') closeAddExpenseModal: ElementRef;
  @ViewChild('openAddExpenseModal') openAddExpenseModal: ElementRef;
  @ViewChild('f') indexForm: NgForm;
  @ViewChild('resf') responseForm: NgForm;
  constructor(private authserve: AuthservService, private http: HttpClient, private connectorService: ConnectorsService, private Router: Router, private activatedRoute: ActivatedRoute) {

  }
  _Tickets: Array<any> = [];
  _RaisedTickets: any = [];
  _JsonBodyData: any = [];
  Message: any;
  faq: Array<any> = [];
  p: number;

  _selectedTicket: any;
  _ticketNumber: any;
  _CustomerName: any;
  _Status: any;
  _Subject: any;
  _Description: any;
  _comment: any;
  _ticketReplies: Array<any> = [];
  ngOnInit() {
    this.GetSupportTickets();
  }
  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
  }
  GetSupportTickets() {
    this.connectorService.GetSupportTickets().subscribe((data: any) => {
      if (data.status == "Success") {
        this._RaisedTickets = data.results;
        this._Tickets = data.results;
        this.dataSource = new MatTableDataSource(data.results);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      }
      else {

      }
    }, error => {
    })
  }
  OnRecordClick(row) {
    this.responseForm.resetForm();
    let SelectedTicket = this._Tickets.find(i => i.pK_TicketID === row);
    this._selectedTicket = (SelectedTicket.pK_TicketID);
    this._ticketNumber = (SelectedTicket.ticketNumber);
    this._CustomerName = (SelectedTicket.customerName);
    this._Status = (SelectedTicket.ticketStatus);
    this._Subject = (SelectedTicket.subject);
    this._Description = (SelectedTicket.description);
    this.connectorService.GetSupportComments(SelectedTicket.pK_TicketID).subscribe((data: any) => {
      if (data.status != "Error") {
        this._ticketReplies = data.results;
        this.openAddExpenseModal.nativeElement.click();
      }
    });
  }
  OnResetClick() {
    this.indexForm.resetForm();
  }
  text(date: any) {
    if (date) {
      return date.split('T')[0];
    }
    return '';
  }
  onSubmit() {
    this._JsonBodyData = {
      "Subject": this.indexForm.value.txtSubject,
      "Description": this.indexForm.value.txtDescription
    }
    this.connectorService.PostSupportTicket(this._JsonBodyData).subscribe((data: any) => {
      if (data.status == "Success") {
        this.GetSupportTickets();
        this.indexForm.resetForm();
        this.Message = "Ticket Raised."
        setTimeout(() => {
          this.Message = "";
        }, 2000);
      }
      else {
        this.Message = data.errorMessage;
      }
    }, error => {
    })
  }
  onResponseSubmit() {
    var JsonBody = {
      Pk_TicketID: this._selectedTicket,
      Comment: this.responseForm.value.txtComment
    };
    this.connectorService.UpdateSupportTicket(JsonBody).subscribe((data: any) => {
      if (data.status != "Error") {
        this.closeAddExpenseModal.nativeElement.click();
        this.GetSupportTickets();
      }
    },
      (err) => {
      });
    this.responseForm.resetForm();
  }
}
export interface UserData {
  TicketNumber: string; date: string; Subject: string; ticketStatus: string;
}