import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MapDataComponent } from '../map-data/map-data.component';
import { HttpClient } from '@angular/common/http';
import { ConnectorsService } from '../servicess/connectors.service';
import { GlobalTypeScriptService } from '../servicess/global-type-script.service';
import { AuthservService } from '../servicess/authserv.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-admin-dashboard',
  templateUrl: './admin-dashboard.component.html',
  styleUrls: ['./admin-dashboard.component.css']
})
export class AdminDashboardComponent implements OnInit {

  constructor(private ActivatedRoute: ActivatedRoute, private authserve: AuthservService, private http: HttpClient, private connectorService: ConnectorsService, private Router: Router, private activatedRoute: ActivatedRoute) { }
  _DashboardElementsData: any = { _customers: 0, _connectors: 0, SubscribtionPlans: 0, Subscribtions: 0 };
  _customersList: Array<any> = [];
  _SupportTicketsList: Array<any> = [];
  _QuotesList: Array<any> = [];
  ngOnInit() {
    this.GetDashboardElements();
    this.GetDashboardTables();
  }
  GetDashboardElements() {
    this.connectorService.GetDashboardElements().subscribe((data: any) => {
      if (data.status == "Success") {
        this._DashboardElementsData._customers = data.results[0].customers;
        this._DashboardElementsData._connectors = data.results[0].connectors;
        this._DashboardElementsData.SubscribtionPlans = data.results[0].subscriptionPlans;
        this._DashboardElementsData.Subscribtions = data.results[0].subscriptions;
      }
    }, error => {

    })
  }
  GetDashboardTables() {
    this.connectorService.GetDashboardTables().subscribe((data: any) => {
      if (data.status == "Success") {
        this._customersList = data.results.customers;
        this._SupportTicketsList = data.results.supportTickets;
        this._QuotesList = data.results.raisedQuotes;
      }
    }, error => {

    })
  }
  SupportTicket(id) {
    this.Router.navigate(["/SupportTickets", id]);
  }
}
