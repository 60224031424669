import { Component, OnInit, EventEmitter, Output, Inject, ViewChild } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MapDataComponent } from '../map-data/map-data.component';
import { HttpClient } from '@angular/common/http';
import { ConnectorsService } from '../servicess/connectors.service';
import { NgxPaginationModule } from 'ngx-pagination';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { from } from 'rxjs';

interface PeriodicElement {
  name: string;
  position: number;
  weight: number;
  symbol: string;
}

@Component({
  selector: 'app-audit-records',
  templateUrl: './audit-records.component.html',
  styleUrls: ['./audit-records.component.css']
})
export class AuditRecordsComponent implements OnInit {
  dataSourceOne: MatTableDataSource<PeriodicElement>;
  displayedColumnsOne: string[] = ['Process', 'StartTime', 'Processed', 'Failed'];
  @ViewChild('TableOnePaginator') tableOnePaginator: MatPaginator;
  @ViewChild('TableOneSort') tableOneSort: MatSort;
  @BlockUI() blockUI: NgBlockUI;

  dataSourceTwo: MatTableDataSource<PeriodicElement>;
  displayedColumnsTwo: string[] = ['Date', 'Process', 'ErrorMessage', 'Run'];
  @ViewChild('TableTwoPaginator') tableTwoPaginator: MatPaginator;
  @ViewChild('TableTwoSort') tableTwoSort: MatSort;
  constructor(private router: ActivatedRoute, public dialog: MatDialog, private route: Router, private http: HttpClient, private connectSer: ConnectorsService) {
    this.dataSourceOne = new MatTableDataSource;
    this.dataSourceTwo = new MatTableDataSource;
  }
  _AuditLogList: any = [];
  _jsonErrorList: any = [];
  p: any;
  _connectorID: any = "";
  ngOnInit() {
    const parameters = window.location.href;
    var pieces = parameters.split(/[\s/]+/);
    this._connectorID = (pieces[pieces.length - 1]);
    localStorage.setItem('ActiveLiName', 'Errors');
    this.AuditList();
  }
  applyFilterOne(filterValue: string) {
    this.dataSourceOne.filter = filterValue.trim().toLowerCase();
  }
  AuditList() {
    this.blockUI.start('Loading...');
    this.connectSer.GetAuditLog(this._connectorID).subscribe((data: any) => {
      if (data.status == "Success") {
        this.blockUI.stop();
        this.dataSourceOne.data = data.results;
        this.dataSourceOne.paginator = this.tableOnePaginator;
        this.dataSourceOne.sort = this.tableOneSort;
      }
    })
  }
  GetRecords() {
    var Fromdate = (<HTMLInputElement>document.getElementById("txtFromDate")).value;
    var Todate = (<HTMLInputElement>document.getElementById("txtToDate")).value;
    if (Fromdate != "" && Todate != "") {
      this.blockUI.start('Loading...');
      // this.dataSourceOne.data = null;
      // this.dataSourceOne.paginator = null;
      // this.dataSourceOne.sort = null;
      this.connectSer.GetAuditLogByDates(Fromdate, Todate, this._connectorID).subscribe((data: any) => {
        if (data.status == "Success") {
          this.blockUI.stop();
          this.dataSourceOne.data = data.results;
          this.dataSourceOne.paginator = this.tableOnePaginator;
          this.dataSourceOne.sort = this.tableOneSort;
        }
      })
    }
  }
}

export interface AuditLog {
  pK_AuditID: string; Process: string; StartTime: string; Processed: string; Failed: string; errorDetails: string;
}
