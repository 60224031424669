import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { AuthservService } from '../servicess/authserv.service';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
@Component({
  selector: 'app-emailvalid',
  templateUrl: './emailvalid.component.html',
  styleUrls: ['./emailvalid.component.css']
})
export class EmailvalidComponent implements OnInit {
  id: any;
  constructor(private http: HttpClient, private auth: AuthservService, private route: ActivatedRoute, private router: Router) {

  }
  isloaded: any = [];
  ngOnInit() {

  }
  custId: any;




}
