import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AuthservService } from '../servicess/authserv.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-forgotpass',
  templateUrl: './forgotpass.component.html',
  styleUrls: ['./forgotpass.component.css']
})
export class ForgotpassComponent implements OnInit {
  @BlockUI() blockUI: NgBlockUI;
  @ViewChild('f') indexForm: NgForm;
  constructor(private authservice: AuthservService, private route: Router, ) { }
  DomainName: any = "";
  ngOnInit() {
    this.DomainName = window.location.hostname + (window.location.port ? ':' + window.location.port : '');
  }
  onSubmit(data: any) {
    this.blockUI.start('Processing your request');
    this.authservice.ForgetPassword(this.DomainName, this.indexForm.value.UserName).subscribe(responseData => {
       var data: any = responseData;
      this.blockUI.stop();
      if (data.status == "Success") {
        this.blockUI.start('Please check your mail to reset your password.');
        setTimeout(() => {
          this.blockUI.stop();
          this.route.navigate(['/home'])
        }, 3000)
      }
    });
  }
}
