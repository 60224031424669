import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { AuthservService } from '../servicess/authserv.service';
import { ConnectorsService } from '../servicess/connectors.service';
import { NgForm } from '@angular/forms';
import { Location } from '@angular/common';
@Component({
  selector: 'app-default-mappings',
  templateUrl: './default-mappings.component.html',
  styleUrls: ['./default-mappings.component.css']
})
export class DefaultMappingsComponent implements OnInit {
  constructor(private route: Router, private http: HttpClient, private authservice: AuthservService, private loc: Location, private connectService: ConnectorsService, ) { }
  _connectorsList: Array<any> = [];
  _Message: any;
  @ViewChild('f') indexForm: NgForm;
  ngOnInit() {
    this.GetConnectorNames();
  }
  GetConnectorNames() {
    this.connectService.GetConnectorNamesList(null).subscribe((data: any) => {
      if (data.status == "Success") {
        this._connectorsList = data.results;
      }
    }, error => {

    })
  }
  onConnectorChange() {
    var Connector = this.indexForm.value.ddlConnector;
    if (Connector != 0) {
      this.GetConnectorDefaultMappings(Connector);
    }
    else {
      this.ResetForm();
    }
  }
  onSubmit() {
    var postFieldMapping = {
      "Fk_ConnectorID": this.indexForm.value.ddlConnector,
      "Item_ImportFieldsMapping": (<HTMLInputElement>document.getElementById("txt_item_import_fm")).value,
      "Item_ExportFieldsMapping": (<HTMLInputElement>document.getElementById("txt_item_export_fm")).value,
      "Inventory_ImportFieldsMapping": (<HTMLInputElement>document.getElementById("txt_inv_import_fm")).value,
      "Inventory_ExportFieldsMapping": (<HTMLInputElement>document.getElementById("txt_inv_export_fm")).value,
      "Price_ImportFieldsMapping": (<HTMLInputElement>document.getElementById("txt_price_import_fm")).value,
      "Price_ExportFieldsMapping": (<HTMLInputElement>document.getElementById("txt_price_export_fm")).value,
      "Image_ImportFieldsMapping": (<HTMLInputElement>document.getElementById("txt_image_import_fm")).value,
      "Image_ExportFieldsMapping": (<HTMLInputElement>document.getElementById("txt_image_export_fm")).value,
      "Orders_ImportFieldsMapping": (<HTMLInputElement>document.getElementById("txt_ord_import_fm")).value,
      "Orders_advanceFieldsMapping": (<HTMLInputElement>document.getElementById("txt_ord_advance_fm")).value,
      "Orders_ExportFieldsMapping": (<HTMLInputElement>document.getElementById("txt_ord_export_fm")).value,
      "Shipments_ImportFieldsMapping": (<HTMLInputElement>document.getElementById("txt_shp_import_fm")).value,
      "Shipments_ExportFieldsMapping": (<HTMLInputElement>document.getElementById("txt_shp_export_fm")).value,
      "Refunds_ImportFieldsMapping": (<HTMLInputElement>document.getElementById("txt_rfd_import_fm")).value,
      "Refunds_ExportFieldsMapping": (<HTMLInputElement>document.getElementById("txt_rfd_export_fm")).value,
      "Cancellation_ImportFieldsMapping": (<HTMLInputElement>document.getElementById("txt_cnc_import_fm")).value,
      "Cancellation_ExportFieldsMapping": (<HTMLInputElement>document.getElementById("txt_cnc_export_fm")).value,
      "Billing_ImportFieldsMapping": (<HTMLInputElement>document.getElementById("txt_bill_import_fm")).value,
      "Billing_ExportFieldsMapping": (<HTMLInputElement>document.getElementById("txt_bill_export_fm")).value
    };
    this.connectService.PostDefaultFieldMapping(postFieldMapping).subscribe((data: any) => {
      if (data.status == "Success") {
        this._Message = "Data Submitted successfully.";
      }
      else {
        this._Message = data.errorMessage;
      }
    }, error => {

    })
    setTimeout(() => {
      this._Message = "";
    }, 3000);
    this.ResetForm();
  }
  ResetForm() {
    this.indexForm.resetForm();
    this.ResetAllTextAreas();
  }
  ResetAllTextAreas() {
    (<HTMLInputElement>document.getElementById("txt_item_import_fm")).value = '';
    (<HTMLInputElement>document.getElementById("txt_item_export_fm")).value = '';
    (<HTMLInputElement>document.getElementById("txt_inv_import_fm")).value = '';
    (<HTMLInputElement>document.getElementById("txt_inv_export_fm")).value = '';
    (<HTMLInputElement>document.getElementById("txt_price_import_fm")).value = '';
    (<HTMLInputElement>document.getElementById("txt_price_export_fm")).value = '';
    (<HTMLInputElement>document.getElementById("txt_image_import_fm")).value = '';
    (<HTMLInputElement>document.getElementById("txt_image_export_fm")).value = '';
    (<HTMLInputElement>document.getElementById("txt_ord_import_fm")).value = '';
    (<HTMLInputElement>document.getElementById("txt_ord_advance_fm")).value = '';
    (<HTMLInputElement>document.getElementById("txt_ord_export_fm")).value = '';
    (<HTMLInputElement>document.getElementById("txt_shp_import_fm")).value = '';
    (<HTMLInputElement>document.getElementById("txt_shp_export_fm")).value = '';
    (<HTMLInputElement>document.getElementById("txt_rfd_import_fm")).value = '';
    (<HTMLInputElement>document.getElementById("txt_rfd_export_fm")).value = '';
    (<HTMLInputElement>document.getElementById("txt_cnc_import_fm")).value = '';
    (<HTMLInputElement>document.getElementById("txt_cnc_export_fm")).value = '';
    (<HTMLInputElement>document.getElementById("txt_bill_import_fm")).value = '';
    (<HTMLInputElement>document.getElementById("txt_bill_export_fm")).value = '';
  }
  GetConnectorDefaultMappings(Connector) {
    this.ResetAllTextAreas();
    this.connectService.GetConnectorDefaultMappings(Connector).subscribe((data: any) => {
      if (data.status == "Success") {
        var ResultsList = data.results;
        if (ResultsList.length > 0) {
          (<HTMLInputElement>document.getElementById("txt_item_import_fm")).value = ResultsList[0].item_ImportFieldsMapping;
          (<HTMLInputElement>document.getElementById("txt_item_export_fm")).value = ResultsList[0].item_ExportFieldsMapping;
          (<HTMLInputElement>document.getElementById("txt_inv_import_fm")).value = ResultsList[0].inventory_ImportFieldsMapping;
          (<HTMLInputElement>document.getElementById("txt_inv_export_fm")).value = ResultsList[0].inventory_ExportFieldsMapping;
          (<HTMLInputElement>document.getElementById("txt_price_import_fm")).value = ResultsList[0].price_ImportFieldsMapping;
          (<HTMLInputElement>document.getElementById("txt_price_export_fm")).value = ResultsList[0].price_ExportFieldsMapping;
          (<HTMLInputElement>document.getElementById("txt_image_import_fm")).value = ResultsList[0].image_ImportFieldsMapping;
          (<HTMLInputElement>document.getElementById("txt_image_export_fm")).value = ResultsList[0].image_ExportFieldsMapping;
          (<HTMLInputElement>document.getElementById("txt_ord_import_fm")).value = ResultsList[0].orders_ImportFieldsMapping;
          (<HTMLInputElement>document.getElementById("txt_ord_advance_fm")).value = ResultsList[0].orders_advanceFieldsMapping;
          (<HTMLInputElement>document.getElementById("txt_ord_export_fm")).value = ResultsList[0].orders_ExportFieldsMapping;
          (<HTMLInputElement>document.getElementById("txt_shp_import_fm")).value = ResultsList[0].shipments_ImportFieldsMapping;
          (<HTMLInputElement>document.getElementById("txt_shp_export_fm")).value = ResultsList[0].shipments_ExportFieldsMapping;
          (<HTMLInputElement>document.getElementById("txt_rfd_import_fm")).value = ResultsList[0].refunds_ImportFieldsMapping;
          (<HTMLInputElement>document.getElementById("txt_rfd_export_fm")).value = ResultsList[0].refunds_ExportFieldsMapping;
          (<HTMLInputElement>document.getElementById("txt_cnc_import_fm")).value = ResultsList[0].cancellation_ImportFieldsMapping;
          (<HTMLInputElement>document.getElementById("txt_cnc_export_fm")).value = ResultsList[0].cancellation_ExportFieldsMapping;
          (<HTMLInputElement>document.getElementById("txt_bill_import_fm")).value = ResultsList[0].billing_ImportFieldsMapping;
          (<HTMLInputElement>document.getElementById("txt_bill_export_fm")).value = ResultsList[0].billing_ExportFieldsMapping;
        }
      }
    }, error => {

    })
  }
}
