import { Component, OnInit, EventEmitter, Output, Inject, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MapDataComponent } from '../map-data/map-data.component';
import { HttpClient } from '@angular/common/http';
import { ConnectorsService } from '../servicess/connectors.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';

@Component({
  selector: 'app-refund',
  templateUrl: './refund.component.html',
  styleUrls: ['./refund.component.css']
})
export class RefundComponent implements OnInit {
  @BlockUI() blockUI: NgBlockUI;
  @ViewChild('closeRefundPopup') closeRefundPopup: ElementRef;
  @ViewChild('CloseReturnPopup') CloseReturnPopup: ElementRef;
  constructor(private router: ActivatedRoute, public dialog: MatDialog, private route: Router, private http: HttpClient, private connectService: ConnectorsService) { }
  _refundImportSubscribed: any = false;
  _refundExportSubscribed: any = false;
  _refundImportEnabled: any = false;
  _refundExportEnabled: any = false;

  _marketPlaceFields: any;

  _returnsImportSubscribed: any = false;
  _returnsExportSubscribed: any = false;
  _returnsImportEnabled: any = false;
  _returnsExportEnabled: any = false;

  _StandFieldMappingSrcJSON: Array<any> = [];
  _StandFieldMappingDstJSON: Array<any> = [];
  _MarketPlaceFieldMapping: Array<any> = [];
  _refundsImportRunning: any = false;
  _refundsImportProcessingName: any = "";
  _refundsExportRunning: any = false;
  _refundsExportProcessingName: any = "";

  _returnsImportRunning: any = false;
  _returnsImportProcessingName: any = "";
  _returnsExportRunning: any = false;
  _returnsExportProcessingName: any = "";

  _importLockFieldMappingResult: Array<any> = [];
  _importStdFieldMappingResult: Array<any> = [];
  _importRetFieldMappingResult: Array<any> = [];
  _exportLockFieldMappingResult: Array<any> = [];
  _exportStdFieldMappingResult: Array<any> = [];
  _updatedImportFieldMapping: Array<any> = [];
  _updatedImportRetFieldMapping: Array<any> = [];
  _updatedExportFieldMapping: Array<any> = [];
  _connectorData: any;
  _flowNames: Array<any> = [];
  _importFlowName: any;
  _exportFlowName: any;
  _returnimportFlowName: any;
  _returnexportFlowName: any;
  _SchedulerFrequencyList: any = [];
  _SchedulerStartTimeList: any;
  _SchedulerDaysList: any;
  _importScheduleTime: any = [{ "Date": "", "Frequency": "", "Time": "", "Days": "" }];
  _exportScheduleTime: any = [{ "Date": "", "Frequency": "", "Time": "", "Days": "" }];
  _importScheduleTimeDays: any = [];
  _exportScheduleTimeDays: any = [];

  _importRetScheduleTime: any = [{ "Date": "", "Frequency": "", "Time": "", "Days": "" }];
  _exportRetScheduleTime: any = [{ "Date": "", "Frequency": "", "Time": "", "Days": "" }];
  _importRetScheduleTimeDays: any = [];
  _exportRetScheduleTimeDays: any = [];
  _isCustomizable: any = true;
  _ReturnImportFieldMapping: any = { "Account": "", "Adjustment": "", "ItemReceipt": false };
  _RefundsImportFieldMapping: any = { "Account": "", "Adjustment": "" };
  _datatypeArrname: any;
  _datatypeIndexValue: any;
  _dataTypeValue: any;
  _defaultTypeValue: any;

  _datatypeRetArrname: any;
  _datatypeRetIndexValue: any;
  _dataTypeRetValue: any;
  _defaultTypeRetValue: any;
  _itemReceipt: any = false;
  _serviceAPI: any = {
    refundsimport: "OrderRefundsImport",
    refundsexport: "OrderRefundsExport",
    returnsimport: "OrderReturnsImport",
    returnsexport: ""
  };
  _connectorID: any = "";
  ngOnInit() {
    const parameters = window.location.href;
    var pieces = parameters.split(/[\s/]+/);
    this._connectorID = (pieces[pieces.length - 1]);
    localStorage.setItem('ActiveLiName', 'Refunds');
    //this.blockUI.start('Loading...');
    this.connectorsList();
    this.GetSchedulerData();
    this.GetMarketPlaceFields();
  }
  GetSchedulerData() {
    var Result = this.connectService.GetSchedulerListItems();
    this._SchedulerFrequencyList = (Result[0].Frequency);
    this._SchedulerStartTimeList = (Result[0].Time);
    this._SchedulerDaysList = (Result[0].DayList);
  }
  GetMarketPlaceFields() {
    this.connectService.GetItemFieldMapping('SalesOrder', this._connectorID).subscribe((data: any) => {
      this.blockUI.stop();
      if (data.status != "Error") {
        this._marketPlaceFields = data.results.columnStandardFields;
      }
    })
  }
  connectorsList() {
    this.connectService.ListConnector(this._connectorID).subscribe((data: any) => {
      this._flowNames = JSON.parse(data.results[0].flowNames);
      this._importFlowName = (this._flowNames[0]).RefundsImport;
      this._exportFlowName = (this._flowNames[0]).RefundsExport;

      this._returnimportFlowName = (this._flowNames[0]).ReturnsImport;
      this._returnexportFlowName = (this._flowNames[0]).ReturnsExport;


      var MappingFields = JSON.parse(data.results[0].connectorFields);
      for (var k = 0; k < MappingFields.length; k++) {
        if ((MappingFields[k].RecordType.toUpperCase()) == 'REFUND') {
          this._MarketPlaceFieldMapping.push({ label: MappingFields[k].Label, value: MappingFields[k].Title })
        }
      }
      this._connectorData = data;
      if (data.results[0].s_Refunds_Import == true || data.results[0].add_Refunds_Import == true)
        this._refundImportSubscribed = true;
      if (data.results[0].s_Refunds_Export == true || data.results[0].add_Refunds_Export == true)
        this._refundExportSubscribed = true;
      if (data.results[0].refunds_Import == true)
        this._refundImportEnabled = true;
      if (data.results[0].refunds_Export == true)
        this._refundExportEnabled = true;

      if (data.results[0].s_Returns_Import == true || data.results[0].add_Refunds_Import == true)
        this._returnsImportSubscribed = true;
      if (data.results[0].s_Returns_Export == true || data.results[0].add_Returns_Export == true)
        this._returnsExportSubscribed = true;
      if (data.results[0].returns_Import == true)
        this._returnsImportEnabled = true;
      if (data.results[0].returns_Export == true)
        this._returnsExportEnabled = true;

      if (this._connectorData.results[0].refunds_ImportScheduletime != null) {
        this._importScheduleTime = (JSON.parse(this._connectorData.results[0].refunds_ImportScheduletime));
        this._importScheduleTimeDays = this._importScheduleTime[0].Days.split(',');
      }
      if (this._connectorData.results[0].refunds_ExportScheduletime != null) {
        this._exportScheduleTime = (JSON.parse(this._connectorData.results[0].refunds_ExportScheduletime));
        this._exportScheduleTimeDays = this._exportScheduleTime[0].Days.split(',');
      }

      if (this._connectorData.results[0].returns_ImportScheduletime != null) {
        this._importRetScheduleTime = (JSON.parse(this._connectorData.results[0].returns_ImportScheduletime));
        this._importRetScheduleTimeDays = this._importRetScheduleTime[0].Days.split(',');
      }
      if (this._connectorData.results[0].returns_ExportScheduletime != null) {
        this._exportRetScheduleTime = (JSON.parse(this._connectorData.results[0].returns_ExportScheduletime));
        this._exportRetScheduleTimeDays = this._exportRetScheduleTime[0].Days.split(',');
      }

      if (this._connectorData.results[0].returns_ImportFieldsMapping != null && this._connectorData.results[0].returns_ImportFieldsMapping != "") {
        var Mapping = JSON.parse(this._connectorData.results[0].returns_ImportFieldsMapping);
        this._ReturnImportFieldMapping.Account = Mapping.Account;
        this._ReturnImportFieldMapping.Adjustment = Mapping.Adjustment;
        try {
          this._itemReceipt = Mapping.ItemReceipt;
        }
        catch (exception) {

        }
        if (Mapping.FieldMapping) {
          this._importRetFieldMappingResult = Mapping.FieldMapping;
        }
      }
      if (this._connectorData.results[0].refunds_ImportFieldsMapping != null && this._connectorData.results[0].refunds_ImportFieldsMapping != "") {
        var Mapping = JSON.parse(this._connectorData.results[0].refunds_ImportFieldsMapping);
        this._RefundsImportFieldMapping.Account = Mapping.Account;
        this._RefundsImportFieldMapping.Adjustment = Mapping.Adjustment;
        if (Mapping.FieldMapping) {
          this._importStdFieldMappingResult = Mapping.FieldMapping;
        }
      }
      this._importStdFieldMappingResult.push({ MarketPlace: '', NetSuite: '', Default: '', DataType: '' });
      this._importRetFieldMappingResult.push({ MarketPlace: '', NetSuite: '', Default: '', Type: '' });
      this._serviceAPI = {
        refundsimport: (data.results[0].api_Refunds_Export != "" && data.results[0].api_Refunds_Export != null) ? data.results[0].api_Refunds_Export : "OrderRefundsImport",
        refundsexport: (data.results[0].api_Refunds_Export != "" && data.results[0].api_Refunds_Export != null) ? data.results[0].api_Refunds_Export : "OrderRefundsExport",
        returnsimport: (data.results[0].api_Returns_Import != "" && data.results[0].api_Returns_Import != null) ? data.results[0].api_Returns_Import : "OrderReturnsImport",
        returnsexport: (data.results[0].api_Returns_Export != "" && data.results[0].api_Returns_Export != null) ? data.results[0].api_Returns_Export : ""
      };
    })
  }

  _OnChangeStandardImportFieldMapping(index) {
    var index_id = (this._importStdFieldMappingResult.length) - 1;
    var SiD = "StdSrc_" + index_id;
    var DiD = "StdDst_" + index_id;
    var DfD = "DFTStd_" + index_id;
    var S_e = (document.getElementById("" + SiD)) as HTMLSelectElement;
    var S_sel = S_e.selectedIndex;
    var S_opt = S_e.options[S_sel];
    var ExpValue = (<HTMLInputElement>document.getElementById(DiD)).value;
    var DestValue = (<HTMLInputElement>document.getElementById(DfD)).value;
    if (ExpValue != "" && (((<HTMLOptionElement>S_opt).value) != "" || DestValue != "")) {
      this._importStdFieldMappingResult.push({ MarketPlace: '', NetSuite: '', Default: '', Type: '' });
    }
    var Dval = "StdDst_" + index;
    var ExpValInd = (<HTMLInputElement>document.getElementById(Dval)).value;
    var cogindex = "coggSrcStd_" + index;
    if (ExpValInd.toLowerCase().includes('custbody') || ExpValInd.toLowerCase().includes('cust')) {
      document.getElementById(cogindex).style.display = "block";
    }
    else {
      document.getElementById(cogindex).style.display = "none";
    }
  }
  _OnChangeReturnImportFieldMapping(index) {
    var index_id = (this._importRetFieldMappingResult.length) - 1;
    var SiD = "RetSrc_" + index_id;
    var DiD = "RetDst_" + index_id;
    var DfD = "DFTRet_" + index_id;
    var S_e = (document.getElementById("" + SiD)) as HTMLSelectElement;
    var S_sel = S_e.selectedIndex;
    var S_opt = S_e.options[S_sel];
    var ExpValue = (<HTMLInputElement>document.getElementById(DiD)).value;
    var DestValue = (<HTMLInputElement>document.getElementById(DfD)).value;
    if (ExpValue != "" && (((<HTMLOptionElement>S_opt).value) != "" || DestValue != "")) {
      this._importRetFieldMappingResult.push({ MarketPlace: '', NetSuite: '', Default: '', Type: '' });
    }
    var Dval = "RetDst_" + index;
    var ExpValInd = (<HTMLInputElement>document.getElementById(Dval)).value;
    var cogindex = "coggSrc_" + index;
    if (ExpValInd.toLowerCase().includes('custbody') || ExpValInd.toLowerCase().includes('cust')) {
      document.getElementById(cogindex).style.display = "block";
    }
    else {
      document.getElementById(cogindex).style.display = "none";
    }
  }

  UpdateReturnsImportFieldMapping() {
    this._updatedImportRetFieldMapping.length = 0;
    for (var i = 0; i < this._importRetFieldMappingResult.length; i++) {
      var SiD = "RetSrc_" + i;
      var DiD = "RetDst_" + i;
      var DfD = "DFTRet_" + i;
      var S_e = (document.getElementById("" + SiD)) as HTMLSelectElement;
      var S_sel = S_e.selectedIndex;
      var S_opt = S_e.options[S_sel];
      var ExpValue = this.GetValueFromArray(this._marketPlaceFields, ((<HTMLInputElement>document.getElementById(DiD)).value));
      var DataType = this.GetFieldTypeFromArray(this._marketPlaceFields, ((<HTMLInputElement>document.getElementById(DiD)).value));
      var DestValue = (<HTMLInputElement>document.getElementById(DfD)).value;
      if (ExpValue != "" && (((<HTMLOptionElement>S_opt).value) != "" || DestValue != "")) {
        if ((this.NetSuiteFieldExists(this._updatedImportFieldMapping, ExpValue)) == false)
          this._updatedImportFieldMapping.push({ MarketPlace: ((<HTMLOptionElement>S_opt).value), label: (<HTMLInputElement>document.getElementById(DiD)).value, NetSuite: ExpValue, Default: DestValue, Type: this._importRetFieldMappingResult[i].Type, fieldType: 'Standard', DataType: DataType })
      }
    }
    var ReturnsImport = { "Account": (<HTMLInputElement>document.getElementById("txt_RetAccount")).value, "Adjustment": (<HTMLInputElement>document.getElementById("txt_RetAdjustment")).value, "ItemReceipt": this._itemReceipt, FieldMapping: this._updatedImportFieldMapping };
    this.connectService.Srv_PostImportFieldMapping(ReturnsImport, "Return_ImportFieldsMapping", this._connectorID).subscribe((data: any) => {

    })
  }
  UpdateRefundsImportFieldMapping() {
    this._updatedImportFieldMapping.length = 0
    for (var i = 0; i < this._importStdFieldMappingResult.length; i++) {
      var SiD = "StdSrc_" + i;
      var DiD = "StdDst_" + i;
      var DfD = "DFTStd_" + i;
      var S_e = (document.getElementById("" + SiD)) as HTMLSelectElement;
      var S_sel = S_e.selectedIndex;
      var S_opt = S_e.options[S_sel];
      var ExpValue = this.GetValueFromArray(this._marketPlaceFields, ((<HTMLInputElement>document.getElementById(DiD)).value));
      var DataType = this.GetFieldTypeFromArray(this._marketPlaceFields, ((<HTMLInputElement>document.getElementById(DiD)).value));
      var DestValue = (<HTMLInputElement>document.getElementById(DfD)).value;
      if (ExpValue != "" && (((<HTMLOptionElement>S_opt).value) != "" || DestValue != "")) {
        if ((this.NetSuiteFieldExists(this._updatedImportFieldMapping, ExpValue)) == false)
          this._updatedImportFieldMapping.push({ MarketPlace: ((<HTMLOptionElement>S_opt).value), label: (<HTMLInputElement>document.getElementById(DiD)).value, NetSuite: ExpValue, Default: DestValue, Type: this._importStdFieldMappingResult[i].Type, fieldType: 'Standard', DataType: DataType })
      }
    }
    var RefundsImport = { "Account": (<HTMLInputElement>document.getElementById("txt_RefAccount")).value, "Adjustment": (<HTMLInputElement>document.getElementById("txt_RefAdjustment")).value, FieldMapping: this._updatedImportFieldMapping };
    this.connectService.Srv_PostImportFieldMapping(RefundsImport, "Refunds_ImportFieldsMapping", this._connectorID).subscribe((data: any) => {

    })
  }
  onSubmitPopupDetails() {
    this.closeRefundPopup.nativeElement.click();
    var DfD = "DFTStd_" + this._datatypeIndexValue;
    if (this._defaultTypeValue)
      (<HTMLInputElement>document.getElementById(DfD)).value = this._defaultTypeValue;
    var arrName = this._datatypeArrname;
    arrName[this._datatypeIndexValue].Type = this._dataTypeValue;
    if (this._datatypeIndexValue == (arrName.length - 1))
      arrName.push({ NetSuite: '', MarketPlace: '', Default: '', Type: '' });
  }

  onSubmitRetPopupDetails() {
    this.CloseReturnPopup.nativeElement.click();
    var DfD = "DFTRet_" + this._datatypeRetIndexValue;
    if (this._defaultTypeRetValue)
      (<HTMLInputElement>document.getElementById(DfD)).value = this._defaultTypeRetValue;
    var arrName = this._datatypeRetArrname;
    arrName[this._datatypeRetIndexValue].Type = this._dataTypeRetValue;
    if (this._datatypeRetIndexValue == (arrName.length - 1))
      arrName.push({ NetSuite: '', MarketPlace: '', Default: '', Type: '' });
  }

  checkHidden(val) {
    if (val != "" && val != null && val != undefined) {
      if (val.toLowerCase().includes('cust'))
        return false;
      else
        return true;
    }
    else
      return true;
  }
  dataTypesPopupInitialize(Arr, index) {
    this._datatypeArrname = Arr;
    this._datatypeIndexValue = index;
    try {
      this._dataTypeValue = Arr[index].Type;
      this._defaultTypeValue = Arr[index].Default;
    }
    catch (exception) {

    }
  }

  dataTypesRetPopupInitialize(Arr, index) {
    this._datatypeRetArrname = Arr;
    this._datatypeRetIndexValue = index;
    try {
      this._dataTypeRetValue = Arr[index].Type;
      this._defaultTypeRetValue = Arr[index].Default;
    }
    catch (exception) {

    }
  }

  NetSuiteFieldExists(Arr, value) {
    if (value != "") {
      return Arr.some(function (el) {
        var status = (el.NetSuite === value);
        return status;
      });
    }
    else {
      return false;
    }
  }
  GetFieldTypeFromArray(JsonArray, Value) {
    if (Value) {
      var index = -1;
      var filteredObj = JsonArray.find(function (item, i) {
        if ((item.fieldName).toUpperCase() === Value.toUpperCase()) {
          index = i;
        }
      });
      if (index == -1) {
        return Value;
      }
      else {
        return JsonArray[index].fieldType;
      }
    }
    else {
      return "";
    }
  }
  GetValueFromArray(JsonArray, Value) {
    if (Value) {
      var index = -1;
      var filteredObj = JsonArray.find(function (item, i) {
        if ((item.fieldName).toUpperCase() === Value.toUpperCase()) {
          index = i;
        }
      });
      if (index == -1) {
        return Value;
      }
      else {
        return JsonArray[index].scriptId;
      }
    }
    else {
      return "";
    }
  }
  PostIISchedulerJSON() {
    var StandardItemImport = [];
    StandardItemImport.push({ "Date": (<HTMLInputElement>document.getElementById("_IMIS_ScheduleFrom")).value, "Frequency": this.GetSelectedValueFromId("_IMIS_Frequency"), "Time": this.GetSelectedValueFromId("_IMIS_Time"), "Days": this.GetSelectedMultipleValuesFromID("_IMIS_Days") })
    this.connectService.Srv_PostImportFieldMapping(StandardItemImport, "Refunds_ImportScheduletime", this._connectorID).subscribe((data: any) => {

    })
  }
  PostIESchedulerJSON() {
    var StandardItemImport = [];
    StandardItemImport.push({ "Date": (<HTMLInputElement>document.getElementById("_IEIS_ScheduleFrom")).value, "Frequency": this.GetSelectedValueFromId("_IEIS_Frequency"), "Time": this.GetSelectedValueFromId("_IEIS_Time"), "Days": this.GetSelectedMultipleValuesFromID("_IEIS_Days") })
    this.connectService.Srv_PostImportFieldMapping(StandardItemImport, "Refunds_ExportScheduletime", this._connectorID).subscribe((data: any) => {

    })
  }

  PostIIRetSchedulerJSON() {
    var StandardItemImport = [];
    StandardItemImport.push({ "Date": (<HTMLInputElement>document.getElementById("_IMISRet_ScheduleFrom")).value, "Frequency": this.GetSelectedValueFromId("_IMISRet_Frequency"), "Time": this.GetSelectedValueFromId("_IMISRet_Time"), "Days": this.GetSelectedMultipleValuesFromID("_IMISRet_Days") })
    this.connectService.Srv_PostImportFieldMapping(StandardItemImport, "Return_ImportScheduletime", this._connectorID).subscribe((data: any) => {

    })
  }
  PostIERetSchedulerJSON() {
    var StandardItemImport = [];
    StandardItemImport.push({ "Date": (<HTMLInputElement>document.getElementById("_IEISRet_ScheduleFrom")).value, "Frequency": this.GetSelectedValueFromId("_IEISRet_Frequency"), "Time": this.GetSelectedValueFromId("_IEISRet_Time"), "Days": this.GetSelectedMultipleValuesFromID("_IEISRet_Days") })
    this.connectService.Srv_PostImportFieldMapping(StandardItemImport, "Return_ExportScheduletime", this._connectorID).subscribe((data: any) => {

    })
  }

  UpdateFlowStatus(FlowUpdateVariable) {
    this.connectService.UpdateFlowStatusByConnector(FlowUpdateVariable, this._connectorID).subscribe((data: any) => {

    })
  }
  GetSelectedValueFromId(ID) {
    try {
      var D_e = (document.getElementById(ID)) as HTMLSelectElement;
      var D_sel = D_e.selectedIndex;
      var D_opt = D_e.options[D_sel];
      var DestValue = ((<HTMLOptionElement>D_opt).value)
      return DestValue;
    }
    catch (e) {
      return "";
    }
  }
  GetSelectedMultipleValuesFromID(ID) {
    let selectElement = (document.getElementById(ID)) as HTMLSelectElement;
    let selectedValues = Array.from(selectElement.selectedOptions).map(option => option.value);
    return ((selectedValues).toString());
  }
  CheckInArray(Arr, Value) {
    Arr = Arr.map(String);
    if (Arr.indexOf(Value.toString()) > -1) {
      return true;
    }
    return false;
  }
  RemoveDivById(divString: any, i) {
    if (divString.length - 1 == i) {

    }
    else {
      var ans = confirm('Are you sure you want to remove this field?');
      if (ans) {
        divString.splice(i, 1);
      }
    }
  }
  RunIntegrator(ApiMethodName, RunningMethodName, ProcessingName) {
    this['' + RunningMethodName] = true;
    this['' + ProcessingName] = 'Initiated...';
    setTimeout(() => {
      setTimeout(() => {
        this['' + RunningMethodName] = false;
      }, 3000)
      this['' + ProcessingName] = 'Processing...';
    }, 3000)
    this.connectService.RunIntegrator(ApiMethodName, this._connectorID).subscribe((data: any) => {
    })
  }
}
