import { ConnectorsService } from '../servicess/connectors.service';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit, ViewChild, AfterViewInit, AfterContentInit, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { NgForm } from '@angular/forms';
import { BehaviorSubject, Observable } from 'rxjs';
import { AuthservService } from '../servicess/authserv.service';

@Component({
  selector: 'app-acumatica-credentials',
  templateUrl: './acumatica-credentials.component.html',
  styleUrls: ['./acumatica-credentials.component.css']
})
export class AcumaticaCredentialsComponent implements OnInit {
  time_Data: Array<any> = [];
  id: any;
  submitted: boolean = false;
  Message: any;
  currentTime: any;
  time_parameter: any;
  _JsonBodyData: any;
  @ViewChild('f') indexForm: NgForm;
  constructor(private authserve: AuthservService, private http: HttpClient, private connectorService: ConnectorsService, private Router: Router, private activatedRoute: ActivatedRoute) {

  }

  ngOnInit() {
    this.connectorService.GetTimeZones().subscribe((data: any) => {
      if (data.status == "Success") {
        this.time_Data = data.results;
      }
    });
    this.GetCustomerNsDetails();
  }
  GetCustomerNsDetails() {
    this.connectorService.GetNsDetails().subscribe((data: any) => {

    }, error => {
    })
  }

  onSubmit() {
    this.Message = "";
    this.submitted = true;
    this._JsonBodyData = {
      "AC_BaseURL": this.indexForm.value.AC_BaseURL,
      "AC_Username": this.indexForm.value.AC_Username,
      "AC_Password": this.indexForm.value.AC_Password,
      "AC_ClientID": this.indexForm.value.AC_ClientID,
      "AC_ClientSecret": this.indexForm.value.AC_ClientSecret,
      "TimeZone": this.indexForm.value.timeZone,
    }
    this.connectorService.UpdateACDetailsManually(this._JsonBodyData).subscribe((data: any) => {
      if (data.status == "Success") {
        localStorage.setItem('isNSPending', 'false');
        this.Message = "Credentials Updated."
        setTimeout(() => {
          location.reload();
          this.Message = "";
        }, 3000)
      }
      else {
        this.Message = data.errorMessage;
      }
      this.indexForm.resetForm();
    }, error => {
    })
  }

}
