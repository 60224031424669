import { Component, OnInit, EventEmitter, Output, Inject, ViewChild } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MapDataComponent } from '../map-data/map-data.component';
import { HttpClient } from '@angular/common/http';
import { ConnectorsService } from '../servicess/connectors.service';
import { NgForm } from '@angular/forms';
@Component({
  selector: 'app-subscription',
  templateUrl: './subscription.component.html',
  styleUrls: ['./subscription.component.css']
})
export class SubscriptionComponent implements OnInit {

  constructor(private router: ActivatedRoute, public dialog: MatDialog, private route: Router, private http: HttpClient, private connectSer: ConnectorsService) { }
  _connectorsList: Array<any> = [];
  @ViewChild('f') indexForm: NgForm;
  _message: any = "";
  chkActivatedLicense: any = false;
  chkItemImport: any = false;
  chkItemeExport: any = false;
  chkPriceImport: any = false;
  chkPriceExport: any = false;
  chkInvenImport: any = false;
  chkInveneExport: any = false;
  chkImageImport: any = false;
  chkImageExport: any = false;
  chkOrdersImport: any = false;
  chkOrdersExport: any = false;
  chkBillingImport: any = false;
  chkBillingExport: any = false;
  chkShipmentsImport: any = false;
  chkShipmentsExport: any = false;
  chkCancellationImport: any = false;
  chkCancellationExport: any = false;
  chkRefundsImport: any = false;
  chkRefundsExport: any = false;
  chkReturnsImport: any = false;
  chkReturnsExport: any = false;
  chkSubsidaryExport: any = false;
  chkCustomerExport: any = false;
  chkEmployeeExport: any = false;

  _connectorName: any = '';
  _selectedERP: any = '';
  _SubscriptionType: any = '';
  message: any;
  _description: any;
  _arr_ErpRecords: Array<any> = [];
  ngOnInit() {
    this.GetERPRecords();
  }

  GetERPRecords() {
    this.connectSer.GetERPRecords().subscribe((data: any) => {
      if (data.status != "Error") {
        this._arr_ErpRecords = data.results;
      }
    })
  }
  GetConnectorsByERP() {
    var ERP = this.indexForm.value.ddlERPType;
    this.connectSer.GetConnectorNamesList(ERP).subscribe((data: any) => {
      if (data.status == "Success") {
        this._connectorsList = data.results;
      }
    }, error => {

    })
  }
  GetSubscriptionPlan() {
    this.ResetCheckBoxesAndDesc();
    if (this.indexForm.value.ddlConnector != '' && this.indexForm.value.txtSubscriptionType != '') {
      var Connector = this.indexForm.value.ddlConnector;
      var Subscription = this.indexForm.value.txtSubscriptionType;
      var erpType = this.indexForm.value.ddlERPType;
      this.connectSer.GetSubscriptionPlan(Connector, Subscription, erpType).subscribe((data: any) => {
        if (data.status == "Success") {
          this._connectorName = data.results[0].fK_ConnectorID;
          this._description = data.results[0].description;
          this._SubscriptionType = data.results[0].subscriptionType;
          this.chkActivatedLicense = data.results[0].needActivationKey;
          this.chkItemImport = data.results[0].item_Import;
          this.chkItemeExport = data.results[0].item_Export;
          this.chkPriceImport = data.results[0].price_Import;
          this.chkPriceExport = data.results[0].price_Export;
          this.chkInvenImport = data.results[0].inventory_Import;
          this.chkInveneExport = data.results[0].inventory_Export;
          this.chkImageImport = data.results[0].image_Import;
          this.chkImageExport = data.results[0].image_Export;
          this.chkOrdersImport = data.results[0].orders_Import;
          this.chkOrdersExport = data.results[0].orders_Export;
          this.chkBillingImport = data.results[0].billing_Import;
          this.chkBillingExport = data.results[0].billing_Export;
          this.chkShipmentsImport = data.results[0].shipments_Import;
          this.chkShipmentsExport = data.results[0].shipments_Export;
          this.chkCancellationImport = data.results[0].cancellation_Import;
          this.chkCancellationExport = data.results[0].cancellation_Export;
          this.chkRefundsImport = data.results[0].refunds_Import;
          this.chkRefundsExport = data.results[0].refunds_Export;
          this.chkReturnsImport = data.results[0].returns_Import;
          this.chkReturnsExport = data.results[0].returns_Export;
          this.chkSubsidaryExport = data.results[0].subsidiary_Export;
          this.chkCustomerExport = data.results[0].customers_Export;
          this.chkEmployeeExport = data.results[0].employee_Export;
        }
      }, error => {

      })
    }
  }
  onReset() {
    this.indexForm.resetForm();
    this.ResetCheckBoxesAndDesc();
  }
  ResetCheckBoxesAndDesc() {
    this._description = '';
    this.chkItemImport = false;
    this.chkItemeExport = false;
    this.chkPriceImport = false;
    this.chkPriceExport = false;
    this.chkInvenImport = false;
    this.chkInveneExport = false;
    this.chkImageImport = false;
    this.chkImageExport = false;
    this.chkOrdersImport = false;
    this.chkOrdersExport = false;
    this.chkBillingImport = false;
    this.chkBillingExport = false;
    this.chkShipmentsImport = false;
    this.chkShipmentsExport = false;
    this.chkCancellationImport = false;
    this.chkCancellationExport = false;
    this.chkRefundsImport = false;
    this.chkRefundsExport = false;
    this.chkReturnsImport = false;
    this.chkReturnsExport = false;
    this.chkSubsidaryExport = false;
    this.chkCustomerExport = false;
    this.chkEmployeeExport = false;
  }
  onSubmit() {
    var JSONBody = {
      Fk_ERPID: this.indexForm.value.ddlERPType,
      FK_ConnectorID: this.indexForm.value.ddlConnector,
      SubscriptionType: this.indexForm.value.txtSubscriptionType,
      Description: this.indexForm.value.txt_Description,
      NeedActivationKey: this.chkActivatedLicense,
      Item_Import: this.chkItemImport,
      Item_Export: this.chkItemeExport,
      Inventory_Import: this.chkInvenImport,
      Inventory_Export: this.chkInveneExport,
      Price_Import: this.chkPriceImport,
      Price_Export: this.chkPriceExport,
      Image_Import: this.chkImageImport,
      Image_Export: this.chkImageExport,
      Orders_Import: this.chkOrdersImport,
      Orders_Export: this.chkOrdersExport,
      Shipments_Import: this.chkShipmentsImport,
      Shipments_Export: this.chkShipmentsExport,
      Refunds_Import: this.chkRefundsImport,
      Refunds_Export: this.chkRefundsExport,
      Returns_Import: this.chkReturnsImport,
      Returns_Export: this.chkReturnsExport,
      Billing_Import: this.chkBillingImport,
      Billing_Export: this.chkBillingExport,
      Cancellation_Import: this.chkCancellationImport,
      Cancellation_Export: this.chkCancellationExport,
      Subsidiary_Export: this.chkSubsidaryExport,
      Customers_Export: this.chkCustomerExport,
      Employee_Export: this.chkEmployeeExport
    };
    this.connectSer.PostSubscription(JSONBody).subscribe((data: any) => {
      if (data.status == "Success") {
        this._message = 'Data submitted';
      }
      else {
        this._message = data.errorMessage;
      }
    }, error => {

    })
    setTimeout(() => {
      this._message = "";
    }, 3000);
    this.onReset();
  }
}
