import { Router, ActivatedRoute, Params } from '@angular/router';
import { OnInit, Component, ViewChild } from '@angular/core';
import { ConnectorsService } from '../servicess/connectors.service';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { NgForm } from '@angular/forms';
import { BehaviorSubject, Observable } from 'rxjs';
import { AuthservService } from '../servicess/authserv.service';
import { NgxPaginationModule } from 'ngx-pagination';
@Component({
  selector: 'app-raise-quote',
  templateUrl: './raise-quote.component.html',
  styleUrls: ['./raise-quote.component.css']
})
export class RaiseQuoteComponent implements OnInit {
  constructor(private ActivatedRoute: ActivatedRoute, private authserve: AuthservService, private http: HttpClient, private connectorService: ConnectorsService, private Router: Router, private activatedRoute: ActivatedRoute) { }
  @ViewChild('f') indexForm: NgForm;
  _ConnectorData: any;
  Message: any;
  _connectorID: any;
  _logoPath: any;
  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      this._connectorID = params['Connectorid'];
      this.GetConnectorDetails(this._connectorID);
    });
  }
  ResetForm() {
    this.indexForm.resetForm();
  }
  GetConnectorDetails(ConnectorID) {
    this.connectorService.GetConnectorDetails(ConnectorID).subscribe((data: any) => {
      if (data.status == "Success") {
        this._ConnectorData = (data.results[0]);
        this._logoPath = (this._ConnectorData).logoPath;
      }
      else {

      }
    }, error => {
    })
  }
  onSubmit() {
    var _jsonBodyData = {
      ConnectorID: this._connectorID,
      CompanyName: this.indexForm.value.txtCompanyName,
      BusinessDescription: this.indexForm.value.txtBusinessDescription,
      Requirements: this.indexForm.value.txtRequirements
    }
    this.connectorService.PostRaiseQuote(_jsonBodyData).subscribe((data: any) => {
      if (data.status == "Success") {
        this.Message = "Quote request send.";
        this.ResetForm();
      }
      else {
        this.Message = data.errorMessage;
      }
    }, error => {
    })
  }
}
