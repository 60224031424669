import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MapDataComponent } from '../map-data/map-data.component';
import { HttpClient } from '@angular/common/http';
import { ConnectorsService } from '../servicess/connectors.service';
import { GlobalTypeScriptService } from '../servicess/global-type-script.service';
import { AuthservService } from '../servicess/authserv.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';


@Component({
  selector: 'app-customers',
  templateUrl: './customers.component.html',
  styleUrls: ['./customers.component.css']
})
export class CustomersComponent implements OnInit {
  displayedColumns = ['Name', 'Company', 'Email', 'Phone', 'TimeZone', 'date'];
  dataSource: MatTableDataSource<UserData>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  constructor(private authserve: AuthservService, private router: ActivatedRoute, public dialog: MatDialog, private route: Router, private http: HttpClient, private connectService: ConnectorsService, private GTS: GlobalTypeScriptService) {

  }
  ngOnInit() {
    this.GetCustomersList();
  }
  ngAfterViewInit() {

  }
  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
  }
  GetCustomersList() {
    this.connectService.GetCustomersList().subscribe((data: any) => {
      if (data.status != "Error") {
        this.dataSource = new MatTableDataSource(data.results);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      }
    },
      (err) => {
      });
  }
  OnRecordClick(row) {
    this.route.navigate(['/CustomerDetails'], { queryParams: { Customer: row } });
  }
}
export interface UserData {
  Name: string; Company: string; Email: string; Phone: string; TimeZone: string; date: string;
}