import { Component, OnInit, EventEmitter, Output, Inject } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MapDataComponent } from '../map-data/map-data.component';
import { HttpClient } from '@angular/common/http';
import { ConnectorsService } from '../servicess/connectors.service'
import { BlockUI, NgBlockUI } from 'ng-block-ui';
@Component({
  selector: 'app-billings',
  templateUrl: './billings.component.html',
  styleUrls: ['./billings.component.css']
})
export class BillingsComponent implements OnInit {

  @BlockUI() blockUI: NgBlockUI;
  constructor(private router: ActivatedRoute, public dialog: MatDialog, private route: Router, private http: HttpClient, private connectService: ConnectorsService) { }
  _billingImportSubscribed: any = false;
  _billingExportSubscribed: any = false;
  _billingImportEnabled: any = false;
  _billingExportEnabled: any = false;
  _orderInvoice: any = false;
  _orderCashSale: any = false;

  _StandFieldMappingSrcJSON: Array<any> = [];
  _StandFieldMappingDstJSON: Array<any> = [];
  _MarketPlaceFieldMapping: Array<any> = [];
  _billingImportRunning: any = false;
  _billingExportRunning: any = false;
  _importLockFieldMappingResult: Array<any> = [];
  _importStdFieldMappingResult: Array<any> = [];
  _exportLockFieldMappingResult: Array<any> = [];
  _exportStdFieldMappingResult: Array<any> = [];
  _updatedImportFieldMapping: Array<any> = [];
  _updatedExportFieldMapping: Array<any> = [];
  _EXMarketPlaceFieldMapping: Array<any> = [];
  _connectorData: any;
  _importScheduleTime: any = [{ "Date": "", "Frequency": "", "Time": "", "Days": "" }];
  _importScheduleTimeDays: any = [];

  _importInvoiceScheduleTime: any = [{ "Date": "", "Frequency": "", "Time": "", "Days": "" }];
  _importInvocieScheduleTimeDays: any = [];

  _importCashSaleScheduleTime: any = [{ "Date": "", "Frequency": "", "Time": "", "Days": "" }];
  _importCashSaleScheduleTimeDays: any = [];

  _flowNames: Array<any> = [];
  _importFlowName: any;
  _exportFlowName: any;
  _SchedulerFrequencyList: any = [];
  _SchedulerStartTimeList: any = [];
  _SchedulerDaysList: any = [];
  _ordInv_Acc: any = "";
  _ordCS_Acc: any = "";

  _payouttype: any = "";
  _payoutdebit: any = "";
  _payoutcredit: any = "";
  _payoutvendor: any = "";
  _payoutAccount: any = "";
  _payoutactive: any = false;
  _isPJournalEditable: any = true;
  _isPVendorEditable: any = true;

  _chargestype: any = "";
  _chargesdebit: any = "";
  _chargescredit: any = "";
  _chargesvendor: any = "";
  _chargesAccount: any = "";
  _chargesactive: any = false;
  _isCJornalEditable: any = true;
  _isCVendorEditable: any = true;


  _payoutProcessingName: any = "";
  _invoiceProcessingName: any = "";
  _cashSaleprocessingName: any = "";

  _payoutImportRunning: any = false;
  _invoceImportRunning: any = false;
  _cashsaleImportRunning: any = false;
  _serviceAPI: any = {
    payoutImport: "PayoutImport",
    orderinvoices: "SalesorderToInvoice",
    ordercashsale: "SalesorderToCashsale"
  };
  _connectorID: any = "";
  _marketPlaceFields: any;
  ngOnInit() {
    const parameters = window.location.href;
    var pieces = parameters.split(/[\s/]+/);
    this._connectorID = (pieces[pieces.length - 1]);
    localStorage.setItem('ActiveLiName', 'Billing');
    //this.blockUI.start('Loading...');
    this.GetMappingFeilds();
    this.connectorsList();
    this.GetSchedulerData();
    this.GetMarketPlaceFields();
  }
  GetSchedulerData() {
    var Result = this.connectService.GetSchedulerListItems();
    this._SchedulerFrequencyList = (Result[0].Frequency);
    this._SchedulerStartTimeList = (Result[0].Time);
    this._SchedulerDaysList = (Result[0].DayList);
  }
  GetMarketPlaceFields() {
    this.connectService.GetItemFieldMapping('SalesOrder', this._connectorID).subscribe((data: any) => {
      this.blockUI.stop();
      if (data.status != "Error") {
        this._marketPlaceFields = data.results.columnStandardFields;
      }
    })
  }
  GetMappingFeilds() {
    // this.connectService.GetItemFieldMapping().subscribe((data: any) => {
    //   this.blockUI.stop();
    //   this._StandFieldMappingSrcJSON = data.result.columnStandardFields;
    //   this._StandFieldMappingDstJSON = data.result.columnStandardFields;
    // })
  }
  connectorsList() {
    this.EmptyConnectorVariables();
    this.connectService.ListConnector(this._connectorID).subscribe((data: any) => {
      this._flowNames = JSON.parse(data.results[0].flowNames);
      this._importFlowName = (this._flowNames[0]).InvoicesImport;
      this._exportFlowName = (this._flowNames[0]).InvoicesExport;
      this._serviceAPI = {
        payoutImport: (data.results[0].api_Billing_Import != "" && data.results[0].api_Billing_Import != null) ? data.results[0].api_Billing_Import : "PayoutImport",
        orderinvoices: (data.results[0].api_orderinvoice != "" && data.results[0].api_orderinvoice != null) ? data.results[0].api_orderinvoice : "SalesorderToInvoice",
        ordercashsale: (data.results[0].api_ordercashsale != "" && data.results[0].api_ordercashsale != null) ? data.results[0].api_ordercashsale : "SalesorderToCashsale"
      };
      var MappingFields = JSON.parse(data.results[0].connectorFields);
      try {
        for (var k = 0; k < MappingFields.length; k++) {
          if ((MappingFields[k].RecordType.toUpperCase()) == 'ORDER') {
            this._MarketPlaceFieldMapping.push({ label: MappingFields[k].Label, value: MappingFields[k].Title })
          }
          if ((MappingFields[k].RecordType.toUpperCase()) == 'ORDER' && (MappingFields[k].IsAccessible.toUpperCase()) == "PUBLIC") {
            this._EXMarketPlaceFieldMapping.push({ label: MappingFields[k].Label, value: MappingFields[k].Title })
          }
        }
      }
      catch (ex) {

      }
      this._connectorData = data;
      if (data.results[0].s_Billing_Import == true || data.results[0].add_Billing_Import == true)
        this._billingImportSubscribed = true;
      if (data.results[0].s_Billing_Export == true || data.results[0].add_Billing_Export == true)
        this._billingExportSubscribed = true;

      if (data.results[0].billing_Import == true)
        this._billingImportEnabled = true;
      if (data.results[0].billing_Export == true)
        this._billingExportEnabled = true;
      if (data.results[0].orderInvoice == true)
        this._orderInvoice = true;
      if (data.results[0].cashSale == true)
        this._orderCashSale = true;
      if (this._connectorData.results[0].billing_ImportFieldsMapping != null && this._connectorData.results[0].billing_ImportFieldsMapping != "") {
        var FieldsMapping = JSON.parse(this._connectorData.results[0].billing_ImportFieldsMapping)
        var PayoutData = FieldsMapping[0]; var ChargesData = FieldsMapping[1];
        if (PayoutData.Type == "") {
          this._isPJournalEditable = true;
          this._isPVendorEditable = true;
          this._payoutdebit = "", this._payoutcredit = "", this._payoutvendor = ""; this._payoutAccount = "";
        }
        if (PayoutData.Type == 1) {
          this._isPJournalEditable = false;
          this._isPVendorEditable = true;
          this._payoutvendor = ""; this._payoutAccount = "";
          this._payoutdebit = PayoutData.Debit, this._payoutcredit = PayoutData.Credit;
        }
        if (PayoutData.Type == 2) {
          this._isPJournalEditable = true;
          this._isPVendorEditable = false;
          this._payoutdebit = "", this._payoutcredit = "";
          this._payoutvendor = PayoutData.Vendor, this._payoutAccount = PayoutData.Account;
        }
        this._payouttype = PayoutData.Type, this._payoutactive = PayoutData.Status;


        if (ChargesData.Type == "") {
          this._isCJornalEditable = true;
          this._isCVendorEditable = true;
          this._chargesdebit = "", this._chargescredit = "", this._chargesvendor = ""; this._chargesAccount = "";
        }
        if (ChargesData.Type == 1) {
          this._isCJornalEditable = false;
          this._isCVendorEditable = true;
          this._chargesvendor = ""; this._chargesAccount = "";
          this._chargesdebit = ChargesData.Debit, this._chargescredit = ChargesData.Credit
        }
        if (ChargesData.Type == 2) {
          this._isCJornalEditable = true;
          this._isCVendorEditable = false;
          this._chargesdebit = "", this._chargescredit = "";
          this._chargesvendor = ChargesData.Vendor, this._chargesAccount = ChargesData.Account;
        }
        this._chargestype = ChargesData.Type, this._chargesactive = ChargesData.Status;
      }

      var FieldsMapping = JSON.parse(this._connectorData.results[0].order_InvoiceFieldMapping)
      var ItemImportJsonMapping = FieldsMapping;
      for (var i = 0; i < ItemImportJsonMapping.length; i++) {
        if (ItemImportJsonMapping[i].fieldType == 'Lock')
          this._importLockFieldMappingResult.push({ MarketPlace: ItemImportJsonMapping[i].MarketPlace, NetSuite: ItemImportJsonMapping[i].NetSuite, Default: ItemImportJsonMapping[i].Default });
        if (ItemImportJsonMapping[i].fieldType == 'Standard')
          this._importStdFieldMappingResult.push({ label: ItemImportJsonMapping[i].label, MarketPlace: ItemImportJsonMapping[i].MarketPlace, NetSuite: ItemImportJsonMapping[i].NetSuite, Default: ItemImportJsonMapping[i].Default });
      }

      if (this._connectorData.results[0].billing_ImportScheduletime != null) {
        this._importScheduleTime = (JSON.parse(this._connectorData.results[0].billing_ImportScheduletime));
        this._importScheduleTimeDays = this._importScheduleTime[0].Days.split(',');
      }
      if (this._connectorData.results[0].orderInvoice_ScheduleTime != null) {
        this._importInvoiceScheduleTime = (JSON.parse(this._connectorData.results[0].orderInvoice_ScheduleTime));
        this._importInvocieScheduleTimeDays = this._importInvoiceScheduleTime[0].Days.split(',');
      }
      if (this._connectorData.results[0].cashSale_ScheduleTime != null) {
        this._importCashSaleScheduleTime = (JSON.parse(this._connectorData.results[0].cashSale_ScheduleTime));
        this._importCashSaleScheduleTimeDays = this._importCashSaleScheduleTime[0].Days.split(',');
      }
      if (this._connectorData.results[0].order_InvoiceFieldMapping != null) {
        var oIA = JSON.parse(this._connectorData.results[0].order_InvoiceFieldMapping);
        this._ordInv_Acc = oIA[0].Account;
      }
      if (this._connectorData.results[0].cashSaleFieldMapping != null) {
        var oIA = JSON.parse(this._connectorData.results[0].cashSaleFieldMapping);
        this._ordCS_Acc = oIA[0].Account;
      }
      if (this._connectorData.results[0].cashSaleFieldMapping != null && this._connectorData.results[0].cashSaleFieldMapping != "") {
        var ExFieldsMapping = JSON.parse(this._connectorData.results[0].cashSaleFieldMapping);
        var ItemExportJsonMapping = ExFieldsMapping;
        for (var i = 0; i < ItemExportJsonMapping.length; i++) {
          if (ItemExportJsonMapping[i].fieldType == 'Standard')
            this._exportStdFieldMappingResult.push({ label: ItemExportJsonMapping[i].label, NetSuite: ItemExportJsonMapping[i].NetSuite, MarketPlace: ItemExportJsonMapping[i].MarketPlace, Default: ItemExportJsonMapping[i].Default, Type: '' });
        }
      }
      this._importStdFieldMappingResult.push({ MarketPlace: '', NetSuite: '', Default: '' });
      this._exportStdFieldMappingResult.push({ NetSuite: '', MarketPlace: '', Default: '' });
    })
  }
  UpdateFlowStatus(FlowUpdateVariable) {
    this.connectService.UpdateFlowStatusByConnector(FlowUpdateVariable, this._connectorID).subscribe((data: any) => {

    })
  }
  EmptyConnectorVariables() {
    this._MarketPlaceFieldMapping.length = 0;
    this._EXMarketPlaceFieldMapping.length = 0;
    this._importLockFieldMappingResult.length = 0;
    this._importStdFieldMappingResult.length = 0;
    this._exportLockFieldMappingResult.length = 0;
    this._exportStdFieldMappingResult.length = 0;
  }
  _OnChangeStandardImportFieldMapping() {
    var index_id = (this._importStdFieldMappingResult.length) - 1;
    var SiD = "StdSrc_" + index_id;
    var DiD = "StdDst_" + index_id;
    var DfD = "DFTStd_" + index_id;
    var S_e = (document.getElementById("" + SiD)) as HTMLSelectElement;
    var S_sel = S_e.selectedIndex;
    var S_opt = S_e.options[S_sel];
    var ExpValue = (<HTMLInputElement>document.getElementById(DiD)).value;
    var DestValue = (<HTMLInputElement>document.getElementById(DfD)).value;
    if (ExpValue != "" && (((<HTMLOptionElement>S_opt).value) != "" || DestValue != "")) {
      this._importStdFieldMappingResult.push({ MarketPlace: '', NetSuite: '', Default: '' });
    }
  }

  _OnChangeStandardExportFieldMapping() {
    var index_id = (this._exportStdFieldMappingResult.length) - 1;
    var SiD = "ExStdSrc_" + index_id;
    var DiD = "ExStdDst_" + index_id;
    var DfD = "DFTStdEx_" + index_id;
    var D_e = (document.getElementById("" + DiD)) as HTMLSelectElement;
    var D_sel = D_e.selectedIndex;
    var D_opt = D_e.options[D_sel];
    var SourceValue = (<HTMLInputElement>document.getElementById(SiD)).value;
    var DestValue = ((<HTMLOptionElement>D_opt).value)
    var DefaultValue = (<HTMLInputElement>document.getElementById(DfD)).value;
    if (DestValue != "" && (SourceValue != "" || DefaultValue != "")) {
      this._exportStdFieldMappingResult.push({ NetSuite: '', MarketPlace: '', Default: '' });
    }
  }

  PostImportFieldMapping() {
    if (this._payouttype == "")
      this._payoutactive = false;
    if (this._chargestype == "")
      this._chargesactive = false;
    var Mapping = [];
    Mapping.push({ process: "Payout", Type: this._payouttype, Debit: this._payoutdebit, Credit: this._payoutcredit, Vendor: this._payoutvendor, Account: this._payoutAccount, Status: this._payoutactive })
    Mapping.push({ process: "Charges", Type: this._chargestype, Debit: this._chargesdebit, Credit: this._chargescredit, Vendor: this._chargesvendor, Account: this._chargesAccount, Status: this._chargesactive })
    this.connectService.Srv_PostImportFieldMapping(Mapping, "Billing_ImportFieldsMapping", this._connectorID).subscribe((data: any) => {

    })
  }
  PostInvoiceFieldMapping() {
    this._updatedImportFieldMapping.length = 0;
    for (var i = 0; i < this._importStdFieldMappingResult.length; i++) {
      var SiD = "StdDst_" + i;
      var DiD = "StdSrc_" + i;
      var DfD = "DFTStd_" + i;
      var D_e = (document.getElementById("" + DiD)) as HTMLSelectElement;
      var D_sel = D_e.selectedIndex;
      var D_opt = D_e.options[D_sel];
      var SourceValue = this.GetValueFromArray(this._marketPlaceFields, ((<HTMLInputElement>document.getElementById(SiD)).value));
      var DataType = this.GetFieldTypeFromArray(this._marketPlaceFields, ((<HTMLInputElement>document.getElementById(DiD)).value));
      var DestValue = ((<HTMLOptionElement>D_opt).value)
      var DefaultValue = (<HTMLInputElement>document.getElementById(DfD)).value;
      if (DestValue != "" && (SourceValue != "" || DefaultValue != "")) {
        if ((this.NetSuiteFieldExists(this._updatedImportFieldMapping, SourceValue)) == false)
          this._updatedImportFieldMapping.push({ label: (<HTMLInputElement>document.getElementById(SiD)).value, NetSuite: SourceValue, MarketPlace: DestValue, Default: DefaultValue, fieldType: 'Standard', DataType: DataType })
      }
    }
    for (var k = 0; k < this._importLockFieldMappingResult.length; k++) {
      var SiD = "SpnImpLockDes_" + k;
      var DiD = "SpnImpLockSrc_" + k;
      var DfD = "SpnImLockDef_" + k;
      var SIMLockValue = ((<HTMLInputElement>document.getElementById(SiD)).value);
      var DefIMLockValue = this.GetValueFromArray(this._marketPlaceFields, ((<HTMLInputElement>document.getElementById(DiD)).value));
      var DataType = this.GetFieldTypeFromArray(this._marketPlaceFields, ((<HTMLInputElement>document.getElementById(DiD)).value));
      var DesIMLockValue = (<HTMLInputElement>document.getElementById(DfD)).innerHTML
      this._updatedImportFieldMapping.push({ NetSuite: SIMLockValue, label: ((<HTMLInputElement>document.getElementById(DiD)).value), MarketPlace: DefIMLockValue, Default: DesIMLockValue, fieldType: 'Lock', DataType: DataType })
    }
    this.connectService.Srv_PostImportFieldMapping(this._updatedImportFieldMapping, "orderinvoice_fieldmapping", this._connectorID).subscribe((data: any) => {
      this.connectorsList();
    })
  }


  PostExportFieldMapping() {
    this._updatedExportFieldMapping.length = 0;
    for (var i = 0; i < this._exportStdFieldMappingResult.length; i++) {
      var SiD = "ExStdSrc_" + i;
      var DiD = "ExStdDst_" + i;
      var DfD = "DFTStdEx_" + i;
      var D_e = (document.getElementById("" + DiD)) as HTMLSelectElement;
      var D_sel = D_e.selectedIndex;
      var D_opt = D_e.options[D_sel];
      var SourceValue = this.GetValueFromArray(this._marketPlaceFields, ((<HTMLInputElement>document.getElementById(SiD)).value));
      var DataType = this.GetFieldTypeFromArray(this._marketPlaceFields, ((<HTMLInputElement>document.getElementById(SiD)).value));
      var DestValue = ((<HTMLOptionElement>D_opt).value)
      var DefaultValue = (<HTMLInputElement>document.getElementById(DfD)).value;
      if (DestValue != "" && (SourceValue != "" || DefaultValue != "")) {
        if ((this.MarketPlaceFieldExists(this._updatedExportFieldMapping, DestValue)) == false)
          this._updatedExportFieldMapping.push({ label: (<HTMLInputElement>document.getElementById(SiD)).value, NetSuite: SourceValue, MarketPlace: DestValue, Default: DefaultValue, fieldType: 'Standard', DataType: DataType })
      }
    }
    for (var k = 0; k < this._exportLockFieldMappingResult.length; k++) {
      var SiD = "SpnExpLockSrc_" + k;
      var DiD = "SpnExpLockDes_" + k;
      var DfD = "SpnExpLockDef_" + k;
      var SIMLockValue = ((<HTMLInputElement>document.getElementById(SiD)).value);
      var DefIMLockValue = this.GetValueFromArray(this._marketPlaceFields, ((<HTMLInputElement>document.getElementById(DiD)).value));
      var DesIMLockValue = (<HTMLInputElement>document.getElementById(DfD)).innerHTML
      this._updatedExportFieldMapping.push({ NetSuite: SIMLockValue, label: ((<HTMLInputElement>document.getElementById(DiD)).value), MarketPlace: DefIMLockValue, Default: DesIMLockValue, fieldType: 'Lock' })
    }
    this.connectService.Srv_PostImportFieldMapping(this._updatedExportFieldMapping, "cashsale_fieldmapping", this._connectorID).subscribe((data: any) => {
      this.connectorsList();
    })
  }
  NetSuiteFieldExists(Arr, value) {
    if (value != "") {
      return Arr.some(function (el) {
        var status = (el.NetSuite === value);
        return status;
      });
    }
    else {
      return false;
    }
  }
  GetValueFromArray(JsonArray, Value) {
    if (Value) {
      var index = -1;
      var filteredObj = JsonArray.find(function (item, i) {
        if ((item.fieldName).toUpperCase() === Value.toUpperCase()) {
          index = i;
        }
      });
      if (index == -1) {
        return Value;
      }
      else {
        return JsonArray[index].scriptId;
      }
    }
    else {
      return "";
    }
  }
  GetFieldTypeFromArray(JsonArray, Value) {
    if (Value) {
      var index = -1;
      var filteredObj = JsonArray.find(function (item, i) {
        if ((item.fieldName).toUpperCase() === Value.toUpperCase()) {
          index = i;
        }
      });
      if (index == -1) {
        return Value;
      }
      else {
        return JsonArray[index].fieldType;
      }
    }
    else {
      return "";
    }
  }
  MarketPlaceFieldExists(Arr, value) {
    if (value != "") {
      return Arr.some(function (el) {
        var status = (el.MarketPlace === value);
        return status;
      });
    }
    else {
      return false;
    }
  }
  PostOrderInvoicesFieldMapping() {
    var _invFielMapping = [];
    _invFielMapping.push({ Account: ((<HTMLInputElement>document.getElementById("txt_Acc_orderInvocies")).value) });
    this.connectService.Srv_PostImportFieldMapping(_invFielMapping, "OrderInvoice_FieldMapping", this._connectorID).subscribe((data: any) => {

    })
  }
  PostOrderCSFieldMapping() {
    var _CSFielMapping = [];
    _CSFielMapping.push({ Account: ((<HTMLInputElement>document.getElementById("txt_Acc_orderCashsale")).value) });
    this.connectService.Srv_PostImportFieldMapping(_CSFielMapping, "CashSale_FieldMapping", this._connectorID).subscribe((data: any) => {

    })
  }
  PostIISchedulerJSON() {
    var StandardItemImport = [];
    StandardItemImport.push({ "Date": (<HTMLInputElement>document.getElementById("_IMIS_ScheduleFrom")).value, "Frequency": this.GetSelectedValueFromId("_IMIS_Frequency"), "Time": this.GetSelectedValueFromId("_IMIS_Time"), "Days": this.GetSelectedMultipleValuesFromID("_IMIS_Days") })
    this.connectService.Srv_PostImportFieldMapping(StandardItemImport, "Billing_ImportScheduletime", this._connectorID).subscribe((data: any) => {

    })
  }

  PostInvoiceSchedulerJSON() {
    var StandardItemImport = [];
    StandardItemImport.push({ "Date": (<HTMLInputElement>document.getElementById("_IMIS_Inv_ScheduleFrom")).value, "Frequency": this.GetSelectedValueFromId("_IMIS_Inv_Frequency"), "Time": this.GetSelectedValueFromId("_IMIS_Inv_Time"), "Days": this.GetSelectedMultipleValuesFromID("_IMIS_Inv_Days") })
    this.connectService.Srv_PostImportFieldMapping(StandardItemImport, "OrderInvoice_ScheduleTime", this._connectorID).subscribe((data: any) => {

    })
  }

  PostcashSaleSchedulerJSON() {
    var StandardItemImport = [];
    StandardItemImport.push({ "Date": (<HTMLInputElement>document.getElementById("_IMIS_csh_ScheduleFrom")).value, "Frequency": this.GetSelectedValueFromId("_IMIS_csh_Frequency"), "Time": this.GetSelectedValueFromId("_IMIS_csh_Time"), "Days": this.GetSelectedMultipleValuesFromID("_IMIS_csh_Days") })
    this.connectService.Srv_PostImportFieldMapping(StandardItemImport, "CashSale_ScheduleTime", this._connectorID).subscribe((data: any) => {

    })
  }
  onChangePayout() {
    if (this._payouttype == "") {
      this._isPJournalEditable = true;
      this._isPVendorEditable = true;
      this._payoutdebit = "", this._payoutcredit = "", this._payoutvendor = ""; this._payoutAccount = "";
    }
    if (this._payouttype == 1) {
      this._isPJournalEditable = false;
      this._isPVendorEditable = true;
      this._payoutvendor = ""; this._payoutAccount = "";
    }
    if (this._payouttype == 2) {
      this._isPJournalEditable = true;
      this._isPVendorEditable = false;
      this._payoutdebit = "", this._payoutcredit = "";
    }
  }
  onChangeCharges() {
    if (this._chargestype == "") {
      this._isCJornalEditable = true;
      this._isCVendorEditable = true;
      this._chargesdebit = "", this._chargescredit = "", this._chargesvendor = ""; this._chargesAccount = "";
    }
    if (this._chargestype == 1) {
      this._isCJornalEditable = false;
      this._isCVendorEditable = true;
      this._chargesvendor = ""; this._chargesAccount = "";
    }
    if (this._chargestype == 2) {
      this._isCJornalEditable = true;
      this._isCVendorEditable = false;
      this._chargesdebit = "", this._chargescredit = "";
    }
  }
  GetSelectedValueFromId(ID) {
    try {
      var D_e = (document.getElementById(ID)) as HTMLSelectElement;
      var D_sel = D_e.selectedIndex;
      var D_opt = D_e.options[D_sel];
      var DestValue = ((<HTMLOptionElement>D_opt).value)
      return DestValue;
    }
    catch (e) {
      return "";
    }
  }
  GetSelectedMultipleValuesFromID(ID) {
    let selectElement = (document.getElementById(ID)) as HTMLSelectElement;
    let selectedValues = Array.from(selectElement.selectedOptions).map(option => option.value);
    return ((selectedValues).toString());
  }
  RemoveDivById(divString: any, i) {
    if (divString.length - 1 == i) {

    }
    else {
      var ans = confirm('Are you sure you want to remove this field?');
      if (ans) {
        divString.splice(i, 1);
      }
    }
  }
  RunIntegrator(ApiMethodName, RunningMethodName, ProcessingName) {
    this['' + RunningMethodName] = true;
    this['' + ProcessingName] = 'Initiated...';
    setTimeout(() => {
      setTimeout(() => {
        this['' + RunningMethodName] = false;
      }, 3000)
      this['' + ProcessingName] = 'Processing...';
    }, 3000)
    this.connectService.RunIntegrator(ApiMethodName, this._connectorID).subscribe((data: any) => {

    })
  }
  CheckInArray(Arr, Value) {
    Arr = Arr.map(String);
    if (Arr.indexOf(Value.toString()) > -1) {
      return true;
    }
    return false;
  }
}