import { Component, OnInit, EventEmitter, Output, Inject } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MapDataComponent } from '../map-data/map-data.component';
import { HttpClient } from '@angular/common/http';
import { ConnectorsService } from '../servicess/connectors.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
@Component({
  selector: 'app-inventory',
  templateUrl: './inventory.component.html',
  styleUrls: ['./inventory.component.css']
})
export class InventoryComponent implements OnInit {
  @BlockUI() blockUI: NgBlockUI;
  constructor(private router: ActivatedRoute, public dialog: MatDialog, private route: Router, private http: HttpClient, private connectService: ConnectorsService) { }
  _inventoryImportSubscribed: any = false;
  _inventoryExportSubscribed: any = false;
  _inventoryImportEnabled: any = false;
  _inventoryExportEnabled: any = false;

  _StandFieldMappingSrcJSON: Array<any> = [];
  _StandFieldMappingDstJSON: Array<any> = [];
  _MarketPlaceFieldMapping: Array<any> = [];
  _inventoryImportRunning: any = false;
  _inventoryExportRunning: any = false;
  _importStdFieldMappingResult: any = { SavedSearch: "", Inventorylocation: "", InventoryUpdate: "", BufferInventory: "" };
  _exportStdFieldMappingResult: any = { SavedSearch: "", Inventorylocation: "", InventoryUpdate: "", BufferInventory: "", MaxInventory: "", LocationMapping: "" };

  _connectorData: any;
  _flowNames: Array<any> = [];
  _importFlowName: any;
  _exportFlowName: any;

  _InventoryLocactions: any;
  _InventorySavedSearch: any;
  _SchedulerFrequencyList: any;
  _SchedulerStartTimeList: any;
  _SchedulerDaysList: any;

  _Inventory_ImportSchedule: any = { Date: "", Frequency: "", Time: "", Days: "" }
  _Inventory_ExportSchedule: any = [{ Date: "", Frequency: "", Time: "", Days: "" }]

  _importScheduleDays: any;
  _exportScheduleDays: any;
  _invMultiLocaions: any;
  _Access_ExpItems: any = false;
  _locationMapping: Array<any> = [];
  _isShopify: any = false;
  _locationsBysubsidary: Array<any> = [];

  _importProcessingName: any = ''; _exportProcessingName: any = '';
  _serviceAPI: any = { inventoryImport: "", inventoryExport: "InventoryExport" };
  _connectorID: any = "";
  _erpType: any = localStorage.getItem('erptype');
  _erpName: any = localStorage.getItem('erpname');
  ngOnInit() {
    const parameters = window.location.href;
    var pieces = parameters.split(/[\s/]+/);
    this._connectorID = (pieces[pieces.length - 1]);
    localStorage.setItem('ActiveLiName', 'Inventory');
    if ((localStorage.getItem('SelectedconnectorName')).toLowerCase() == 'shopify')
      this._isShopify = true;
    this.connectorsList()
    if (this._erpType == 1) {
      this.GetAdditionalInfo();
      this.GetLocationsBySubsidary();
    }
    else if (this._erpType == 2) {
      this.GetAcumaticaLocaitons();
    }
    this.GetSchedulerData();
  }
  GetAcumaticaLocaitons() {
    this.connectService.GetAcumaticalocations(this._connectorID).subscribe((data: any) => {
      if (data.status != "Error") {
        var AcuLocationJSON: Array<any> = [];
        var ParsedLocations = JSON.parse(data.results);
        for (var i = 0; i < ParsedLocations.length; i++) {
          var WarehouseID = ParsedLocations[i].WarehouseID.value;
          var loc_json = { "internalid": ParsedLocations[i].WarehouseID.value, "name": ParsedLocations[i].Description.value }
          AcuLocationJSON.push(loc_json)
        }
        this._InventoryLocactions = AcuLocationJSON;
      }
    })
  }
  GetLocationsBySubsidary() {
    this.connectService.Getlocationsbysubsidary(this._connectorID).subscribe((data: any) => {
      if (data.status != "Error") {
        this._InventoryLocactions = data.results;
      }
    })
  }
  GetAdditionalInfo() {
    this.connectService.GetInventoryAddInfo().subscribe((data: any) => {
      this._InventorySavedSearch = data.results.itemSavedSearches;
    })
  }
  GetSchedulerData() {
    var Result = this.connectService.GetSchedulerListItems();
    this._SchedulerFrequencyList = (Result[0].Frequency);
    this._SchedulerStartTimeList = (Result[0].Time);
    this._SchedulerDaysList = (Result[0].DayList);
  }
  connectorsList() {
    this.connectService.ListConnector(this._connectorID).subscribe((data: any) => {
      try {
        var MappingFields = JSON.parse(data.results[0].connectorFields);
        for (var k = 0; k < MappingFields.length; k++) {
          if ((MappingFields[k].RecordType.toUpperCase()) == 'ITEM') {
            this._MarketPlaceFieldMapping.push(MappingFields[k].Title)
          }
        }
      }
      catch (e) {

      }

      this._flowNames = JSON.parse(data.results[0].flowNames);
      this._importFlowName = (this._flowNames[0]).InventoryImport;
      this._exportFlowName = (this._flowNames[0]).InventoryExport;
      this._serviceAPI = {
        inventoryImport: (data.results[0].api_Inventory_Import != "" && data.results[0].api_Inventory_Import != null) ? data.results[0].api_Inventory_Import : "",
        inventoryExport: (data.results[0].api_Inventory_Export != "" && data.results[0].api_Inventory_Export != null) ? data.results[0].api_Inventory_Export : "InventoryExport"
      };
      this._connectorData = data;
      if (data.results[0].s_Inventory_Import == true || data.results[0].add_Inventory_Import == true)
        this._inventoryImportSubscribed = true;
      if (data.results[0].s_Inventory_Export == true || data.results[0].add_Inventory_Export == true)
        this._inventoryExportSubscribed = true;
      if (data.results[0].inventory_Import == true)
        this._inventoryImportEnabled = true;
      if (data.results[0].inventory_Export == true)
        this._inventoryExportEnabled = true;
      this._importStdFieldMappingResult = { SavedSearch: "", Inventorylocation: "", InventoryUpdate: "", BufferInventory: "" };
      this._exportStdFieldMappingResult = { SavedSearch: "", Inventorylocation: "", InventoryUpdate: "", BufferInventory: "", MaxInventory: "", LocationMapping: "" };
      if (this._connectorData.results[0].inventory_ImportFieldsMapping != null && this._connectorData.results[0].inventory_ImportFieldsMapping != "")
        this._importStdFieldMappingResult = JSON.parse(this._connectorData.results[0].inventory_ImportFieldsMapping);
      if (this._connectorData.results[0].inventory_ExportFieldsMapping != null && this._connectorData.results[0].inventory_ExportFieldsMapping != "")
        this._exportStdFieldMappingResult = JSON.parse(this._connectorData.results[0].inventory_ExportFieldsMapping);
      if (this._exportStdFieldMappingResult.SavedSearch != null && this._exportStdFieldMappingResult.SavedSearch != "") {
        this._Access_ExpItems = true;
        this._exportStdFieldMappingResult.Inventorylocation = "";
        this._exportStdFieldMappingResult.InventoryUpdate = "";
      }
      else {
        this._invMultiLocaions = (this._exportStdFieldMappingResult.Inventorylocation).split(',');
        if (this._isShopify) {
          if (this._exportStdFieldMappingResult.LocationMapping != null && this._exportStdFieldMappingResult.LocationMapping != "" && this._exportStdFieldMappingResult.LocationMapping != undefined)
            this._locationMapping = (this._exportStdFieldMappingResult.LocationMapping);
        }
      }
      this._Inventory_ImportSchedule = { Date: "", Frequency: "", Time: "", Days: "" }
      this._Inventory_ExportSchedule = [{ Date: "", Frequency: "", Time: "", Days: "" }]
      if (this._connectorData.results[0].inventory_ImportScheduletime != null && this._connectorData.results[0].inventory_ImportScheduletime != "")
        this._Inventory_ImportSchedule = JSON.parse(this._connectorData.results[0].inventory_ImportScheduletime);
      if (this._connectorData.results[0].inventory_ExportScheduletime != null && this._connectorData.results[0].inventory_ExportScheduletime != "")
        this._Inventory_ExportSchedule = JSON.parse(this._connectorData.results[0].inventory_ExportScheduletime);
      this._importScheduleDays = this._Inventory_ImportSchedule.Days.split(',');
      this._exportScheduleDays = this._Inventory_ExportSchedule[0].Days.split(',');
    })
  }
  OnChangeIESavedSearch() {
    var SS_Value = this.GetSelectedValueFromId("_ddlinventorySavedSearch");
    if (SS_Value != null && SS_Value != "") {
      this._exportStdFieldMappingResult.Inventorylocation = "";
      this._exportStdFieldMappingResult.InventoryUpdate = "";
      this._Access_ExpItems = true;
    }
    else {
      this._Access_ExpItems = false;
    }
  }
  UpdateInventoryImportFieldMapping() {
    var FieldMappingJson = { Inventorylocation: this.GetSelectedValueFromId("_ddlLocationimport"), InventoryUpdate: this.GetSelectedValueFromId("_ddlUpdateimport"), BufferInventory: '' };
    this.connectService.Srv_PostImportFieldMapping(FieldMappingJson, "inventory_importfieldsmapping", this._connectorID).subscribe((data: any) => {

    })
  }
  UpdateInventoryExportFieldMapping() {
    for (var i = 0; i < this._locationMapping.length; i++) {
      try {
        var id = 'txtlocVal_' + i;
        var val = (<HTMLInputElement>document.getElementById(id)).value;
        this._locationMapping[i].ShopifyLocationId = val;
      }
      catch (Ex) {

      }
    }
    var FieldMappingJson = {
      SavedSearch: this.GetSelectedValueFromId("_ddlinventorySavedSearch"),
      Inventorylocation: this.GetSelectedMultipleValuesFromID("_ddlLocationexport"),
      InventoryUpdate: this.GetSelectedValueFromId("_ddlUpdateExport"),
      BufferInventory: (<HTMLInputElement>document.getElementById("txt_Exbuffer")).value,
      MaxInventory: (<HTMLInputElement>document.getElementById("txt_ExMaxInv")).value,
      LocationMapping: this._locationMapping
    };
    this.connectService.Srv_PostImportFieldMapping(FieldMappingJson, "inventory_exportfieldsmapping", this._connectorID).subscribe((data: any) => {

    })
  }
  UpdateInventoryImportScheduleTime() {
    var Schedule = { Date: (<HTMLInputElement>document.getElementById("_II_ScheduleFrom")).value, Frequency: this.GetSelectedValueFromId("_II_Frequency"), Time: this.GetSelectedValueFromId("_II_Time"), Days: this.GetSelectedMultipleValuesFromID("_II_Days") };
    this.connectService.Srv_PostImportFieldMapping(Schedule, "Inventory_ImportScheduletime", this._connectorID).subscribe((data: any) => {

    })
  }
  UpdateInventoryExportScheduleTime() {
    var Schedule = [{ Date: (<HTMLInputElement>document.getElementById("_IE_ScheduleFrom")).value, Frequency: this.GetSelectedValueFromId("_IE_Frequency"), Time: this.GetSelectedValueFromId("_IE_Time"), Days: this.GetSelectedMultipleValuesFromID("_IE_Days") }];
    this.connectService.Srv_PostImportFieldMapping(Schedule, "Inventory_ExportScheduletime", this._connectorID).subscribe((data: any) => {

    })
  }
  GetSelectedValueFromId(ID) {
    try {
      var D_e = (document.getElementById(ID)) as HTMLSelectElement;
      var D_sel = D_e.selectedIndex;
      var D_opt = D_e.options[D_sel];
      var DestValue = ((<HTMLOptionElement>D_opt).value)
      return DestValue;
    }
    catch (e) {
      return "";
    }
  }
  GetSelectedMultipleValuesFromID(ID) {
    let selectElement = (document.getElementById(ID)) as HTMLSelectElement;
    let selectedValues = Array.from(selectElement.selectedOptions).map(option => option.value);
    return ((selectedValues).toString());
  }
  CheckInArray(Arr, Value) {
    if (Arr) {
      Arr = Arr.map(String);
      if (Arr.indexOf(Value.toString()) > -1) {
        return true;
      }
      return false;
    }
  }

  onOptionsSelected() {
    this._locationMapping = [];
    let selectElement = (document.getElementById('_ddlLocationexport')) as HTMLSelectElement;
    let selectedValues = Array.from(selectElement.selectedOptions).map(option => option.value);
    let selectedText = Array.from(selectElement.selectedOptions).map(option => option.text);
    for (var i = 0; i < selectedValues.length; i++) {
      this._locationMapping.push({ Display: selectedText[i], LocationId: selectedValues[i], ShopifyLocationId: '' })
    }
  }
  RunIntegrator(ApiMethodName, RunningMethodName, ProcessingName) {
    this['' + RunningMethodName] = true;
    this['' + ProcessingName] = 'Initiated...';
    setTimeout(() => {
      setTimeout(() => {
        this['' + RunningMethodName] = false;
      }, 3000)
      this['' + ProcessingName] = 'Processing...';
    }, 3000)
    this.connectService.RunIntegrator(ApiMethodName, this._connectorID).subscribe((data: any) => {

    })
  }
  UpdateFlowStatus(FlowUpdateVariable) {
    this.connectService.UpdateFlowStatusByConnector(FlowUpdateVariable, this._connectorID).subscribe((data: any) => {

    })
  }
}
