import { Component, OnInit, ViewChild } from '@angular/core';
import { ConnectorsService } from '../../servicess/connectors.service';
import { MatMenuTrigger } from '@angular/material';
import { AuthservService } from '../../servicess/authserv.service';
import { Router } from "@angular/router";
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;
  constructor(public auth: AuthservService, private route: Router, private connectorService: ConnectorsService,) { }
  _imgurl: any = localStorage.getItem('imageUrl');
  _profileName: any = localStorage.getItem('loggedName');
  _isAdmin: any = false;
  _NsPending: any = false;
  _multilogindetails: any;
  _ismultilogins: any = false;
  _comapanyName: any = "";
  ngOnInit() {
    if (localStorage.getItem('LoginMultiDetails') != null) {
      this._multilogindetails = JSON.parse(localStorage.getItem('LoginMultiDetails'));
      this._comapanyName = localStorage.getItem('company');
      if (this._multilogindetails.length > 1) {
        this._ismultilogins = true;
      }
    }

    var Role = localStorage.getItem('LoggedRole');
    if ((localStorage.getItem('isNSPending')).toString() == 'true')
      this._NsPending = true;
    if (parseInt(Role) == 2) {
      this._isAdmin = true;
    }

  }
  ClosePending() {
    this._NsPending = false;
    localStorage.setItem('isNSPending', 'false');
  }
  refreshCustomerID(j: any) {
    localStorage.setItem('customerID', this._multilogindetails[j].customerID);
    localStorage.setItem('company', this._multilogindetails[j].companyName);
    location.reload();
    this.route.navigate(['/dashBoard']);
  }
  verifyLogOut() {

  }

  logoutUser() {
    localStorage.removeItem('accessToken');
    localStorage.clear();
    this.route.navigate(['/home'])
  }
}