import { ConnectorsService } from '../servicess/connectors.service';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit, ViewChild, AfterViewInit, AfterContentInit, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { NgForm } from '@angular/forms';
import { BehaviorSubject, Observable } from 'rxjs';
import { AuthservService } from '../servicess/authserv.service';
@Component({
  selector: 'app-access-keys',
  templateUrl: './access-keys.component.html',
  styleUrls: ['./access-keys.component.css']
})
export class AccessKeysComponent implements OnInit {
  id: any;
  submitted: boolean = false;
  Message: any;
  currentTime: any;
  time_Data: Array<any> = [];
  time_parameter: any;
  _JsonBodyData: any;
  nS_Account: any; nS_AppId: any; nS_ConsumerKey: any; nS_ConsumerSecret: any; nS_TokenId: any; nS_TokenSecret: any;
  @ViewChild('f') indexForm: NgForm;
  constructor(private authserve: AuthservService, private http: HttpClient, private connectorService: ConnectorsService, private Router: Router, private activatedRoute: ActivatedRoute) {

  }
  ngOnInit() {
    this.connectorService.GetTimeZones().subscribe((data: any) => {
      if (data.status == "Success") {
        this.time_Data = data.results;
      }
    });
    this.GetCustomerNsDetails();
  }
  GetCustomerNsDetails() {
    this.connectorService.GetNsDetails().subscribe((data: any) => {

    }, error => {
    })
  }
  onSubmit() {
    this.Message = "";
    this.submitted = true;
    this._JsonBodyData = {
      "NS_Account": this.indexForm.value.NS_Account,
      "NS_AppID": this.indexForm.value.NS_AppID,
      "NS_ConsumerKey": this.indexForm.value.NS_ConsumerID,
      "NS_ConsumerSecret": this.indexForm.value.NS_ConsumerSecret,
      "NS_TokenID": this.indexForm.value.NS_TokenID,
      "NS_TokenSecret": this.indexForm.value.NS_TokenSecret,
      "TimeZone": this.indexForm.value.timeZone,
    }
    this.connectorService.UpdateNsDetailsManually(this._JsonBodyData).subscribe((data: any) => {
      if (data.status == "Success") {
        localStorage.setItem('isNSPending', 'false');
        this.Message = "Credentials Updated."
        setTimeout(() => {
          location.reload();
          this.Message = "";
        }, 3000)
      }
      else {
        this.Message = data.errorMessage;
      }
      this.indexForm.resetForm();
    }, error => {
    })
  }
}
