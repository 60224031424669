import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthservService } from '../servicess/authserv.service';
import { ReactiveFormsModule } from '@angular/forms';
import { FormControl, FormGroup, Validators, FormArray } from '@angular/forms';
import { FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-connectorspopup',
  templateUrl: './connectorspopup.component.html',
  styleUrls: ['./connectorspopup.component.css']
})
export class ConnectorspopupComponent implements OnInit {

  constructor(public authserve: AuthservService) { }
  public store: any = [];

  ngOnInit() {
    // this.connectors() 
  }
  save() {

  }

  close: boolean = false;

  fun1(data: any) {

  }
  get() {
    this.close = true;
  }

}
