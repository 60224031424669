import { Component, OnInit, EventEmitter, Output, Inject, ViewChild } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MapDataComponent } from '../map-data/map-data.component';
import { HttpClient } from '@angular/common/http';
import { ConnectorsService } from '../servicess/connectors.service';
import { NgForm } from '@angular/forms';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
//import { FormArray, FormBuilder, FormGroup, Validators,ValidatorFn,AbstractControl, FormControl } from '@angular/forms';
import { FormBuilder, FormGroup, Validators, FormArray, FormControl } from "@angular/forms";
@Component({
  selector: 'app-general',
  templateUrl: './general.component.html',
  styleUrls: ['./general.component.css']
})
export class GeneralComponent implements OnInit {
  @BlockUI() blockUI: NgBlockUI;
  userlist: Array<any> = [];
  constructor(private router: ActivatedRoute, public dialog: MatDialog, private route: Router, private http: HttpClient, private connectSer: ConnectorsService, private fb: FormBuilder) {
  }
  myForm: FormGroup;
  submitted = false;
  items: FormArray;
  _isEnabledMainDiv: any = false;
  @ViewChild('f') indexForm: NgForm;
  isenabled: any = true;
  san: any = "";
  id: any;
  store: Array<any> = [];
  info: any;
  credentialshead: Array<any> = [];
  postCredentailResults: any;
  credential: any;
  credentialDisplayHeaders: any;
  credential1: any;
  UpdateIOStatusRes: any;
  updateisenabled: any;
  JSonCredentialsData: Array<any> = [];
  msg: any = "hello";
  _isSubmitEnabled: any = false;
  _AccountName: any;
  _SubsidariesList: Array<any> = [];
  _selectedsubsidary: any;
  _selectedDeveloperID: any;
  _isAmazon: any = false;
  _isNetSuite: any = false;
  _isAcumatica: any = false;
  _developerlist: Array<any> = [];
  _connectorID: any = "";
  _erpType: any = localStorage.getItem('erptype');
  _Tenant: any = "";
  _needActivationkey: any = "";
  _DRLicenseKey: any = "";
  ngOnInit() {
    const parameters = window.location.href;
    var pieces = parameters.split(/[\s/]+/);
    this._connectorID = (pieces[pieces.length - 1]);
    switch ((localStorage.getItem('SelectedconnectorName')).toLowerCase()) {
      case 'amazon':
        {
          this._isAmazon = true;
          break;
        }
      default: {
        break;
      }
    }
    this._needActivationkey = localStorage.getItem('needActivationkey');
    this.blockUI.start('Loading...');
    localStorage.setItem('ActiveLiName', 'General');
    this.connectorsList();
    this.blockUI.stop();
    if (this._erpType == 1 && this._needActivationkey == 'true') {
      this._isNetSuite = true;
      this.GetSubsidaries();
    }
    if (this._erpType == 2) {
      this._isAcumatica = true;
    }
    this.GetDeveloperIDS();
    this.myForm = this.fb.group({
      credentials: this.fb.array([]),
    })
  }
  getKeyValue(event: any, i) {
    this.JSonCredentialsData[i].Value = event.target.value;
    this._isSubmitEnabled = false;
    this.message = '';
  }
  GetDeveloperIDS() {
    this.connectSer.GetDeveloperIDS().subscribe((data: any) => {
      for (var i = 0; i < data.results.length; i++) {
        this._developerlist.push({ id: data.results[i].developerid, text: data.results[i].developerid })
      }
    })
  }
  GetSubsidaries() {
    this.connectSer.GetSubsidrieslIst().subscribe((data: any) => {
      this.blockUI.stop();
      var ParentList = [], ChildList = [];
      var resList = data.results;
      for (var x = 0; x < resList.length; x++) {
        if (resList[x].parent == null) {
          this._SubsidariesList.push({ internalId: resList[x].internalId, name: resList[x].name })
        }
      }
      for (var y = 0; y < resList.length; y++) {
        if (resList[y].parent) {
          this._SubsidariesList.push({ internalId: resList[y].internalId, name: resList[y].parent.name + ':' + resList[y].name })
        }
      }
    })
  }
  connectorsList() {
    this.id = +this.router.snapshot.paramMap.get('id');
    this.connectSer.ListConnector(this.id).subscribe((data: any) => {
      this._Tenant = data.results[0].tenant;
      this._AccountName = data.results[0].accountName;
      this._selectedsubsidary = data.results[0].subsidary;
      if (this._isAmazon) {
        this._selectedDeveloperID = data.results[0].develoeprid;
      }
      if (data.results.length >= 0) {
        this.store = data;
        this.info = data.results[0].credentialAuthJson;
        if (this.info != null && this.info != "") {
          var _credJson = JSON.parse(this.info);
        }
        this.credentialshead = data;
        if (data.results[0].isEnabled != true)
          this.isenabled = false;
        this.credential = data.results[0].credentialsHeaders.split(',');
        this.credentialDisplayHeaders = data.results[0].credentialHeadersDisplay.split(',');

        this.JSonCredentialsData = [];
        this.credential.forEach((item, i) => {
          this.JSonCredentialsData.push({ key: this.credential[i], Value: "", DisplayName: this.credentialDisplayHeaders[i], Subsidary: "" });
        })

        if (this.info != null && this.info != "") {
          this.JSonCredentialsData = [];
          JSON.parse(this.info).forEach((item, j) => {
            var lastFive = (_credJson[j].Value), credJson = "";

            // lastFive = (_credJson[j].Value).substr((_credJson[j].Value).length - 5);
            // credJson = (_credJson[j].Value).replace(/./g, '*');
            var _strvalue = credJson + lastFive;
            this.JSonCredentialsData.push({ key: _credJson[j].key, Value: _strvalue, DisplayName: _credJson[j].DisplayName });
          })
        }
      }
      this._isEnabledMainDiv = true;
    })
  }

  onSubmit() {
    this.blockUI.start('Loading...');
    var AccountName = this.indexForm.value.txtAccountName;
    var Subsidary = null; var Tenant = null;
    if (this._isNetSuite == true) {
      Subsidary = this.GetSelectedValueFromId("subID");
    }
    if (this._isAcumatica == true) {
      Tenant = <HTMLInputElement>document.getElementById("txt_TenantID");
      Tenant = Tenant.value;
    }
    this.connectSer.postCredentails(this.JSonCredentialsData, AccountName, Subsidary, this._connectorID, Tenant, this._DRLicenseKey).subscribe((data) => {
      this.blockUI.stop();
      this.postCredentailResults = data;
      if (this.postCredentailResults.status == "Success") {
        if (this._isAmazon) {
          var develoeprid = this.GetSelectedValueFromId("DevloperId");
          this.connectSer.UpdatefieldbyMap(develoeprid, "AmazonDeveloperId", this._connectorID).subscribe((data: any) => {
            location.reload();
          })
        }
        else {
          location.reload();
        }
      }
    })
  }

  validate: any = "yes"
  message: any;
  connectors(data: any) {
    if (this.validate == data) {
      this.connectSer.UpdateIOStatusService(this._connectorID).subscribe((data) => {
        this.UpdateIOStatusRes = data;
        this.updateisenabled = this.UpdateIOStatusRes.results.isEnabled;
        if (this.UpdateIOStatusRes.status == "Success") {
          if (JSON.stringify(this.updateisenabled) == 'true') {
            location.reload();
          }
          else {
            this.message = "your connector is disabled successfully. Page will redirect in few seconds..!";
            setTimeout(() => {
              this.close()
            }, 2000);
          }
        }
      })
    }
  }
  close() {
    this.route.navigate(['/dashBoard']);
  }
  validate1: any = 'no';
  connectors2(data: any) {
    var element = <HTMLInputElement>document.getElementById("_chkIntegrator");
    element.checked = true;
  }
  ValidateCredentials() {
    this.blockUI.start('Loading...');
    this._isSubmitEnabled = false;
    this.message = '';
    var _credJson = [];
    for (var i = 0; i < this.JSonCredentialsData.length; i++) {
      _credJson.push({ key: this.JSonCredentialsData[i].key, Value: this.JSonCredentialsData[i].Value })
    }
    if (this._isAmazon) {
      var develoeprid = this.GetSelectedValueFromId("DevloperId");
      _credJson.push({ key: 'DeveloperId', Value: develoeprid, DisplayName: '', Subsidary: '' })
    }
    this.connectSer.TestMarketPlaceConnection(_credJson, this._connectorID).subscribe((data: any) => {
      this.blockUI.stop();
      if (data.results == true) {
        this.message = 'Credentials validated. Please click on Submit button to continue.'
        this._isSubmitEnabled = true;
        try {
          this._DRLicenseKey = data.licensekey;
        }
        catch (e) {

        }
      }
      else {
        this.message = 'Test connection failed. Please cross check the keys.';
        setTimeout(() => {
          this.message = '';
        }, 2000);
      }
    },
      (err) => { alert(err) });
  }
  GetSelectedValueFromId(ID) {
    try {
      var D_e = (document.getElementById(ID)) as HTMLSelectElement;
      var D_sel = D_e.selectedIndex;
      var D_opt = D_e.options[D_sel];
      var DestValue = ((<HTMLOptionElement>D_opt).value)
      return DestValue;
    }
    catch (e) {
      return "";
    }
  }
}