import { Component, OnInit, EventEmitter, Output, Inject, ViewChild } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MapDataComponent } from '../map-data/map-data.component';
import { HttpClient } from '@angular/common/http';
import { ConnectorsService } from '../servicess/connectors.service';
import { NgForm } from '@angular/forms';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
//import { FormArray, FormBuilder, FormGroup, Validators,ValidatorFn,AbstractControl, FormControl } from '@angular/forms';
import { FormBuilder, FormGroup, Validators, FormArray, FormControl } from "@angular/forms";

interface PeriodicElement {
  name: string;
  position: number;
  weight: number;
  symbol: string;
}

@Component({
  selector: 'app-drnet-suite-connection',
  templateUrl: './drnet-suite-connection.component.html',
  styleUrls: ['./drnet-suite-connection.component.css']
})
export class DRNetSuiteConnectionComponent implements OnInit {
  dataSourceOne: MatTableDataSource<PeriodicElement>;
  displayedColumnsOne: string[] = ['accountID', 'licenseKey', 'tokenID', 'tokenSecret', 'createddate', 'updateddate'];
  @ViewChild('TableOnePaginator') tableOnePaginator: MatPaginator;
  @ViewChild('TableOneSort') tableOneSort: MatSort;

  @BlockUI() blockUI: NgBlockUI;
  constructor(private router: ActivatedRoute, public dialog: MatDialog, private route: Router, private http: HttpClient, private connectSer: ConnectorsService, private fb: FormBuilder) {
    this.dataSourceOne = new MatTableDataSource;
  }
  _Message: any = "";
  _isValidated: any = false;

  _RestletURL: any = "";
  _AccountID: any = "";
  _ConsumerKey: any = "";
  _ConsumerSecret: any = "";
  _TokenID: any = "";
  _TokenSecret: any = "";
  _licenseKey: any = "";

  ngOnInit() {
    this.blockUI.start('Loading...');
    this.GetActiveDRNetSuiteConnections();
  }
  applyFilterOne(filterValue: string) {
    this.dataSourceOne.filter = filterValue.trim().toLowerCase();
  }
  GetActiveDRNetSuiteConnections() {
    this.connectSer.GetActiveDRNetSuiteConnections().subscribe((data: any) => {
      this.blockUI.stop();
      if (data.status == 'OK') {
        var ResultsData = (data.results);
        (ResultsData).forEach((item, j) => {
          var TokenidlastFive = (ResultsData[j].tokenID), TokensecretlastFive = (ResultsData[j].tokenSecret), credJson = "";
          if (TokenidlastFive != null && TokensecretlastFive != null) {
            TokenidlastFive = (ResultsData[j].tokenID).substr((ResultsData[j].tokenID).length - 8);
            TokensecretlastFive = (ResultsData[j].tokenSecret).substr((ResultsData[j].tokenSecret).length - 8);
            var IDvalue = '*****' + TokenidlastFive, Secretvalue = '*****' + TokensecretlastFive;
            ResultsData[j].tokenID = IDvalue, ResultsData[j].tokenSecret = Secretvalue;
            ResultsData[j].createddate = (ResultsData[j].createddate).split('T')[0];
            ResultsData[j].updateddate = (ResultsData[j].updateddate).split('T')[0];
          }
        })
        this.dataSourceOne.data = ResultsData;
        this.dataSourceOne.paginator = this.tableOnePaginator;
        this.dataSourceOne.sort = this.tableOneSort;
      }
      else {

      }
    },
      (err) => {
        this.blockUI.stop();
      });
  }
  onSubmitClick() {
    this.blockUI.start('Loading...');
    this._Message = "";

    if (this._RestletURL == "" || this._AccountID == "" || this._ConsumerKey == "" || this._ConsumerSecret == "" || this._TokenID == "" || this._TokenSecret == "") {
      this._Message = "Please fill all the details and submit the form again.";
    }
    else {
      this.connectSer.SaveDRNetSuiteCredentials(this._RestletURL, this._AccountID, this._ConsumerKey, this._ConsumerSecret, this._TokenID, this._TokenSecret, this._licenseKey).subscribe((data: any) => {
        this.blockUI.stop();
        this._Message = data.results;
        if (data.status == 'OK') {
          setTimeout(() => {
            this.blockUI.stop();
            location.reload();
          }, 2000);
        }
        else {

        }
      },
        (err) => {
          this.blockUI.stop();
        });
    }
  }

  onClickConnection() {
    this.blockUI.start('Loading...');
    this._Message = ""; this._isValidated = false;

    if (this._RestletURL == "" || this._AccountID == "" || this._ConsumerKey == "" || this._ConsumerSecret == "" || this._TokenID == "" || this._TokenSecret == "") {
      this._Message = "Please fill all the details and submit the form again.";
      this.blockUI.stop();
    }
    else {
      this.connectSer.TestNetSuiteConnection(this._RestletURL + "&method=getLicenseKey", this._AccountID, this._ConsumerKey, this._ConsumerSecret, this._TokenID, this._TokenSecret).subscribe((data: any) => {
        this.blockUI.stop();
        if (data.status == 'OK') {
          var dataResults = JSON.parse(data.results);
          this._Message = "Connection established successfully. Please submit the form.";
          this._isValidated = true;
          this._licenseKey = (JSON.parse(data.results)).message;
        }
        else {
          this._Message = data.results;
          this._isValidated = false;
        }
      },
        (err) => {
          this.blockUI.stop();
        });
    }
  }

  _OnChangeFieldInfo() {
    this._Message = ""; this._isValidated = false;
  }
  ResetAllData() {
    this._Message = ""; this._isValidated = false;
    this._RestletURL = "";
    this._AccountID = "";
    this._ConsumerKey = "";
    this._ConsumerSecret = "";
    this._TokenID = "";
    this._TokenSecret = "";
    this._licenseKey = "";
  }
}

export interface connectionslist {
  accountID: string;
  consumerKey: string;
  consumerSecret: string;
  createddate: string;
  licenseKey: string;
  restletURL: string;
  status: string;
  tokenID: string;
  tokenSecret: string;
  updateddate: string;
}
