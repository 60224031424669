import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { AuthservService } from '../servicess/authserv.service';
import { ConnectorsService } from '../servicess/connectors.service';
import { NgForm } from '@angular/forms';
import { Location } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { BlockUI, NgBlockUI } from 'ng-block-ui';

@Component({
  selector: 'app-my-profile',
  templateUrl: './my-profile.component.html',
  styleUrls: ['./my-profile.component.css']
})
export class MyProfileComponent implements OnInit {
  @BlockUI() blockUI: NgBlockUI;
  fileData: File = null;
  previewUrl: any = localStorage.getItem('imageUrl');
  fileUploadProgress: string = null;
  uploadedFilePath: string = null;

  _fullname: any;
  _company: any;
  _email: any;
  _phone: any;
  ErrorMessage: any;
  readonlyState: any = true;
  @ViewChild('f') indexForm: NgForm;
  customerid: any;
  constructor(private route: Router, private http: HttpClient, private authservice: AuthservService, private loc: Location, private connectService: ConnectorsService, ) { }
  ngOnInit() {
    this.connectService.GetUserDetails().subscribe((data: any) => {
      if (data.status == "Success") {
        if (data.results.roleID == 2)
          this.readonlyState = false;
        else
          this.readonlyState = true;
        this._fullname = data.results.fullName;
        this._company = data.results.company;
        this._email = data.results.username;
        this._phone = data.results.phone;
        this.customerid = data.results.customerID
      }
    });
  }
  fileProgress(fileInput: any) {
    this.fileData = <File>fileInput.target.files[0];
    this.preview();
  }
  preview() {
    var mimeType = this.fileData.type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }
    var reader = new FileReader();
    reader.readAsDataURL(this.fileData);
    reader.onload = (_event) => {
      this.previewUrl = reader.result;
    }
  }

  onSubmit() {
    this.blockUI.start('Processing your request.');
    var _JsonBodyData = {
      "CustomerID": this.customerid,
      "FullName": this.indexForm.value.fullname,
      "company": this.indexForm.value.Company,
      "Phone": this.indexForm.value.Phone,
      "loggedId": localStorage.getItem('loggedId')
    }
    this.connectService.UpdateUserDetails(_JsonBodyData).subscribe((data: any) => {
      this.blockUI.stop();
      if (data.status == "Success") {
        this.ErrorMessage = "Details updated.";
      }
      else {
        this.ErrorMessage = data.errorMessage;
      }
    }, error => {
    })
    if (this.fileData != null) {
      this.blockUI.start('Update profile image may take few seconds.');
      this.uploadImage("", "", this.fileData).then();
    }
  }
  uploadImage(url: string, params: any, file: any) {
    var RootUrl = this.connectService.GetRootURL();
    url = RootUrl + "api/user/UploadImage";
    const prm = new Promise((resolve, reject) => {
      var formData: any = new FormData();
      var xhr = new XMLHttpRequest();
      formData.append("file", file, file.name);
      formData.append("loggedid", localStorage.getItem('loggedId'));
      xhr.onreadystatechange = function () {
        if (xhr.readyState == 4) {
          if (xhr.status == 201) {
            resolve(JSON.parse(xhr.response));

          } else {
            reject(JSON.parse(xhr.response));
          }
        }
      }
      xhr.open("POST", url, true);
      xhr.setRequestHeader("Authorization", 'Bearer ' + localStorage.getItem('accessToken'));
      xhr.send(formData);
    });
    prm.catch(function (error) {
      localStorage.setItem('imageUrl', error.url);
      location.reload();
    });
    return prm;
  }
}