import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { AuthservService } from '../servicess/authserv.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { first } from 'rxjs/operators';
import { NgForm } from '@angular/forms';
import { throwMatDialogContentAlreadyAttachedError } from '@angular/material';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { Location } from '@angular/common';
@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css']
})
export class SignupComponent implements OnInit {
  @BlockUI() blockUI: NgBlockUI;
  public msg: any = {};

  constructor(private route: Router, private http: HttpClient, private authservice: AuthservService, private loc: Location) { }
  returnUrl: string;
  error = '';

  UserName: any;
  Email: any;
  Password: any;
  Address: any;
  emailVerified = false;
  status: any
  DomainName: any;
  @ViewChild('f') indexForm: NgForm;
  //f: NgForm;
  ngOnInit() {
    const angularRoute = this.loc.path();
    this.DomainName = window.location.hostname + (window.location.port ? ':' + window.location.port : '');
  }
  submitted = false;
  info: any = [];
  data: any;
  message: any;
  conformpass: any;
  pass: any;
  mobile: any;
  name: any;
  email: any;
  uname: any;
  CompanyName: any;
  emaill: any;
  u1: any;
  u2: any;
  onSubmit(data: any) {
    this.blockUI.start('Registering your account...');
    this.submitted = true,
      this.u1 = this.indexForm.value.UserName;
    this.u2 = this.indexForm.value.Email;
    this.data = {
      FullName: this.indexForm.value.UserName,
      CompanyName: this.indexForm.value.CompanyName,
      UserName: this.indexForm.value.Email,
      Email: this.indexForm.value.Email,
      Password: this.indexForm.value.Password,
      Phone: this.indexForm.value.phone,
      Domain: this.DomainName
    }
    this.message = "Please check your gmail";
    this.authservice.SignUp(this.data).subscribe(responseData => {
      var data: any = responseData;
      if (data.status == "Error") {
        let msg: any = {};
        msg.errorMessage = data.errorMessage;
        this.msg = msg;
        if (data.emailVerified == false)
          this.emailVerified = true;
        this.blockUI.stop();
        this.blockUI.start(data.errorMessage);
        setTimeout(() => {
          this.blockUI.stop();
        }, 3000)
      }
      else {
        this.indexForm.resetForm();
        this.indexForm.form.markAsPristine();
        this.indexForm.form.markAsUntouched();
        this.indexForm.form.updateValueAndValidity();
        this.info = responseData;
        this.Email = this.indexForm.value.Email;
        this.conformpass = "";
        this.pass = "";
        this.mobile = "";
        this.emaill = data.Email;
        let msg: any = {};
        var data2: any = data;
        this.msg.errorMessage = data2.statusDetails;
        this.blockUI.stop();
        this.blockUI.start('Please check your mail to activate your account.');
        setTimeout(() => {
          this.blockUI.stop();
          this.route.navigate(['/home']);
        }, 3000)
      }
    });
  }
  requestData() {
    this.blockUI.start('Resending email to activate your account.');
    this.authservice.resendEmail(this.DomainName, this.data.Email).subscribe((data) => {
      let msg: any = {};
      var data111: any = data;
      this.msg.errorMessage = data111.statusDetails;
      this.emailVerified = false;
      this.blockUI.stop();
      this.blockUI.start('Please check your mail to activate your account.');
      setTimeout(() => {
        this.blockUI.stop();
        this.route.navigate(['/home']);
      }, 3000)
    })
  }
}
