import { Component, OnInit } from '@angular/core';
import { AuthservService } from '../servicess/authserv.service'
@Component({
  selector: 'app-resend-email',
  templateUrl: './resend-email.component.html',
  styleUrls: ['./resend-email.component.css']
})
export class ResendEmailComponent implements OnInit {

  constructor(private authdata: AuthservService) { }

  ngOnInit() {
  }

  emailResend() {
    this.authdata.resendEmail('', '').subscribe((data) => {

    });
  }
}
