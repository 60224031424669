import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { AuthservService } from '../servicess/authserv.service';
import { ConnectorsService } from '../servicess/connectors.service';
import { NgForm } from '@angular/forms';
import { Location } from '@angular/common';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
@Component({
  selector: 'app-adduser',
  templateUrl: './adduser.component.html',
  styleUrls: ['./adduser.component.css']
})
export class AdduserComponent implements OnInit {
  @BlockUI() blockUI: NgBlockUI;
  constructor(private route: Router, private http: HttpClient, private authservice: AuthservService, private loc: Location, private connectService: ConnectorsService, ) { }
  DomainName: any;
  SelectedUserid: any;
  SubUsersList: any = [];
  ErrorMessage: any = "";
  @ViewChild('f') indexForm: NgForm;
  ngOnInit() {
    const angularRoute = this.loc.path();
    this.DomainName = window.location.hostname + (window.location.port ? ':' + window.location.port : '');
    this.GetUsers();
  }
  GetUsers() {
    this.connectService.GetSubUsers().subscribe((data: any) => {
      if (data.status == "Success") {
        this.SubUsersList = data.results;
      }
    }, error => {

    })
  }
  onSubmit() {
    this.blockUI.start('Processing your request.');
    var CustomerDetails = { UserName: this.indexForm.value.UserName, Fullname: this.indexForm.value.fullname, Domain: this.DomainName };
    this.connectService.PostAddUser(CustomerDetails).subscribe((data: any) => {
      this.blockUI.stop();
      if (data.status == "Success") {
        this.indexForm.resetForm();
        this.GetUsers();
      }
      else {
        this.ErrorMessage = data.errorMessage;
        setTimeout(() => {
          this.ErrorMessage = "";
        }, 3000)
      }
    }, error => {

    })
  }
  ResetForm() {
    this.indexForm.resetForm();
  }
  UpdateUsetID(id: any) {
    this.SelectedUserid = (id);
  }
  RemoveSubUser() {
    this.blockUI.start('Processing your request');
    this.connectService.DeleteSubUsers(this.SelectedUserid).subscribe((data: any) => {
      if (data.status == "Success") {
        this.blockUI.stop();
        this.GetUsers();
        this.ErrorMessage = "User removed";
        setTimeout(() => {
          this.ErrorMessage = "";
        }, 3000)
      }
    }, error => {

    })
  }
}
