import { Component, OnInit } from '@angular/core';
import { AuthservService } from '../../servicess/authserv.service';
@Component({
  selector: 'app-admin-layout-header',
  templateUrl: './admin-layout-header.component.html',
  styleUrls: ['./admin-layout-header.component.css']
})
export class AdminLayoutHeaderComponent implements OnInit {

  constructor(private authserve: AuthservService) { }

  ngOnInit() {
  }
  logoutUser() {
    this.authserve.userLogout();
  }
}