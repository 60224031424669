import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthservService } from '../servicess/authserv.service';
@Component({
  selector: 'app-gett',
  templateUrl: './gett.component.html',
  styleUrls: ['./gett.component.css']
})
export class GettComponent implements OnInit {

  constructor(private htt: HttpClient, private as: AuthservService) { }

  ngOnInit() {
    this.onFetch();
  }

  onFetch() {
    this.as.onGet().subscribe((data) => {
    })
  }
  onSubmit(data1: { email: string, password: string }) {
    return this.as.SignUp(data1);
  }

}
