import { Component, OnInit, EventEmitter, Output, Inject, ViewChild } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MapDataComponent } from '../map-data/map-data.component';
import { HttpClient } from '@angular/common/http';
import { ConnectorsService } from '../servicess/connectors.service';
import { NgForm } from '@angular/forms';
import { BlockUI, NgBlockUI } from 'ng-block-ui';

@Component({
  selector: 'app-file-cabinet',
  templateUrl: './file-cabinet.component.html',
  styleUrls: ['./file-cabinet.component.css']
})

export class FileCabinetComponent implements OnInit {
  @BlockUI() blockUI: NgBlockUI;
  constructor(private router: ActivatedRoute, public dialog: MatDialog, private route: Router, private http: HttpClient, private connectSer: ConnectorsService) { }
  _connectorsList: Array<any> = [];
  _connectorName: any = "";
  fileData: File = null;
  _errorMsg: any = "";
  _connectorFiles: Array<any> = [];
  _isFile: any = false;

  @ViewChild('f') indexForm: NgForm;
  ngOnInit() {
    if (localStorage.getItem("selectedConnector") != null && localStorage.getItem("selectedConnector") != undefined && localStorage.getItem("selectedConnector") != "") {
      this._connectorName = parseInt(localStorage.getItem("selectedConnector"));
      this.GetFilesByConnector();
    }
    this.GetConnectorNames();
  }
  GetConnectorNames() {
    this.connectSer.GetConnectorNamesList(null).subscribe((data: any) => {
      if (data.status == "Success") {
        this._connectorsList = data.results;
      }
    }, error => {

    })
  }
  GetFilesByConnector() {
    this._connectorFiles = null;
    this._isFile = false;
    if (this._connectorName != "") {
      this.connectSer.GetFilesByConnector(this._connectorName).subscribe((data: any) => {
        if (data.status == "Success") {
          this._connectorFiles = data.results;
          if (this._connectorFiles.length > 0) {
            this._isFile = true;
          }
        }
      }, error => {

      })
    }
  }
  onSubmit() {
    var flag = 0;
    this._errorMsg = '';
    for (var i = 0; i < this._connectorFiles.length; i++) {
      var txtFile = (this.indexForm.value.txt_filename).trim() + '';
      var ExFileName = (this._connectorFiles[i].fileName).trim() + '';
      if (txtFile.toUpperCase() == ExFileName.toUpperCase()) {
        flag = 1;
      }
    }
    if (flag == 0) {
      this._errorMsg = '';
      if (this.fileData == null) {
        this._errorMsg = 'Please upload the file';
      }
      else {
        localStorage.setItem("selectedConnector", this._connectorName);
        this.uploadImage("", "", this.fileData).then();
      }
    }
    else {
      this._errorMsg = 'File name already exists';
    }
  }
  fileProgress(fileInput: any) {
    this._errorMsg = '';
    this.fileData = <File>fileInput.target.files[0];
  }

  uploadImage(url: string, params: any, file: any) {
    this.blockUI.start('Processing your request.');
    var RootUrl = this.connectSer.GetRootURL();
    url = RootUrl + "api/Admin/CreateFile";
    const prm = new Promise((resolve, reject) => {
      var formData: any = new FormData();
      var xhr = new XMLHttpRequest();
      formData.append("file", file, file.name);
      formData.append("ConnectorID", this._connectorName);
      formData.append("FileName", this.indexForm.value.txt_filename);
      xhr.onreadystatechange = function () {
        if (xhr.readyState == 4) {
          if (xhr.status == 201) {
            resolve(JSON.parse(xhr.response));

          } else {
            reject(JSON.parse(xhr.response));
          }
        }
      }
      xhr.open("POST", url, true);
      xhr.setRequestHeader("Authorization", 'Bearer ' + localStorage.getItem('accessToken'));
      xhr.send(formData);
    });
    prm.catch(function (error) {
      location.reload();
    });
    return prm;
  }
  DeleteFile(id) {
    var ans = confirm('Are you delete the file?');
    if (ans) {
      this.connectSer.DeleteFileById(id).subscribe((data: any) => {
        if (data.status == "Success") {
          this.GetFilesByConnector();
        }
      }, error => {

      })
    }
  }
}
